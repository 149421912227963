import React, { forwardRef, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';


const CustomDatePicker = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value ? moment(props.value, (props.colDef.formatSave || props.colDef.format || 'DD/MM/YYYY hh:mm A')).toDate() : null);
    const dateInputRef = React.createRef();


    useEffect(() => {
        if (dateInputRef.current) {
            dateInputRef.current.focus();
            //dateInputRef.current.show();
        }
    }, [])

    const handleChange = (date) => {
        setValue(date);
        props.api.stopEditing();
        if (props?.colDef?.formatSave) {
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (date ? moment(date).format(props?.colDef?.formatSave) : null);
            props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format(props?.colDef?.format || 'DD/MM/YYYY') : null))
        } else {
            if (props.colDef.fieldDB !== props.colDef.field)
                props.data[props.colDef.fieldDB] = (date ? window.firebase.firestore.Timestamp.fromDate(moment(moment(date).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).toDate()) : null);
            props.api.getRowNode(props.rowIndex).setDataValue(props.colDef.field, (date ? moment(date).format(props?.colDef?.format || 'DD/MM/YYYY') : null))
        }
    };

    return (
        <div className="ag-input-wrapper" style={{
            width: props.eGridCell.clientWidth,
            height: props.eGridCell.clientHeight,
            boxSizing: 'border-box'
        }}>
            <DatePicker
                value={value ? moment(value) : null}
                onChange={handleChange}
                ref={dateInputRef}
                open={true}
                format={props.colDef.format || 'DD/MM/YYYY'}
                placeholder={props?.colDef?.placeholder || 'DD/MM/YYYY'}
                className={`form-control`}
                picker={props?.colDef?.picker || 'date'}
            />
        </div>
    );
});

export default CustomDatePicker;