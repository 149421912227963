import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { ContentOverlay, UpgradePlan } from '../../../../components';
import CustomTable from '../../../../components/customTable';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import toast from 'toasted-notes'
import { TemplateContext } from '../templateContext'

const dynCols = [
    { name: 'Name', value: 'name', placeholder: 'name', type: 'text', mandatory: true, width: '21.25', dWidth: 297 },
    { name: 'Dealer', value: 'clientID', placeholder: 'dealer', type: 'select', mandatory: true, width: '21.25', dWidth: 297 },
    { name: 'Make', value: 'makes', placeholder: 'make', type: 'multi', width: '21.25', dWidth: 297 },
    { name: 'Sale Type', value: 'saleTypes', placeholder: 'sale type', type: 'multi', mandatory: true, width: '21.25', dWidth: 297 }
]

const Departments = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth - 30, windowHeight: (window.innerHeight - 315) })
    const { allsettings, loader } = useContext(TemplateContext);
    const [departments, setDepartments] = useState([]);

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        setDepartments(allsettings?.departments || [])
    }, [allsettings])

    const handleSave = (list) => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/dealLog/${props.dealersettings.oemID}`)
            .set({
                departments: list
            }, { merge: true })
            .then(() => {
                toast.notify('Department updated successfully', {
                    duration: 2000
                });
            })
    }

    return (
        <>
            {
                liteVersion && !loader ? (<UpgradePlan />) : (<></>)
            }
            <h2> <Translate text={'Departments'} /> </h2>
            <div className="divider-line"></div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    <div className='mt-2'>
                        <CustomTable
                            dealersettings={props.dealersettings}
                            cols={dynCols}
                            subList={departments}
                            setSubList={(list) => {
                                setDepartments(list)
                                handleSave(list)
                            }}
                            clientID={CommonHelper.getDealLogDealers(props.dealersettings).map(r => { return { label: r.name, value: r.id } })}
                            makes={props?.dealersettings?.allMakeModels?.map(r => { return { label: r.name, value: r.value } })}
                            saleTypes={props?.dealersettings?.client?.settings?.salesType?.filter(e => e.active === true)?.map(r => { return { label: r.name, value: r.value } })}
                            windowHeight={windowSize.windowHeight}
                            createUUID={true}
                        ></CustomTable>
                    </div>
            }
        </>
    )
}

export default Departments;