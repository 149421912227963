import React, { useState, useEffect, useContext, Fragment } from 'react';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputCheckBox, ReactSelect, PopUpModal, ContentOverlay, InputRadio, ReactMultiSelect } from '../../../../components'
import { rolePermissions, roleVM, levelOptions } from './viewModel'
import { ClientContext } from '../clientContext'
import AddRole from './addRole'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import UpdateLevel from './updateLevel';

const UserRoles = (props) => {
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState('')
    const [roleList, setRoleList] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [clientUsers, setClientUsers] = useState([])
    const [userLoader, setUserloader] = useState(false)
    const [permissionLoader, setPermissionloader] = useState(false)
    const [showRoleModel, setShowRoleModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editRole, setEditRole] = useState(null)
    const [oldSelecedRole, setOldSelecedRole] = useState(null)
    const [searchPermission, setSearchPermission] = useState('')
    const { clients, roles } = useContext(ClientContext);
    const [searchText, setSearchText] = useState('')
    const [currentSettings, setCurrentSettings] = useState()
    const [allPermissions, setAllPermissions] = useState(rolePermissions)
    const [allTabs, setAllTabs] = useState([])
    const [activeTab, setActiveTab] = useState('admin');
    const [levelModel, setLevelModel] = useState({ show: false, role: null, title: '' });
    const levelAccess = _.map((props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index), function (obj) {
            return obj.value;
        })
    useEffect(() => {
        if (_.isEmpty(allPermissions) || _.isEmpty(currentSettings))
            return
        const sales = (_.isEmpty(currentSettings.moduleSettings)
            || _.isEmpty(currentSettings.moduleSettings.sales)
            || (currentSettings.moduleSettings.sales.enabled === true && currentSettings.moduleSettings.sales.active === true)) ? true : false
        const service = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.service)
            && currentSettings.moduleSettings.service.enabled === true
            && currentSettings.moduleSettings.service.active === true) ? true : false;
        const tradeinPro = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.tradeinPro)
            && currentSettings.moduleSettings.tradeinPro.enabled === true
            && currentSettings.moduleSettings.tradeinPro.active === true) ? true : false;
        const eventsPro = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.eventsPro)
            && currentSettings.moduleSettings.eventsPro.enabled === true
            && currentSettings.moduleSettings.eventsPro.active === true) ? true : false;
        const fleetPro = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.fleetPro)
            && currentSettings.moduleSettings.fleetPro.enabled === true
            && currentSettings.moduleSettings.fleetPro.active === true) ? true : false;
        const serviceFleetPro = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.serviceFleetPro)
            && currentSettings.moduleSettings.serviceFleetPro.enabled === true
            && currentSettings.moduleSettings.serviceFleetPro.active === true) ? true : false;
        const dealLog = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.dealLog)
            && currentSettings.moduleSettings.dealLog.enabled === true
            && currentSettings.moduleSettings.dealLog.active === true) ? true : false;
        const frontdesk = (!_.isEmpty(currentSettings.moduleSettings)
            && !_.isEmpty(currentSettings.moduleSettings.frontdesk)
            && currentSettings.moduleSettings.frontdesk.enabled === true
            && currentSettings.moduleSettings.frontdesk.active === true) ? true : false;
        const cafe = (_.isEmpty(currentSettings.moduleSettings)
            || _.isEmpty(currentSettings.moduleSettings.cafe)
            || (currentSettings.moduleSettings.cafe.enabled === true && currentSettings.moduleSettings.cafe.active === true)) ? true : false

        let pipelines = [];
        let salesType = [];
        let servicePipelines = [];
        let tradeinProPipelines = [];
        let eventsProPipelines = [];
        if (sales) {
            currentSettings.settings
                && currentSettings.settings.salesType
                && currentSettings.settings.salesType.filter(a => a.active === true).forEach(rec => {
                    salesType.push({
                        name: rec.name,
                        value: rec.value,
                        type: 'salesType',
                        sortorder: 'b',
                        groupBy: 'sales'
                    })
                })
            currentSettings.settings
                && currentSettings.settings.allPipelines
                && currentSettings.settings.allPipelines.filter(a => a.active === true).forEach(rec => {
                    pipelines.push({
                        name: rec.name,
                        value: rec.value,
                        type: 'pipeline',
                        sortorder: 'c',
                        groupBy: 'sales'
                    })
                })
        }
        if (service) {
            currentSettings.services
                && currentSettings.services.allPipelines
                && currentSettings.services.allPipelines.filter(a => a.active === true).forEach(rec => {
                    servicePipelines.push({
                        name: rec.name,
                        value: rec.value,
                        type: 'servicePipeline',
                        sortorder: 'f',
                        groupBy: 'service'
                    })
                })
        }
        if (tradeinPro) {
            currentSettings.tradeinPro
                && currentSettings.tradeinPro.allPipelines
                && currentSettings.tradeinPro.allPipelines.filter(a => a.active === true).forEach(rec => {
                    tradeinProPipelines.push({
                        name: rec.name,
                        value: rec.value,
                        type: 'tradeinProPipeline',
                        sortorder: 'h',
                        groupBy: 'tradeinPro'
                    })
                })
        }
        if (eventsPro) {
            currentSettings.eventsPro
                && currentSettings.eventsPro.allPipelines
                && currentSettings.eventsPro.allPipelines.filter(a => a.active === true).forEach(rec => {
                    eventsProPipelines.push({
                        name: rec.name,
                        value: rec.value,
                        type: 'eventsProPipeline',
                        sortorder: 'j',
                        groupBy: 'eventsPro'
                    })
                })
        }

        let _rolePermissions = [
            ...rolePermissions.filter(a =>
                a.type !== 'pipeline'
                && a.type !== 'servicePipeline'
                && a.type !== 'tradeinProPipeline'
                && a.type !== 'eventsProPipeline'
                && (!frontdesk ? (a.value !== 'frontdesk') : true)
                && (!cafe ? (a.value !== 'kitchen') : true)
                && (!sales ? (a.type !== 'permissions' && a.value !== 'workflowSetup' && a.module !== 'sales') : true)
                && (!service ? (a.type !== 'servicePermissions' && a.value !== 'serviceworkflowSetup' && a.module !== 'service') : true)
                && (!tradeinPro ? (a.type !== 'tradeinProPermissions' && a.value !== 'tradeinProworkflowSetup' && a.module !== 'tradeinPro') : true)
                && (!eventsPro ? (a.type !== 'eventsProPermissions' && a.value !== 'eventsProworkflowSetup' && a.module !== 'eventsPro') : true)
                && (!fleetPro ? (a.type !== 'fleetProPermissions' && a.value !== 'fleetProworkflowSetup' && a.module !== 'fleetPro') : true)
                && (!serviceFleetPro ? (a.type !== 'serviceFleetProPermissions' && a.module !== 'serviceFleetPro') : true)
                && (!dealLog ? (a.module !== 'dealLog' && a.value !== 'dealLogProworkflowSetup' ) : true)
            ),
            ...salesType,
            ...pipelines,
            ...servicePipelines,
            ...tradeinProPipelines,
            ...eventsProPipelines
        ];

        setAllPermissions(_rolePermissions)
        setAllTabs(_.chain(_rolePermissions)
            .groupBy("groupBy")
            .map((value, key) => ({
                type: key
            }))
            .value())
    }, [currentSettings])



    useEffect(() => {
        if (_.isEmpty(selectedClient))
            return;

        const currentSettingsSnapshot = window.firebase.firestore().doc(`clients/${selectedClient.documentID}/currentSettings/${selectedClient.documentID}`)
            .onSnapshot(async querySnapshot => {
                if (querySnapshot.exists) {
                    const eventsProSettings = await window.firebase.firestore().doc(`clients/${selectedClient.documentID}/currentSettings/${selectedClient.documentID}/eventsPro/${selectedClient.documentID}`).get();
                    const fleetProSettings = await window.firebase.firestore().doc(`clients/${selectedClient.documentID}/currentSettings/${selectedClient.documentID}/fleetPro/${selectedClient.documentID}`).get();
                    setCurrentSettings(
                        {
                            ...querySnapshot.data(),
                            eventsPro: eventsProSettings.exists ? eventsProSettings.data() : {},
                            fleetPro: fleetProSettings.exists ? fleetProSettings.data() : {}
                        });
                }
                else {
                    setCurrentSettings(props.dealersettings.client)
                }
            });
        return () => {
            currentSettingsSnapshot && currentSettingsSnapshot();
        }


    }, [selectedClient])

    // useEffect(() => {
    //     // if (props.activeKey === 'users') {
    //     //     function handleResize() {
    //     //         document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 260) + 'px');
    //     //         document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 209) + 'px');
    //     //         document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 350) + 'px');
    //     //     }
    //     //     window.addEventListener('resize', handleResize);
    //     //     document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 260) + 'px');
    //     //     document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 209) + 'px');
    //     //     document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 350) + 'px');
    //     // return () => {
    //     //     _.isArray(unsubUsers) && unsubUsers.forEach(_user => {
    //     //         window['unsub' + _user.id] && window['unsub' + _user.id]();
    //     //     })
    //     // }
    //     // }
    // }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }) ? _.find(clients, { 'documentID': props.dealersettings.client.id }) : clients[0])
            }
        }
    }, [clients])

    useEffect(() => {
        if (!_.isEmpty(roles)) {
            var _roles = [];
            roles.filter(e => levelAccess.some(a => a === e.level)
                && (!_.isEmpty(selectedClient) ? (_.isEmpty(e.clientID) || e.clientID === selectedClient.documentID) : true))
                .forEach(rec => {
                    _roles.push({
                        ...rec,
                        'active': (_.find(roleList, { 'documentID': rec.documentID }) ? _.find(roleList, { 'documentID': rec.documentID }).active : false)
                    });
                })
            if (_.isEmpty(selectedRole) || !_.find(_roles, { 'documentID': selectedRole.documentID })) {
                if (_roles.length > 0 && !_roles.some(item => item.active === true)) {
                    _.find(_roles, { 'documentID': _roles[0].documentID }).active = true;
                }
                setSelectedRole(_roles.find(item => item.active === true))
                setOldSelecedRole(_roles.find(item => item.active === true))

            }
            else {
                _.find(_roles, { 'documentID': selectedRole.documentID }).active = true;
            }
            setRoleList(_roles)
        }
    }, [roles, selectedClient])


    useEffect(() => {
        //if (!_.isEmpty(selectedClient)) {
        //    getClientUsers()
        //}
        setUserloader(true)
        const clientUserSnapshot = window.firebase.firestore().collection('users')
            .where('settingsID', '==', props.dealersettings.oemID)
            .onSnapshot(querysnapshot => {
                let users = []
                querysnapshot.forEach((doc) => {
                    const _user = {
                        ...doc.data(),
                        documentID: doc.id
                    }
                    if (_user.isDisabled !== true && levelAccess.some(e => e === _user.level) && _user.arrayClientID && _user.arrayClientID.length > 0 && CommonHelper.arrayContainsArray(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), _user.arrayClientID)) {
                        users.push({
                            id: doc.id,
                            name: _user.name,
                            email: _user.email,
                            profileImage: _user.profileImage,
                            arrayClientID: _user.arrayClientID,
                            roleID: _user.roleID,
                            department: _user.department,
                        })
                    }
                    else if (_.isEmpty(_user.arrayClientID) && !_.isEmpty(_user.settingsID)) {
                        users.push({
                            id: doc.id,
                            name: _user.name,
                            email: _user.email,
                            profileImage: _user.profileImage,
                            arrayClientID: _user.arrayClientID,
                            roleID: _user.roleID,
                            department: _user.department,
                        })
                    }
                });
                setClientUsers(users)
                setUserloader(false)
            });

        return () => {
            clientUserSnapshot && clientUserSnapshot()
        }
    }, [])

    // async function getClientUsers() {
    //     setUserloader(true)
    //     let users = []
    //     const clientUserSnapshot = await window.firebase.firestore().collection('users')
    //         .where('arrayClientID', 'array-contains', props.dealersettings.client.id)
    //         .get();
    //     clientUserSnapshot.forEach((doc) => {
    //         const _user = {
    //             ...doc.data(),
    //             documentID: doc.id
    //         }
    //         users.push({
    //             id: doc.id,
    //             name: _user.name,
    //             email: _user.email,
    //             profileImage: _user.profileImage,
    //             arrayClientID: _user.arrayClientID,
    //             roleID: _user.roleID
    //         })
    //     });
    //     //setUnsubUsers(users)
    //     // if (users.length > 0) {
    //     //     users.forEach(_user => {
    //     //         window['unsub' + _user.id] = window.firebase.firestore().doc(`users/${_user.id}/userClients/${props.dealersettings.client.id}`)
    //     //             .onSnapshot((docSnapshot) => {
    //     //                 setClientUsers([..._.forEach(users, function (obj) {
    //     //                     if (obj.id === _user.id)
    //     //                         _.set(obj, 'roleID', (docSnapshot.exists ? docSnapshot.data().roleID : ''));
    //     //                 })]);
    //     //             });
    //     //     })
    //     // }
    //     // else {
    //     //     setClientUsers([])
    //     // }
    //     setClientUsers(users)
    //     setUserloader(false)
    // }

    const handleRoleChange = (role, delRole) => {
        if (JSON.stringify(selectedRole) !== JSON.stringify(oldSelecedRole)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to discard permission changes.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then((result) => {
                if (result.value) {
                    setRoleList([..._.map(roleList.filter(item => !_.isEmpty(delRole) ? item.documentID !== delRole.documentID : true), function (obj) {
                        return obj.documentID === oldSelecedRole.documentID ? { ...oldSelecedRole, 'active': false } : {
                            ...obj, 'active': (obj.documentID === role.documentID ? true : false)
                        };
                    })])
                    setSelectedRole(role)
                    setOldSelecedRole(role)
                }
            })
        }
        else {
            if (!_.isEmpty(role)) {
                setRoleList([..._.forEach(roleList.filter(item => !_.isEmpty(delRole) ? item.documentID !== delRole.documentID : true), function (obj) {
                    if (obj.documentID === role.documentID)
                        _.set(obj, 'active', true);
                    else
                        _.set(obj, 'active', false);
                })]);
                setSelectedRole(role)
                setOldSelecedRole(role)
            }
        }
    }

    const handlePermissionChange = (e, name) => {
        let _pipeline = [];
        if (name === 'pipeline' || name === 'servicePipeline' || name === 'tradeinProPipeline' || name === 'eventsProPipeline' || name === 'salesType') {
            if (e.target.checked)
                _pipeline = selectedRole[name] ? [...selectedRole[name].filter(item => item !== e.target.name), e.target.name] : [e.target.name];
            else
                _pipeline = selectedRole[name] ? [...selectedRole[name].filter(item => item !== e.target.name)] : [];
        }
        let _permissionCheck = { [e.target.name]: e.target.checked };
        if (e.target.name === 'ownerDashboard' || e.target.name === 'viewOtherEnquiry') {
            _permissionCheck = {
                [e.target.name]: e.target.checked,
                [e.target.name === 'ownerDashboard' ? 'viewOtherEnquiry' : 'ownerDashboard']: !e.target.checked
            }
        }
        if (e.target.name === 'bdcDashboard' && e.target.checked && (_.isEmpty(selectedRole.pipeline) || !selectedRole.pipeline.some(e => e === 'LeadsBucket'))) {
            Swal.fire('Please enable \'Inbound Leads\' pipeline to access bdc dashboard.', '', 'info')
            return;
        }
        var updateRole = {
            ...selectedRole,
            active: true,
            [name]: (name === 'pipeline' || name === 'servicePipeline' || name === 'tradeinProPipeline' || name === 'eventsProPipeline' || name === 'salesType') ? _pipeline : {
                ...selectedRole[name],
                ..._permissionCheck
            }
        };
        if (name === 'pipeline' && e.target.name === 'LeadsBucket' && !e.target.checked) {
            updateRole = {
                ...updateRole,
                ['permissions']: {
                    ...updateRole.permissions,
                    ['bdcDashboard']: false
                }
            }
        }

        if ((name === 'pipeline' || name === 'servicePipeline' || name === 'tradeinProPipeline' || name === 'eventsProPipeline') && !e.target.checked) {
            if (selectedRole.defaultPipeline === e.target.name) {
                updateRole = {
                    ...updateRole,
                    defaultPipeline: null
                }
            }
            if (selectedRole.defaultservicePipeline === e.target.name) {
                updateRole = {
                    ...updateRole,
                    defaultservicePipeline: null
                }
            }
            if (selectedRole.defaulttradeinProPipeline === e.target.name) {
                updateRole = {
                    ...updateRole,
                    defaulttradeinProPipeline: null
                }
            }
            if (selectedRole.defaulteventsProPipeline === e.target.name) {
                updateRole = {
                    ...updateRole,
                    defaulteventsProPipeline: null
                }
            }
        }

        if (e.target.name === 'accessToTVDisplayOnly') {
            if (e.target.checked) {
                updateRole = {
                    ...updateRole,
                    ['modules']: {
                        ...updateRole.modules,
                        ...roleVM.modules,
                        ['tvModule']: true
                    }
                }
            }
            else {
                updateRole = {
                    ...updateRole,
                    ['modules']: {
                        ...roleVM.modules,
                        ['enquiry']: true
                    }
                }
            }
        }

        setSelectedRole(updateRole)
        setRoleList([..._.map(roleList, function (obj) {
            return obj.documentID === selectedRole.documentID ? { ...updateRole } : obj;
        })])
    }

    const handleTypeChange = (e) => {
        setSelectedRole({
            ...selectedRole,
            [e.target.name]: e.target.value
        })
    }
    const handleClientUserChange = (e) => {
        setClientUsers([{ ...e.doc, 'roleID': selectedRole.documentID }, ...clientUsers.filter(item => item.id !== e.value)]);
        window.firebase.firestore().doc(`users/${e.value}`).set({ 'roleID': selectedRole.documentID, 'role': selectedRole.name, 'level': selectedRole.level }, { merge: true })
        e.doc.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${e.value}/userClients/${clientid}`).set({ 'roleID': selectedRole.documentID }, { merge: true })
        })


        //update settings date and priority
        //if (!_.isEmpty(selectedClient)) {
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
            .set({
                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                settingsUpdatePriority: 'critical',
                settingsUpdateBy: props?.dealersettings?.name || null,
                settingsUpdateText: 'User Role Settings has been updated',
            }, { merge: true })
        if (props.dealersettings.oemID) {
            window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                .set({
                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                    settingsUpdatePriority: 'critical',
                    settingsUpdateBy: props?.dealersettings?.name || null,
                    settingsUpdateText: 'User Role Settings has been updated',
                }, { merge: true })
        }
        //}
        //end
    }

    const handleswitchRole = (role, user) => {
        let updateRole = {
            'roleID': role.documentID,
            'role': role.name,
            'level': role.level,
            'arrayClientID': user.arrayClientID
        }
        if (role.level === 'region' || role.level === 'group') {
            setLevelModel({
                show: true,
                role: updateRole,
                title: 'Update Level',
                user: user
            })
        }
        else {
            if (role.level === 'oem') {
                updateRole = {
                    ...updateRole,
                    'oemID': props.dealersettings.oemID,
                    'arrayClientID': clients.map(v => _.pick(v, ['documentID']).documentID)
                }
            }
            setClientUsers([{ ...user, ...updateRole }, ...clientUsers.filter(item => item.id !== user.id)]);
            window.firebase.firestore().doc(`users/${user.id}`).set({ ...updateRole }, { merge: true })
            updateRole.arrayClientID.forEach(clientid => {
                window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({
                    'roleID': role.documentID,
                    'name': _.find(clients, { 'documentID': clientid }) ? _.find(clients, { 'documentID': clientid }).name : null,
                    'department': user.department,
                }, { merge: true })
            })
            //window.firebase.firestore().doc(`users/${user.id}/userClients/${selectedClient.documentID}`).set({ 'roleID': role.documentID }, { merge: true })

            //update settings date and priority
            //if (!_.isEmpty(selectedClient)) {
            window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                .set({
                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                    settingsUpdatePriority: 'critical',
                    settingsUpdateBy: props?.dealersettings?.name || null,
                    settingsUpdateText: 'User Role Settings has been updated',
                }, { merge: true })
            if (props.dealersettings.oemID) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'critical',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'User Role Settings has been updated',
                    }, { merge: true })
            }
            //}
            //end
        }

    }

    const handledeleteUserRole = (user) => {
        setClientUsers([{ ...user, 'roleID': '' }, ...clientUsers.filter(item => item.id !== user.id)]);
        window.firebase.firestore().doc(`users/${user.id}`).set({ 'roleID': '', 'role': '' }, { merge: true })
        user.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({ 'roleID': '' }, { merge: true })
        })
        //window.firebase.firestore().doc(`users/${user.id}/userClients/${selectedClient.documentID}`).set({ 'roleID': '' }, { merge: true })
    }

    const handleModelClose = (role) => {
        setShowRoleModel(false)
        setLevelModel({ show: false })
        if (!_.isEmpty(role)) {
            setEditRole(null)
            // if (!_.isEmpty(oldSelecedRole) && oldSelecedRole.documentID === role.documentID) {
            //     setOldSelecedRole({ ...oldSelecedRole, 'name': role.name, 'clientID': role.clientID, 'level': role.level })
            // }
            // if (roleList.some(item => item.documentID === role.documentID)) {
            //     setRoleList([..._.forEach(roleList, function (obj) {
            //         if (obj.documentID === role.documentID) {
            //             _.set(obj, 'name', role.name);
            //             _.set(obj, 'level', role.level);
            //             _.set(obj, 'clientID', role.clientID);
            //         }
            //     })]);
            // }
            // else {
            //     setRoleList([...roleList.filter(item => item.documentID !== role.documentID), role]);
            // }
        }
    }

    const handleeditRole = (role) => {
        setPopHeader('Edit Role')
        setEditRole(role)
        setShowRoleModel(true)
    }

    const handlePermissionSave = () => {
        setPermissionloader(true)
        setOldSelecedRole(selectedRole)
        delete selectedRole.active
        selectedRole.modifiedDate = window.firebase.firestore.Timestamp.now();
        selectedRole.modifiedBy = localStorage.uid;
        selectedRole.oemID = props.dealersettings.client.settingsID;
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${selectedRole.documentID}`).set({ ..._.pick(selectedRole, _.keys(roleVM)) }, { merge: true })
            .then(snapshot => {
                //update settings date and priority
                //if (!_.isEmpty(selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'critical',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'User Role Settings has been updated',
                    }, { merge: true })
                if (props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'critical',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'User Role Settings has been updated',
                        }, { merge: true })
                }
                //}
                //end

                setPermissionloader(false);
                //props.updateDealerSettings('rolePermissions', selectedRole);
                toast.notify('Permissions updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setPermissionloader(false)
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }

    const handledeleteRole = async (role) => {
        if (clientUsers.filter(item => item.roleID === role.documentID).length > 0) {
            Swal.fire(CommonHelper.showLocale(props, 'Role cannot be removed as it has been assigned to other users.'), '', 'error')
            return;
        }

        var checkroleUsers = await window.firebase.firestore().collection('users')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .where('roleID', '==', role.documentID)
            .limit(1)
            .get();
        if (checkroleUsers.docs.length > 0) {
            Swal.fire(CommonHelper.showLocale(props, 'Role cannot be removed as it has been assigned to other dealer users.'), '', 'error')
            return;
        }

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/roles/${role.documentID}`).delete()
                    .then(snapshot => {
                        clientUsers.filter(item => item.roleID === role.documentID).forEach(_user => {
                            window.firebase.firestore().doc(`users/${_user.id}`).set({ 'roleID': '' }, { merge: true })
                            _user.arrayClientID.forEach(clientid => {

                                window.firebase.firestore().doc(`users/${_user.id}/userClients/${clientid}`).set({ 'roleID': '' }, { merge: true })
                            })
                            //window.firebase.firestore().doc(`users/${_user.id}/userClients/${selectedClient.documentID}`).set({ 'roleID': '' }, { merge: true })
                        })
                        if (role.active === true) {
                            handleRoleChange(roleList.filter(item => item.documentID !== role.documentID)[0], role)
                        }
                        else {
                            setRoleList([...roleList.filter(item => item.documentID !== role.documentID)]);
                        }
                        setClientUsers([..._.forEach(clientUsers, function (obj) {
                            if (obj.roleID === role.documentID)
                                _.set(obj, 'roleID', '');
                        })]);
                        toast.notify('Role deleted successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
        })
    }

    const PermissionList = ({ rec }) => {
        return (<>
            <div className="settings-field-subheader">{(rec.type === 'servicePipeline' || rec.type === 'tradeinProPipeline' || rec.type === 'eventsProPipeline') ? 'pipeline' : (rec.type === 'servicePermissions' || rec.type === 'tradeinProPermissions' || rec.type === 'eventsProPermissions' || rec.type === 'fleetProPermissions' || rec.type === 'serviceFleetProPermissions') ? 'permissions' : rec.type === 'salesType' ? 'Sales Type' : rec.type}</div>
            {
                rec.permissions.map((r, i) => {
                    return <li key={i} >
                        <span className={`switch switch-sm mr-2`}>
                            <InputCheckBox
                                className={`switch`}
                                id={`${rec.type}_${r.value}`}
                                name={r.value}
                                checked={!_.isEmpty(selectedRole[rec.type]) ? (rec.type === 'pipeline' || rec.type === 'servicePipeline' || rec.type === 'tradeinProPipeline' || rec.type === 'eventsProPipeline' || rec.type === 'salesType') ? selectedRole[rec.type].some(item => item === r.value) : Boolean(selectedRole[rec.type][r.value]) : false}
                                onChange={(e) => { handlePermissionChange(e, rec.type) }}

                            />
                            <label htmlFor={`${rec.type}_${r.value}`}></label>
                        </span>
                        <label htmlFor={`${rec.type}_${r.value}`} className={`cursor-pointer mb-0`}>{['admin', 'modules', 'permissions', 'servicePermissions', 'tradeinProPermissions', 'eventsProPermissions', 'fleetProPermissions', 'serviceFleetProPermissions'].includes(rec.type) ? <Translate text={r.name} /> : <>{r.name}</>}</label>
                        {
                            (r.type === 'pipeline' || r.type === 'servicePipeline' || r.type === 'tradeinProPipeline' || r.type === 'eventsProPipeline') && r.value !== 'LeadsBucket' && selectedRole[rec.type] && selectedRole[rec.type].some(item => item === r.value) ? (
                                <div className='radio-check-fix ml-3'>
                                    <div className="radio icheck-success float-left">
                                        <InputRadio
                                            id={`radio-${rec.type}_${r.value}`}
                                            type="radio"
                                            value={r.value}
                                            name={`default${r.type === 'pipeline' ? 'Pipeline' : r.type}`}
                                            checked={selectedRole[`default${r.type === 'pipeline' ? 'Pipeline' : r.type}`] === r.value ? true : false}
                                            onChange={handleTypeChange}
                                        />
                                        <label className={selectedRole[`default${r.type === 'pipeline' ? 'Pipeline' : r.type}`] === r.value ? '' : 'pipeline-default'} htmlFor={`radio-${rec.type}_${r.value}`}><Translate text={selectedRole[`default${r.type === 'pipeline' ? 'Pipeline' : r.type}`] === r.value ? 'Default' : 'Mark as default'} /></label>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                    </li>
                })
            }
        </>)
    }

    return (
        <>
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            {
                                props.dealersettings.clients.map(v => _.pick(v, ['id']).id).length > 0 ? (
                                    <div className="col-md-4">
                                        <ReactSelect
                                            options={clientOptions}
                                            name={"clientID"}
                                            placeholder={'select client'}
                                            onChange={(e, data) => {
                                                if (e) {
                                                    //setLoader(true)
                                                    setSelectedClient(e.data)
                                                }
                                                else {
                                                    setSelectedClient(null)
                                                }
                                            }}
                                            value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                ) : (<></>)
                            }
                            <div className="filter-search search-icon col-md-4" style={{ marginLeft: '-15px' }}>
                                <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="settings-column-wraper">
                <div className="settings-columns">
                    <div className="settings-column-left">
                        <div className="settings-column-title">
                            <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setEditRole(null); setShowRoleModel(true); setPopHeader('Add Role') }}> <i className="ico icon-add"></i></a></div>
                            <h3><Translate text={'Roles'} /></h3>
                        </div>
                        <div className="settings-column-nav roles-nav-left">
                            {
                                roles.length > 0 ? (<ul>
                                    {
                                        roleList.map((rec, index) => {
                                            return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
                                                {
                                                    rec.name === 'Administrator' ? (
                                                        <div className="role-list-items-lock"> <i className="ico icon-lock"></i> </div>
                                                    ) : (
                                                        <Dropdown className="role-list-items-more" alignRight>
                                                            <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                                <i className="ico icon-more"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                <Dropdown.Item eventKey="delete" onClick={(e) => { handleeditRole(rec) }}><i className="ico icon-edit"></i> <Translate text={'edit'} /> </Dropdown.Item>
                                                                <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteRole(rec) }}><i className="ico icon-delete"></i> <Translate text={'delete'} /> </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )
                                                }
                                                <a href="#" className="column-list-left"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRoleChange(rec);
                                                    }}
                                                >{rec.name}
                                                    <br />
                                                    <span className="user-role-span ml-0">{_.find(levelOptions, { value: rec.level }) ? _.find(levelOptions, { value: rec.level }).label : rec.level}</span>
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>) : (<></>)
                            }
                        </div>
                    </div>
                    <div className="settings-column-middle">
                        <div className="settings-column-title">
                            <h3><Translate text={'users'} /></h3>
                        </div>
                        {
                            !_.isEmpty(selectedRole) ? (<>
                                <div className="user-list">
                                    <p><Translate text={'Add users to'} /> '{selectedRole.name}'</p>
                                    <div className="row">
                                        {/* <div className="col-md-6">
                                            <ReactSelect
                                                options={clientOptions}
                                                name={"clientID"}
                                                placeholder={'select client'}
                                                onChange={(e, data) => {
                                                    if (e) {
                                                        setSelectedClient(e.data)
                                                    }
                                                    else {
                                                        setSelectedClient(null)
                                                    }
                                                }}
                                                value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div> */}
                                        <div className="col-md-12">
                                            <ReactSelect
                                                options={_.map(clientUsers.filter(item => _.isEmpty(item.roleID)), function (c) {
                                                    return {
                                                        value: c.id,
                                                        doc: c,
                                                        searchlabel: c.name,
                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                        label: <div className="drop-image">
                                                            <img src={CommonHelper.showUserAvatar(c.profileImage, clientUsers.name)} alt="" className="img-object-fit" />
                                                            {c.name}
                                                        </div>
                                                    };
                                                })}
                                                name={"clientUser"}
                                                onChange={handleClientUserChange}
                                                value={''}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                                placeholder={'select user'}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                </div>
                                {
                                    userLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :

                                        clientUsers.length === 0 ? (<></>) : (<div className="user-list-items">
                                            <ul>
                                                {
                                                    clientUsers.filter(item =>
                                                        item.roleID === selectedRole.documentID
                                                        && (!_.isEmpty(selectedClient) ? item.arrayClientID.some(e => e === selectedClient.documentID) : true)
                                                        && (!_.isEmpty(searchText) ? (
                                                            (!_.isEmpty(item.name) ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                            (!_.isEmpty(item.phone) ? item.phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                            (!_.isEmpty(item.email) ? item.email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                                        ) : true)
                                                    ).map((rec, index) => {
                                                        return <li key={index} className="user-list-box">
                                                            <Dropdown className="user-list-items-more" drop={'left'}>
                                                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                                    <i className="ico icon-more"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                    <Dropdown.Header className='nav-item-header nav-item text-left'>
                                                                        <Translate text={'Switch Role'} />
                                                                    </Dropdown.Header>
                                                                    {
                                                                        roleList.filter(item => item.active === false).map((_role, index) => {
                                                                            return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleswitchRole(_role, rec) }}> {_role.name} </Dropdown.Item>
                                                                        })
                                                                    }
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteUserRole(rec) }}><i className="ico icon-delete"></i> Remove </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <div className="user-list-items-image">
                                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" className="rounded-circle img-object-fit" />
                                                            </div>
                                                            <div className="user-list-items-info">
                                                                <h4>{rec.name}</h4>
                                                                <h5>{rec.email}</h5>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>)
                                }
                            </>
                            ) : (<></>)
                        }

                    </div>
                    <div className="settings-column-right">
                        <div className="settings-column-title">
                            <h3><Translate text={'Permissions'} /></h3>
                            {
                                !_.isEmpty(selectedRole) ? (
                                    <div className="column-common-button">
                                        <button type="button" className="btn btn-primary float-right" onClick={() => handlePermissionSave()}>
                                            {
                                                permissionLoader ?
                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                    : (<></>)
                                            }
                                            <Translate text={'save'} /></button>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                        <div className={`permission-list roles-nav-left`}>
                            {
                                !_.isEmpty(selectedRole) ? (<>
                                    <div className="settings-inner-padding">
                                        <div className="radio-tab">
                                            <div className="form-check ml-0">
                                                <div className="form-radio-tabs">
                                                    {
                                                        allTabs.map((rec, index) => {
                                                            return <Fragment key={index}>
                                                                <InputRadio
                                                                    id={`key-${rec.type}`}
                                                                    type="radio"
                                                                    value={rec.type}
                                                                    name={rec.type}
                                                                    className="form-check-input form-radio-tab-child"
                                                                    checked={rec.type === activeTab ? true : false}
                                                                    onChange={(e) => {
                                                                        setActiveTab(rec.type);
                                                                    }}
                                                                />
                                                                <label htmlFor={`key-${rec.type}`} className={`radio-inline ${index === 0 ? 'left-border' : allTabs.length - 1 === index ? 'right-border' : ''}`}>{rec.type === 'tradeinPro' ? 'TRADE-IN PRO' : rec.type === 'eventsPro' ? 'EVENTS PRO' : rec.type === 'fleetPro' ? 'FLEET PRO' : rec.type === 'serviceFleetPro' ? 'SERVICE FLEET PRO' : rec.type.toUpperCase()}</label>
                                                            </Fragment>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-search w-100">
                                            <input placeholder="search permissions" aria-label="Search" className="w-100" value={searchPermission} onChange={(e) => { setSearchPermission(e.target.value) }} />
                                        </div>
                                    </div>
                                    {
                                        activeTab === 'sales' ? (
                                            <>
                                                <div className="form-row" style={{ padding: '5px 15px' }}>
                                                    <div className="form-group col-md-12">
                                                        <h3>Hide Lead Source in Pipeline and Dashboard</h3>
                                                        <ReactMultiSelect
                                                            options={currentSettings?.settings?.origins?.filter(a => a.active === true)?.map(r => { return { label: r.name, value: r.value } }) || []}
                                                            name={"excludeOrigins"}
                                                            placeholder={'select origin'}
                                                            onChange={(option) => {
                                                                const value = [];
                                                                !_.isEmpty(option) && option.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setSelectedRole({
                                                                    ...selectedRole,
                                                                    ['excludeOrigins']: value
                                                                })
                                                            }}
                                                            value={selectedRole?.excludeOrigins || []}
                                                            classNamePrefix={`basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (<></>)
                                    }
                                    <ul className={``}>
                                        {
                                            _.orderBy(_.chain(allPermissions.filter(({ name, value, type, groupBy }) =>
                                                groupBy === activeTab &&
                                                (!_.isEmpty(searchPermission) ? (
                                                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchPermission.toLowerCase()) : false) ||
                                                    (!_.isEmpty(type) ? type.toLowerCase().includes(searchPermission.toLowerCase()) : false) ||
                                                    (!_.isEmpty(value) ? value.toLowerCase().includes(searchPermission.toLowerCase()) : false)
                                                ) : true)
                                            ))
                                                .groupBy("type")
                                                .map((value, key) => ({
                                                    type: key, permissions: value
                                                }))
                                                .value(), ['sortorder'], ['asc']).map((rec, index) => {
                                                    return <PermissionList key={index} rec={rec} />
                                                })
                                        }
                                    </ul></>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModal show={showRoleModel}>
                <AddRole
                    {...props}
                    show={showRoleModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    role={editRole}
                    allRoles={roleList}
                />
            </PopUpModal>
            <PopUpModal show={levelModel.show}>
                <UpdateLevel
                    {...props}
                    show={levelModel.show}
                    title={levelModel.title}
                    handleClose={handleModelClose}
                    role={levelModel.role}
                    user={levelModel.user}
                    clientUsers={clientUsers}
                    setClientUsers={(val) => setClientUsers(val)}
                />
            </PopUpModal>
        </>
    );
}

export default UserRoles;