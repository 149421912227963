import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import _ from 'lodash';
import CustomDatePicker from './datePicker';
import CustomSelect from './customSelect';
import CustomModal from './customModal';
import NumberEditor from './numberEditor';
import AddressPicker from './addressPicker';
import moment from 'moment';
import CustomPopover from './customPopover';
import cellRenderer from './cellRenderer';

const GridView = (props) => {
    const gridRef = useRef();
    const currencySymbol = props.currencySymbol || '$ ';
    const [colDefs, setColDefs] = useState([])

    const context = useMemo(() => {
        return {
            currencySymbol: currencySymbol,
            settings: props?.currentSettings || {}
        };
    }, [props?.currentSettings]);

    useEffect(() => {
        if (_.isEmpty(props.dynamicCols))
            return

        const rowNo = {
            headerName: "#",
            value: "#",
            width: 60,
            suppressHeaderFilterButton: true,
            suppressHeaderMenuButton: true,
            suppressHeaderContextMenu: true,
            resizable: false,
            sortable: false,
            pinned: 'left',
            suppressColumnsToolPanel: true,
            suppressMovable: true,
            valueFormatter: (params) => {
                return `${parseInt(params.node.id) + 1}`;
            },
        };
        const allOrderFields = props.dynamicCols.map(r => {
            const _colSettings = _.find(props.dynamicCols, { value: r.value }) || {};
            if (_colSettings.dataType === 'date') {
                _colSettings.comparator = dateComparator;
                _colSettings.filter = 'agDateColumnFilter';
                _colSettings.filterParams = datefiltercomparator;
            } else {
                _colSettings.comparator = (valueA, valueB) => {
                    return otherComparator(valueA, valueB, _colSettings.dataType)
                }
                _colSettings.filter = _colSettings.dataType === 'price' || _colSettings.dataType === 'number' ? 'agNumberColumnFilter' : 'agSetColumnFilter';
                _colSettings.filterParams = _colSettings.dataType === 'price' || _colSettings.dataType === 'number' ? numberfiltercomparator : setfiltercomparator;
            }
            return {
                ..._colSettings,
                ...r,
                prefix: _colSettings.dataType === 'price' ? currencySymbol : null,
                field: r.value,
                headerName: r.name,
                hide: !Boolean(r.default),
                minWidth: 200,
                cellRenderer: cellRenderer,
                cellEditor: _colSettings.dataType === 'date' ? CustomDatePicker :
                    _colSettings.dataType === 'select' || _colSettings.dataType === 'multiselect' ? CustomSelect :
                        _colSettings.dataType === 'address' ? AddressPicker :
                            _colSettings.dataType === 'modal' ? CustomModal :
                                _colSettings.dataType === 'note' ? CustomPopover : NumberEditor,
                cellEditorPopup: true,
                cellEditorParams: {
                    modalFields: _colSettings?.modalFields || [],
                    dealerSettings: props.currentSettings,
                    onKeyDown: (event) => {
                        console.log('Key pressed: ', event.key);
                    }
                },
                cellClass: params => {
                    return `${params.column.colId}-${params.rowIndex} ${params?.data?.fieldComments?.[params?.column?.colId] ? 'cursor-pointer' : ''}`;
                },
                suppressHeaderMenuButton: Boolean(props.hideHeaderMenuButton),
                suppressHeaderContextMenu: Boolean(props.hideHeaderMenuButton),
                suppressMovable: Boolean(props.hideHeaderMenuButton),
            }
        })
        setColDefs([rowNo,
            ...allOrderFields.filter(a => a.hide === false),
            ...allOrderFields.filter(a => a.hide === true)])
    }, [props.dynamicCols])

    const dateComparator = (date1, date2) => {
        const date1Timestamp = moment(date1, (props?.dateFormat || 'DD/MM/YYYY')).toDate().getTime();
        const date2Timestamp = moment(date2, (props?.dateFormat || 'DD/MM/YYYY')).toDate().getTime();

        if (date1Timestamp < date2Timestamp) {
            return -1;
        } else if (date1Timestamp > date2Timestamp) {
            return 1;
        } else {
            return 0;
        }
    }

    const otherComparator = (val1, val2, type) => {
        const valueA = type === 'price' || type?.includes('number') ? Number(val1?.toString()?.replace(/\D/g, '') || '') : val1?.props?.children?.filter(r => _.isString(r))?.map(r => { return r })?.join(' ') || val1;
        const valueB = type === 'price' || type?.includes('number') ? Number(val2?.toString()?.replace(/\D/g, '') || '') : val2?.props?.children?.filter(r => _.isString(r))?.map(r => { return r })?.join(' ') || val2;

        if (type === 'price' || type?.includes('number')) {
            return valueA - valueB;
        } else if (valueA === valueB)
            return 0;
        else {
            return (valueA > valueB) ? 1 : -1;
        }
    }

    const datefiltercomparator = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = moment(dateAsString, (props?.dateFormat || 'DD/MM/YYYY')).toDate();
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        buttons: ["clear"],
    }

    const numberfiltercomparator = {
        comparator: (filterValue, cellValue) => {
            if (cellValue == null || cellValue === 0 || cellValue === undefined) return -1;
            if (cellValue < filterValue) return -1;
            if (cellValue > filterValue) return 1;
            return 0;
        },
        buttons: ["clear"],
    };

    const setfiltercomparator = {
        cellRenderer: cellRenderer,
        cellRendererParams: { isFilterRenderer: true },
        buttons: ["clear"],
    };

    useEffect(() => {
        console.log(gridRef?.current?.api)
        if (props.sideBar)
            gridRef?.current?.api?.setSideBarVisible && gridRef.current.api.setSideBarVisible(true);
    }, [gridRef?.current?.api])

    const columnResized = (params) => {
        if (params.finished) {
            props.saveSettings(params)
        }

    }

    const onColumnMoved = useCallback((params) => {
        if (params.finished) {
            props.saveSettings(params)
        }
    }, []);

    const onColumnVisible = useCallback((params) => {
        props.saveSettings(params)
    }, []);

    const onColumnPinned = useCallback((params) => {
        if (params.column) {
            const index = _.findIndex(params.api.getColumnDefs().filter(a => !Boolean(a.hide)), (a) => a.colId === params.column.getColId());
            let pinCols = [{ colId: params.column.getColId(), pinned: params.pinned }];
            if (params.pinned === 'left') {
                pinCols = params.api.getColumnDefs().filter(a => !Boolean(a.hide)).slice(0, index + 1).map(r => { return { colId: r.colId, pinned: "left" } })
            }
            params.api.applyColumnState({
                state: pinCols
            });
            props.saveSettings(params)
        }
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                    },
                },
            ],
            defaultToolPanel: "columns",
            hiddenByDefault: true
        };
    }, []);

    const getRowHeight = useCallback((params) => {
        return 38;
    }, []);

    const gridOptions = useMemo(() => {
        return {
            getContextMenuItems: [],
            // onFirstDataRendered: (params) => {
            //     params.api.ensureIndexVisible(68, 'middle');
            // },
        }
    }, []);


    const onCellValueChanged = useCallback((params) => {
        props.onGridValueChange(params)
        params.api.refreshCells();
        console.log(params)
    }, []);

    const onCellClicked = useCallback((params) => {
        if (props.onCellClicked)
            props.onCellClicked(params)
    }, []);

    const getContextMenuItems = useCallback(
        (params) => {
            var result = [
                {
                    name: "Copy",
                    shortcut: "Ctrl + C",
                    action: (cell) => {
                        let val = cell.value;
                        if (cell?.value?.props?.children?.length) {
                            val = cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                        }
                        params.api.flashCells({ rowNodes: [cell.node], columns: [cell.column.colId] });
                        navigator.clipboard.writeText(val);
                    },
                    icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
                },
                {
                    name: "Comments",
                    action: () => {
                        params.eGridCell = document.querySelectorAll(`.${params.column.colId}-${params.node.rowIndex}`)[0];
                        props.onContextMenuClick(params, 'comments')
                    },
                    icon: '<i class="ico icon-sms"></i>',
                }, {
                    name: "Change Logs",
                    action: () => {
                        props.onContextMenuClick(params, 'changeLogs')
                    },
                    icon: '<i class="ico  icon-history"></i>',
                },
                "separator",
                {
                    name: "Export",
                    subMenu: [
                        {
                            name: 'Excel Export',
                            action: function (cell) {
                                cell.api.exportDataAsExcel({
                                    columnKeys: props?.dynamicCols?.filter(a => a.default)?.map(r => { return r.value }) || [],
                                    fileName: props.excelfileName || 'ams-pro-download',
                                    sheetName: props.excelfileName || 'Sheet1',
                                    processCellCallback: function (cell) {
                                        // Manipulate the value however you need.
                                        if (cell?.value?.props?.children?.length) {
                                            return cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                                        }
                                        return cell.value;
                                    },
                                });
                            },
                            icon: '<i class="ico icon-excel-download"></i>',
                        },
                        {
                            name: 'CSV Export',
                            action: function (cell) {
                                cell.api.exportDataAsCsv({
                                    columnKeys: props?.dynamicCols?.filter(a => a.default)?.map(r => { return r.value }) || [],
                                    fileName: props.excelfileName || 'ams-pro-download',
                                    sheetName: props.excelfileName || 'Sheet1',
                                    processCellCallback: function (cell) {
                                        // Manipulate the value however you need.
                                        if (cell?.value?.props?.children?.length) {
                                            return cell.value.props.children.filter(r => _.isString(r)).map(r => { return r }).join(' ');
                                        }
                                        return cell.value;
                                    },
                                });
                            },
                            icon: '<i class="ico icon-excel-download"></i>',
                        }
                    ],
                    icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
                }
            ];
            return result;
        },
        [window],
    );

    const getMainMenuItems = useCallback(
        (params) => {
            var result = [
                ...params.defaultItems.slice(0, 2),
                'pinSubMenu'
            ];
            return result;
        },
        [],
    );
    const excelStyles = useMemo(() => {
        return [
            {
                id: "header",
                interior: {
                    color: "#c6e9d5",
                    pattern: "Solid",
                },
                borders: {
                    borderBottom: {
                        color: "#c6e9d5",
                        lineStyle: "Continuous",
                        weight: 1,
                    },
                },
                font: {
                    bold: true,
                },
            }
        ];
    }, []);

    return (
        <div className="grid-wrapper ag-theme-quartz"
            style={{ height: `${props.windowHeight}px` }} // the Data Grid will fill the size of the parent container

        >
            <AgGridReact
                ref={gridRef}
                getRowHeight={getRowHeight}
                rowData={props.rowData}
                columnDefs={colDefs}
                loading={props.loader}
                sideBar={sideBar}
                onColumnResized={columnResized}
                onColumnVisible={onColumnVisible}
                onColumnMoved={onColumnMoved}
                //gridOptions={gridOptions}
                onCellValueChanged={onCellValueChanged}
                onColumnPinned={onColumnPinned}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                getMainMenuItems={getMainMenuItems}
                excelStyles={excelStyles}
                context={context}
                onCellClicked={onCellClicked}
            //   components={components}
            // stopEditingWhenCellsLoseFocus={true}
            />
        </div>
    )
}

export default GridView;