import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import heic2any from 'heic2any'
//import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { default as _images } from '../images';
import {
   testDriveStatus, inboundLeadStatus, serviceStatus, enquiryStatus, defaulttipStatuses,
   defaultenquiryStatus, defaultActivityTypes, defaultenquiryLabels, tipStatus, stockStatus, userLevel, fleetProStatus,
   recommendationChoice, recommendationOption, recommPartQuotes, discount_type, service_type
} from './enum'
import { activityLogOptions, objChangeLog, objActivitylog, contactBasicDetailsVM, objNewEnquiryVM } from '../../src/views/pipeline/viewModel'
import { objService } from '../views/service/viewModel';
import { objTradeIP } from '../views/tradeinplus/viewModel';
import parser from "cron-parser";
import { firestoreDB } from './helper';
import Translate from '../constants/translate';
import toast from "toasted-notes";
import { objContact } from '../views/contacts/viewModel'
//const urlExists = require('url-exists');

Date.prototype.addHours = function (h) {
   this.setTime(this.getTime() + (h * 60 * 60 * 1000));
   return this;
}

class CommonHelper {
   getUserNamebyId(users, id, alternate) {
      if (id && users && users.filter(e => e.id === id || e.documentID === id).length > 0) {
         return users.filter(e => e.id === id || e.documentID === id)[0].name
      }
      return alternate ? alternate : ""
   }
   getUserNamebyDocumentId(users, id) {
      if (id && users && users.filter(e => e.documentID === id).length > 0) {
         return users.filter(e => e.documentID === id)[0].name
      }
      return ""
   }
   getUserDepartmentbyDocumentId(users, id) {
      if (id && users && users.filter(e => e.documentID === id).length > 0) {
         return users.filter(e => e.documentID === id)[0].department
      }
      return ""
   }
   getDealerNamebyId(clients, id) {
      if (id && clients.filter(e => e.id === id).length > 0) {
         return clients.filter(e => e.id === id)[0].name
      }
      return ""
   }
   getUserImagebyId(users, id, name) {
      if (!_.isEmpty(users) && id && users.filter(e => e.id === id || e.documentID === id).length > 0) {
         var image = Object.assign({}, users.filter(e => e.id === id || e.documentID === id)[0]);
         return (image.profileImage ? image.profileImage : (image.name ? this.showNameAvatar(image.name) : _images.nouser))
      }
      else if (name) {
         return this.showNameAvatar(name)
      }
      return _images.nouser
   }

   showUserAvatar(imageURL, name) {
      if (!_.isEmpty(imageURL)) {
         return (imageURL)
      }
      return (name ? this.showNameAvatar(name) : _images.nouser)
   }

   showLocale = (props, _text, _alternate) => {
      let _locales = props?.dealersettings?.locales;
      if (!_.isEmpty(_locales)) {
         if (_text && !_.isEmpty(_locales[_text]))
            return _locales[_text];
         else if (_text && !_.isEmpty(_locales[_text.toLowerCase()]))
            return _locales[_text.toLowerCase()];
         else if (!_.isEmpty(_alternate) && !_.isEmpty(_locales[_alternate]))
            return _locales[_alternate];
         else if (!_.isEmpty(_alternate) && !_.isEmpty(_locales[_alternate.toLowerCase()]))
            return _locales[_alternate.toLowerCase()];
         else
            return (_alternate ? _alternate : _text?.replace(/([a-z])([A-Z])/g, '$1 $2'));
      }
      return _alternate ? _alternate : _text?.replace(/([a-z])([A-Z])/g, '$1 $2');
   }

   replaceContact = (objData) => {
      const fields = Object.assign({}, objContact);
      for (let [key, value] of Object.entries(objData)) {
         if (key === 'dob' || key === 'licenseExpiry')
            fields[key] = value && moment(value).isValid() ? value : null;
         else
            fields[key] = value;
      }
      return this.removeEmptyOrNull(fields)
   }

   showLimitedChar(str, max_chars, tag, noClass) {
      if (str && max_chars) {

         if (str.length > max_chars) {
            let _cls = noClass ? '' : 'text-area-space'
            return <>
               {
                  tag === 'span' ?
                     <span placement="bottom" title={str} className={_cls}>{str.substr(0, max_chars) + '...'}</span>
                     :
                     <div placement="bottom" title={str} className={_cls}>{str.substr(0, max_chars) + '...'}</div>
               }
            </>
         }
         return <>{str}</>
      }
      return <>{str}</>
   }

   showNameAvatar(name) {
      try {
         return 'https://ui-avatars.com/api/?size=128&name=' + name + '&font-size=0.5&bold=true&color=fff&background=' + this.NameToColor(name);
      }
      catch (error) {
         console.log(error);
         return _images.nouser;
      }
   }

   NameToColor = (str) => {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      let h = Math.abs(hash % 360);
      let s = Math.abs(50);
      let l = Math.abs(70);

      s /= 100;
      l /= 100;

      let c = (1 - Math.abs(2 * l - 1)) * s,
         x = c * (1 - Math.abs((h / 60) % 2 - 1)),
         m = l - c / 2,
         r = 0,
         g = 0,
         b = 0;

      if (0 <= h && h < 60) {
         r = c; g = x; b = 0;
      } else if (60 <= h && h < 120) {
         r = x; g = c; b = 0;
      } else if (120 <= h && h < 180) {
         r = 0; g = c; b = x;
      } else if (180 <= h && h < 240) {
         r = 0; g = x; b = c;
      } else if (240 <= h && h < 300) {
         r = x; g = 0; b = c;
      } else if (300 <= h && h < 360) {
         r = c; g = 0; b = x;
      }

      r = Math.round((r + m) * 255).toString(16);
      g = Math.round((g + m) * 255).toString(16);
      b = Math.round((b + m) * 255).toString(16);


      if (r.length == 1)
         r = "0" + r;
      if (g.length == 1)
         g = "0" + g;
      if (b.length == 1)
         b = "0" + b;

      return r + g + b;
   }

   getNamebyId(obJArray, id, alternate) {
      if (id && obJArray && obJArray[id] && obJArray[id].name) {
         return obJArray[id].name;
      }
      return alternate ? alternate : ""
   }

   getLabelByMultipleValues(obJArray, _value, alternate, byName) {
      if (_value && !_.isEmpty(_value) && _.isArray(_value)) {
         let cells = [];
         _value.forEach((item) => {
            cells.push(byName ? this.getNameByValue(obJArray, item, alternate) : this.getLabelByValue(obJArray, item, alternate))
         })
         return cells.join(', ');
      }
      return !_.isEmpty(_value) ? _value : alternate
   }

   getLabelByValue(obJArray, _value, alternate) {
      if (!_.isEmpty(obJArray) && (_value || _.isBoolean(_value)) && obJArray.filter(e => e.value === _value).length > 0) {
         var label = obJArray.filter(e => e.value === _value)[0].label
         return (label ? label : _value)
      }
      return _value ? _value : alternate
   }

   getValueByLabel(obJArray, _value, alternate) {
      if (!_.isEmpty(obJArray) && (_value) && obJArray.filter(e => e.label.toLowerCase() === _value.toLowerCase()).length > 0) {
         var label = obJArray.filter(e => e.label.toLowerCase() === _value.toLowerCase())[0].value
         return (label ? label : _value)
      }
      return _value ? _value : alternate
   }
   getNameByCode(obJArray, _code, alternate) {
      if (!_.isEmpty(obJArray) && (_code || _.isBoolean(_code)) && obJArray.filter(e => e.code === _code || e.id === _code).length > 0) {
         var label = obJArray.filter(e => e.code === _code || e.id === _code)[0].name
         return (label ? label : _code)
      }
      return _code ? _code : alternate
   }


   getSlotTimeValues = () => {
      return [
         { active: true, full: true, value: '00:00', label: '12:00 AM', code: 0 },
         { active: true, full: true, value: '01:00', label: '1:00 AM', code: 1 },
         { active: true, full: true, value: '02:00', label: '2:00 AM', code: 2 },
         { active: true, full: true, value: '03:00', label: '3:00 AM', code: 3 },
         { active: true, full: true, value: '04:00', label: '4:00 AM', code: 4 },
         { active: true, full: true, value: '05:00', label: '5:00 AM', code: 5 },
         { active: true, full: true, value: '06:00', label: '6:00 AM', code: 6 },
         { active: true, full: true, value: '07:00', label: '7:00 AM', code: 7 },
         { active: true, full: true, value: '08:00', label: '8:00 AM', code: 8 },
         { active: true, full: true, value: '09:00', label: '9:00 AM', code: 9 },
         { active: true, full: true, value: '10:00', label: '10:00 AM', code: 10 },
         { active: true, full: true, value: '11:00', label: '11:00 AM', code: 11 },
         { active: true, full: true, value: '12:00', label: '12:00 PM', code: 12 },
         { active: true, full: true, value: '13:00', label: '1:00 PM', code: 13 },
         { active: true, full: true, value: '14:00', label: '2:00 PM', code: 14 },
         { active: true, full: true, value: '15:00', label: '3:00 PM', code: 15 },
         { active: true, full: true, value: '16:00', label: '4:00 PM', code: 16 },
         { active: true, full: true, value: '17:00', label: '5:00 PM', code: 17 },
         { active: true, full: true, value: '18:00', label: '6:00 PM', code: 18 },
         { active: true, full: true, value: '19:00', label: '7:00 PM', code: 19 },
         { active: true, full: true, value: '20:00', label: '8:00 PM', code: 20 },
         { active: true, full: true, value: '21:00', label: '9:00 PM', code: 21 },
         { active: true, full: true, value: '22:00', label: '10:00 PM', code: 22 },
         { active: true, full: true, value: '23:00', label: '11:00 PM', code: 23 },

         { active: true, half: true, value: '00:30', label: '12:30 AM', code: 0.3 },
         { active: true, half: true, value: '01:30', label: '1:30 AM', code: 1.3 },
         { active: true, half: true, value: '02:30', label: '2:30 AM', code: 2.3 },
         { active: true, half: true, value: '03:30', label: '3:30 AM', code: 3.3 },
         { active: true, half: true, value: '04:30', label: '4:30 AM', code: 4.3 },
         { active: true, half: true, value: '05:30', label: '5:30 AM', code: 5.3 },
         { active: true, half: true, value: '06:30', label: '6:30 AM', code: 6.3 },
         { active: true, half: true, value: '07:30', label: '7:30 AM', code: 7.3 },
         { active: true, half: true, value: '08:30', label: '8:30 AM', code: 8.3 },
         { active: true, half: true, value: '09:30', label: '9:30 AM', code: 9.3 },
         { active: true, half: true, value: '10:30', label: '10:30 AM', code: 10.3 },
         { active: true, half: true, value: '11:30', label: '11:30 AM', code: 11.3 },
         { active: true, half: true, value: '12:30', label: '12:30 PM', code: 12.3 },
         { active: true, half: true, value: '13:30', label: '1:30 PM', code: 13.3 },
         { active: true, half: true, value: '14:30', label: '2:30 PM', code: 14.3 },
         { active: true, half: true, value: '15:30', label: '3:30 PM', code: 15.3 },
         { active: true, half: true, value: '16:30', label: '4:30 PM', code: 16.3 },
         { active: true, half: true, value: '17:30', label: '5:30 PM', code: 17.3 },
         { active: true, half: true, value: '18:30', label: '6:30 PM', code: 18.3 },
         { active: true, half: true, value: '19:30', label: '7:30 PM', code: 19.3 },
         { active: true, half: true, value: '20:30', label: '8:30 PM', code: 20.3 },
         { active: true, half: true, value: '21:30', label: '9:30 PM', code: 21.3 },
         { active: true, half: true, value: '22:30', label: '10:30 PM', code: 22.3 },
         { active: true, half: true, value: '23:30', label: '11:30 PM', code: 23.3 },
      ]
   }

   refreshStageCount = (pipeline, dealersettings) => {

      if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client)) {
         try {

            let _searchObject = {
               "pipeline": 'LeadsBucket',
               "owner": localStorage.uid,
               "settingsID": dealersettings.oemID ? dealersettings.oemID : null
            }

            _searchObject = this.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);

            setTimeout(() => {
               const stageCountsBQ = window.firebase.functions().httpsCallable('enquiries-stageCountsBQ');
               stageCountsBQ(_searchObject).then((response) => {
                  //console.log('stageCountsBQ', _dpl, response);
               });
            }, 3000)
         }
         catch (error) {
            console.error(error)
         }
      }
   }

   formatCurrency = (symbol, num, maskPrice) => {
      var p = num.toFixed(2).split(".");
      var currency = p[0].split("").reverse().reduce(function (acc, num, i, orig) {
         return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
      }, "") + (p[1] > 0 ? ("." + p[1]) : '');
      let _formatPrice = `${(symbol ? symbol : '')}${currency}`
      return maskPrice ? this.maskPrice(_formatPrice) : _formatPrice
   }

   maskPrice = (price, maskSymbol) => {
      return price ? price.toString().split('').map(char => {
         return !_.isEmpty(_.trim(char)) && Number.isInteger(Number(char)) ? (maskSymbol || 'x') : char;
      }).join('') : price
   };

   getNameByValue(obJArray, _value, alternate, value_alternate) {
      if (!_.isEmpty(obJArray) && (_value || _.isBoolean(_value)) && obJArray.filter(e => e.value === _value).length > 0) {
         var label = obJArray.filter(e => e.value === _value)[0].name;
         // if (!_.isEmpty(obJArray.filter(e => e.value === _value)[0].sourceType)) {
         //    label += ` (${obJArray.filter(e => e.value === _value)[0].sourceType})`;
         // }
         return (label ? label : !_.isEmpty(value_alternate) ? value_alternate : !_.isEmpty(alternate) ? alternate : _value)
      }
      return !_.isEmpty(alternate) ? alternate : ''
   }

   getStaticFieldName(obJArray, _value, alternate, props) {
      if (!_.isEmpty(obJArray) && _value && _.find(obJArray, { value: _value })) {
         var label = _.find(obJArray, { value: _value })?.name;
         return (label ? label : !_.isEmpty(alternate) ? alternate : _value)
      }
      else if (!_.isEmpty(props?.dealersettings?.locales)) {
         return this.showLocale(props, _value, alternate)
      }
      return !_.isEmpty(alternate) ? alternate : _value;
   }

   getFieldByValue(obJArray, _value, field, alternate) {
      if (!_.isEmpty(obJArray) && (_value || _.isBoolean(_value)) && obJArray.filter(e => e.value === _value).length > 0) {
         var label = obJArray.filter(e => e.value === _value)[0][field ? field : 'name'];
         return (label ? label : !_.isEmpty(alternate) ? alternate : _value)
      }
      return !_.isEmpty(alternate) ? alternate : ''
   }

   isDynamicStatus(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.dynamic === true)[0]))
         return true
      else
         return false
   }

   isEnqStatusOpen(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.dynamic === true && !e.afterSold)[0]))
         return true
      else
         return false;
   }

   isEnqStatusSold(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.afterSold === true)[0]))
         return true
      else
         return _value === enquiryStatus.WON ? true : false
   }

   isStatusOpenOrSold(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && (e.dynamic === true || e.afterSold === true))[0]))
         return true
      else
         return _value === enquiryStatus.WON ? true : false
   }

   bindEnquiryStatusMenu(enquiryAllStatus, status, _enquiry, canModify, defaultStatus) {
      const { pipeline } = _enquiry;

      let _enquiryStatus = enquiryAllStatus;
      if (!_.isEmpty(defaultStatus))
         _enquiryStatus = _.filter(enquiryAllStatus, (v) => _.includes(defaultStatus, v.value));

      let dynamicStatuses = !_.isEmpty(_enquiryStatus) ? _enquiryStatus.filter(e => e.dynamic === true && !e.afterSold && e.active === true) : []
      let afterSoldStatuses = !_.isEmpty(_enquiryStatus) ? _enquiryStatus.filter(e => e.afterSold === true && e.active === true) : []

      const isDymStatusOpen = this.isEnqStatusOpen(enquiryAllStatus, status)
      const isStatusOpen = (_.isEmpty(status) || isDymStatusOpen || status === enquiryStatus.PENDING) ? true : false;
      const isStatusSold = this.isEnqStatusSold(enquiryAllStatus, status)

      let _dynamicStatusMenu = [];
      if (canModify && pipeline !== 'LeadsBucket') {
         if (isStatusOpen) {
            _dynamicStatusMenu = dynamicStatuses && dynamicStatuses.filter(e => e.value !== status)
            if (_enquiryStatus.filter(e => e.value === enquiryStatus.WON)[0])
               _dynamicStatusMenu.push(_enquiryStatus.filter(e => e.value === enquiryStatus.WON)[0])
            if (_enquiryStatus.filter(e => e.value === enquiryStatus.LOST)[0])
               _dynamicStatusMenu.push(_enquiryStatus.filter(e => e.value === enquiryStatus.LOST)[0])
         }
         else if (isStatusSold || status === enquiryStatus.CLOSED) {
            _dynamicStatusMenu = afterSoldStatuses && afterSoldStatuses.filter(e => e.value !== status)
            if (_enquiryStatus.filter(e => e.value === enquiryStatus.DELIEVERD)[0])
               _dynamicStatusMenu.push(_enquiryStatus.filter(e => e.value === enquiryStatus.DELIEVERD)[0])
            if (_enquiryStatus.filter(e => e.value === enquiryStatus.LOST)[0])
               _dynamicStatusMenu.push(_enquiryStatus.filter(e => e.value === enquiryStatus.LOST)[0])
         }
      }

      return _dynamicStatusMenu;
   }

   getEnquiriesByStatus(enquiryAllStatus, status, _enquiries) {
      if (_enquiries && status) {
         const enquiries = [];
         _enquiries.forEach((ele) => {
            const isDymStatusOpen = this.isEnqStatusOpen(enquiryAllStatus, ele.status)
            const isStatusOpen = (_.isEmpty(ele.status) || isDymStatusOpen || ele.status === enquiryStatus.PENDING) ? true : false;
            const isStatusSold = this.isEnqStatusSold(enquiryAllStatus, ele.status)

            if (status === enquiryStatus.OPEN && isStatusOpen) {
               enquiries.push(ele);
            }
            else if (status === enquiryStatus.WON && isStatusSold) {
               enquiries.push(ele);
            }
            else if (status !== enquiryStatus.OPEN && status !== enquiryStatus.WON && ele.status === status) {
               enquiries.push(ele);
            }

         })

         return enquiries
      }
      else
         return _enquiries
   }

   bindEnquiryLabel(enquiryLabels, label, alternate, clsName) {
      if (label) {
         let _objData = enquiryLabels && enquiryLabels.filter(e => e.value === label)[0];
         return <>{
            !_.isEmpty(_objData)
               ?
               <>
                  <div
                     key={_objData.value}
                     className={`badge badge-pill badge-label-empty ${clsName ? clsName : ''}`}
                     style={{
                        color: `${_objData.color ? _objData.color : '#f47815 '}`,
                        backgroundColor: `${_objData.color ? _objData.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${_objData.color ? _objData.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                     }}>{_objData.name.toUpperCase()}</div>
               </>
               :
               <>
                  {!_.isEmpty(alternate) ? alternate : ''}
               </>
         }</>
      }

      return !_.isEmpty(alternate) ? alternate : ''
   }

   bindStockLabel(stockLabels, status, alternate, clsName) {
      if (status) {
         let _objData = stockLabels && stockLabels.filter(e => e.value === (status === stockStatus.MCRETAILED ? stockStatus.DELIVERED : status))[0];
         return <>{
            !_.isEmpty(_objData)
               ?
               <>
                  <div
                     key={_objData.value}
                     //className={`badge badge-pill ${_objData.badge ? _objData.badge : 'badge-label-empty'} ${clsName ? clsName : ''}`}
                     className={`badge badge-pill badge-status-empty ${clsName || ''}`}
                     style={{ background: (_objData.color || '#6b717f') }}
                  >{status === stockStatus.MCRETAILED ? 'MCLAREN RETAILED' : _objData.name.toUpperCase()}</div>
               </>
               :
               <>
                  {!_.isEmpty(alternate) ? alternate : ''}
               </>
         }</>
      }

      return !_.isEmpty(alternate) ? alternate : ''
   }

   bindServiceStatusMenu(serviceAllStatus, status, permissions, fields, _recommRequiredList, servicePackages, canModify) {
      //console.log('serviceAllStatus', serviceAllStatus)
      // let dynamicStatuses = !_.isEmpty(serviceAllStatus) ? serviceAllStatus.filter(e => e.dynamic === true && !e.afterSold && e.active === true) : []
      let afterSoldStatuses = !_.isEmpty(serviceAllStatus) ? serviceAllStatus.filter(e => e.afterComplete === true && e.active === true) : []
      let beforeSoldStatuses = !_.isEmpty(serviceAllStatus) ? serviceAllStatus.filter(e => e.beforeComplete === true && e.active === true) : []
      let beforeApprovalStatuses = !_.isEmpty(serviceAllStatus) ? serviceAllStatus.filter(e => e.beforeApproval === true && e.active === true) : []
      let afterApprovalStatuses = !_.isEmpty(serviceAllStatus) ? serviceAllStatus.filter(e => e.afterApproval === true && e.active === true) : []

      // const isDymStatusOpen = this.isEnqStatusOpen(serviceAllStatus, status)
      // const isStatusOpen = (_.isEmpty(status) || isDymStatusOpen || status === enquiryStatus.PENDING) ? true : false;
      const isStatusSold = this.isServiceStatusComplete(serviceAllStatus, status)
      const isStatusOnProgress = this.isServiceStatusOnProgress(serviceAllStatus, status)
      const isStatusOnRequest = this.isServiceStatusOnRequest(serviceAllStatus, status)
      const isStatusApproved = this.isServiceStatusApproved(serviceAllStatus, status)

      let _dynamicStatusMenu = [];
      if (canModify && status) {
         if ((status === serviceStatus.SCHEDULED) && permissions?.checkInAccess &&
            _.find(serviceAllStatus, { value: serviceStatus.CHECKIN })) {
            _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.CHECKIN }))
         }

         if (isStatusOnRequest ||
            status === serviceStatus.CHECKIN ||
            status === serviceStatus.TECHNICIANREQUESTED ||
            status === serviceStatus.PARTSREQUESTED ||
            status === serviceStatus.WORKSHOPREQUESTED ||
            status === serviceStatus.ADVISORREQUESTED ||
            status === serviceStatus.OWNERREQUESTED) {

            if ((permissions?.checkInAccess || permissions?.serviceAdvisor) && _.find(serviceAllStatus, { value: serviceStatus.TECHNICIANREQUESTED }))
               _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.TECHNICIANREQUESTED }))

            if ((permissions?.technician || permissions?.serviceAdvisor) && _.find(serviceAllStatus, { value: serviceStatus.PARTSREQUESTED }))
               _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.PARTSREQUESTED }))

            if ((permissions?.partsInterpretor || permissions?.serviceAdvisor) && _.find(serviceAllStatus, { value: serviceStatus.WORKSHOPREQUESTED }))
               _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.WORKSHOPREQUESTED }))

            if ((permissions?.workshopManager || permissions?.serviceAdvisor) && _.find(serviceAllStatus, { value: serviceStatus.ADVISORREQUESTED }))
               _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.ADVISORREQUESTED }))

            if (!_.isEmpty(beforeApprovalStatuses) &&
               (permissions?.checkInAccess || permissions?.technician || permissions?.partsInterpretor || permissions?.workshopManager || permissions?.serviceAdvisor)) {
               beforeApprovalStatuses.forEach((data) => { _dynamicStatusMenu.push(data); })
            }

            if ((_.isEmpty(servicePackages) && _.isEmpty(_recommRequiredList) && permissions?.completedAccess) && isStatusOnRequest &&
               //fields.technicianStatus === serviceStatus.COMPLETED &&
               //(isStatusOnRequest || status === serviceStatus.TECHNICIANREQUESTED || status === serviceStatus.PARTSREQUESTED || status === serviceStatus.WORKSHOPREQUESTED) &&
               _.find(serviceAllStatus, { value: serviceStatus.COMPLETED }))
               _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.COMPLETED }))

         }

         if ((isStatusApproved || status === serviceStatus.OWNERAPPROVED) &&
            !_.isEmpty(afterApprovalStatuses) &&
            (permissions?.workshopManager || permissions?.serviceAdvisor || permissions?.partsInterpretor || permissions?.technician)) {
            afterApprovalStatuses.forEach((data) => { _dynamicStatusMenu.push(data); })
         }

         if ((isStatusApproved || status === serviceStatus.OWNERAPPROVED) &&
            (permissions?.workshopManager || permissions?.serviceAdvisor || permissions?.technician) &&
            _.find(serviceAllStatus, { value: serviceStatus.WORKINPROGRESS })) {
            _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.WORKINPROGRESS }))
         }

         if ((isStatusOnProgress || status === serviceStatus.WORKINPROGRESS) &&
            !_.isEmpty(beforeSoldStatuses) &&
            (permissions?.workshopManager || permissions?.serviceAdvisor || permissions?.partsInterpretor || permissions?.technician)) {
            beforeSoldStatuses.forEach((data) => { _dynamicStatusMenu.push(data); })
         }

         if ((isStatusOnProgress || status === serviceStatus.WORKINPROGRESS) &&
            permissions?.completedAccess &&
            _.find(serviceAllStatus, { value: serviceStatus.COMPLETED }))
            _dynamicStatusMenu.push(_.find(serviceAllStatus, { value: serviceStatus.COMPLETED }))

         if (isStatusSold || status === serviceStatus.COMPLETED) {
            _dynamicStatusMenu = afterSoldStatuses && afterSoldStatuses.filter(e => e.value !== status)

            // if (permissions?.completedAccess)
            //    _dynamicStatusMenu.push(serviceAllStatus.filter(e => e.value === serviceStatus.COMPLETED)[0])
         }

         _dynamicStatusMenu = _dynamicStatusMenu && _dynamicStatusMenu.filter(e => e.value !== status)
      }

      return _.uniq(_dynamicStatusMenu);
   }

   bindEventStatusMenu(allStatus, status, permissions, canModify) {

      let _dynamicStatusMenu = [];
      if (canModify && permissions?.accessToChangeEventStatus) {
         _dynamicStatusMenu = allStatus.filter(e => e.value !== status && e.active === true)
      }

      return _dynamicStatusMenu;
   }

   bindFleetStatusMenu(allStatus, status, permissions, canModify) {

      let _dynamicStatusMenu = [];
      if (canModify) {
         _dynamicStatusMenu = allStatus.filter(e => e.value !== status && e.active === true)
      }

      return _dynamicStatusMenu;
   }

   getFleetStatus = (doc, startDate, endDate) => {
      let _status = doc.status;
      let _startDate = startDate ? startDate : moment().format('YYYY-MM-DD')
      let _endDate = endDate ? endDate : moment().format('YYYY-MM-DD')
      if (!_.isEmpty(doc.statusDaterange)) {
         Object.keys(doc.statusDaterange).forEach((key) => {
            if (doc?.statusDaterange?.[key]?.startDate?.seconds
               && doc?.statusDaterange?.[key]?.endDate?.seconds
               && (
                  moment(`${_startDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]') ||
                  moment(`${_endDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]')
               )) {
               _status = key;
               return false;
            }
         })
      }

      if (!_.isEmpty(doc.statusBooking)) {
         doc.statusBooking.forEach(rec => {
            if (rec?.startDate?.seconds
               && rec?.endDate?.seconds
               && (
                  moment(`${_startDate} 09:00`).isBetween(`${moment.unix(rec.startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(rec.endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]') ||
                  moment(`${_endDate} 09:00`).isBetween(`${moment.unix(rec.startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(rec.endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]')
               )) {
               _status = rec.status;
               return false;
            }
         });
      }


      return _status;
   }



   bindTechStatusMenu(technicianAllStatus, status, permissions, fields, canModify) {

      let _dynamicStatusMenu = [];
      if (canModify) {
         if ((status === serviceStatus.COMPLETED || status === serviceStatus.PENDING || _.isEmpty(status)) &&
            (permissions?.workshopManager || permissions?.serviceAdvisor || permissions?.technician) &&
            _.find(technicianAllStatus, { value: serviceStatus.WORKINPROGRESS })) {
            _dynamicStatusMenu.push(_.find(technicianAllStatus, { value: serviceStatus.WORKINPROGRESS }))
         }

         if ((status === serviceStatus.WORKINPROGRESS) &&
            (fields.technician === localStorage.uid || permissions?.workshopManager || permissions?.serviceAdvisor) &&
            _.find(technicianAllStatus, { value: serviceStatus.COMPLETED })) {
            _dynamicStatusMenu.push(_.find(technicianAllStatus, { value: serviceStatus.COMPLETED }))
         }
      }

      return _dynamicStatusMenu;
   }

   isServiceStatusComplete(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.afterComplete === true)[0]))
         return true
      else
         return _value === serviceStatus.COMPLETED ? true : false
   }

   isServiceStatusOnProgress(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.beforeComplete === true)[0]))
         return true
      else
         return _value === serviceStatus.WORKINPROGRESS ? true : false
   }

   isServiceStatusOnRequest(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.beforeApproval === true)[0]))
         return true
      else
         return (_value === serviceStatus.TECHNICIANREQUESTED ||
            _value === serviceStatus.PARTSREQUESTED ||
            _value === serviceStatus.WORKSHOPREQUESTED ||
            _value === serviceStatus.ADVISORREQUESTED) ? true : false
   }

   isServiceStatusApproved(obJArray, _value) {
      if (!_.isEmpty(obJArray) && _value && !_.isEmpty(obJArray.filter(e => e.value === _value && e.afterApproval === true)[0]))
         return true
      else
         return (_value === serviceStatus.OWNERAPPROVED) ? true : false
   }

   getFullName(titles, _contact, alternate, byName) {
      if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.title ? (byName ? this.getNameByValue(titles, _contact.title, '') : this.getLabelByValue(titles, _contact.title, '')) + ' ' : '') +
            (_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : '') +
            (_contact.preferredName ? ' (' + _contact.preferredName.replace(/\u2028/g, '') + ')' : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : '') +
            (_contact.preferredName ? ' (' + _contact.preferredName.replace(/\u2028/g, '') + ')' : ''));
      }
      return alternate ? alternate : '';
   }

   getFullNameWithID(titles, _contact, alternate, byName) {
      if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.title ? (byName ? this.getNameByValue(titles, _contact.title, '') : this.getLabelByValue(titles, _contact.title, '')) + ' ' : '') +
            (_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : '') +
            (_contact.displayID ? ' (' + _contact.displayID.replace(/\u2028/g, '') + ')' : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : '') +
            (_contact.displayID ? ' (' + _contact.displayID.replace(/\u2028/g, '') + ')' : ''));
      }
      return alternate ? alternate : '';
   }
   //region PHONE FORMAT

   formatToGroupSizes = (format) => {
      // Remove all special characters to leave only '#' characters
      const cleanedFormat = format.replace(/[^#]/g, ' ');
      //console.log(cleanedFormat);
      // Calculate group sizes
      let currentCount = 0;
      const sizes = [];

      for (let i = 0; i < cleanedFormat.length; i++) {
         if (cleanedFormat[i] === '#') {
            currentCount += 1;
         }

         // Push to array when we reach the end of a group or the last character
         if ((i + 1 < cleanedFormat.length && cleanedFormat[i + 1] !== '#') || i + 1 === cleanedFormat.length) {
            if (currentCount > 0) sizes.push(currentCount);
            currentCount = 0;
         }
      }

      return sizes;
   };

   phoneFormat = (code, number, dealersettings, excelFormat) => {
      if (_.isEmpty(number)) return '';

      if (!_.isEmpty(dealersettings?.client?.clientSettings?.phoneFormat?.format) && !excelFormat) {

         // Remove any non-digit characters
         let digits = number.replace(/\D/g, '');
         // Remove leading 0 for formatting
         if (digits.startsWith('0')) digits = digits.substring(1);

         // Build the phone format dynamically
         let regexParts = [];

         let _format = dealersettings?.client?.clientSettings?.phoneFormat?.format;
         let formattedString = _format;
         this.formatToGroupSizes(_format).forEach((size, index) => {
            regexParts.push(`(\\d{0,${size}})`);
            formattedString = formattedString.replace('#'.repeat(size), `$${index + 1}`);
         });

         const regex = new RegExp(regexParts.join(''));

         if (dealersettings?.client?.clientSettings?.phoneFormat?.zeroPrefix)
            digits = "0" + digits;

         let formattedNumber = digits.replace(regex, formattedString).trim();

         if (dealersettings?.client?.clientSettings?.phoneFormat?.code && code) {
            formattedNumber = `${(code.indexOf('+') < 0 ? '+' : '') + code} ${formattedNumber}`
         }
         //console.log(regex, formattedString, formattedNumber, number, digits)
         return formattedNumber;
      }
      else {
         return `${code ? (code.indexOf('+') < 0 ? '+' : '') + code : ''} ${excelFormat ? number : this.formatUSNumber(number)}`
      }

   }

   getFullPhone(code, number, isUSFormat, stringFormat) {
      if (stringFormat) {
         return `${code ? (code.indexOf('+') < 0 ? '+' : '') + code : ''} ${number}`
      }
      else {
         return number
            ?
            <>
               {code ? (code.indexOf('+') < 0 ? '+' : '') + code : ''}
               {
                  isUSFormat
                     ?
                     <>{this.formatUSNumber(number)}</>
                     :
                     <NumberFormat
                        value={number}
                        displayType={'text'}
                        format={` ### ### #########`}
                     />

               }


            </>
            :
            ''
      }

   }

   formatUSNumber(value) {
      // Remove any non-digit characters
      const digits = value.replace(/\D/g, '');

      // // Check if the first digit is 0
      const hasLeadingZero = false;// digits.startsWith('0');
      let formattedNumber = digits;

      // // Remove leading 0 for formatting
      // if (hasLeadingZero) {
      //    formattedNumber = digits.substring(1);
      // }

      // Format the number as ### ### ####
      if (formattedNumber.length > 3) {
         formattedNumber = formattedNumber.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1 $2 $3').trim();
      }

      // Add the leading 0 back if it was there initially
      return hasLeadingZero ? '0' + formattedNumber : formattedNumber;

      // const match = entry
      //    .replace(/\D+/g, '')//.replace(/^1/, '')
      //    .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
      // const part1 = match.length > 2 ? ` ${match.substring(0, 3)}` : match
      // const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
      // const part3 = match.length > 6 ? ` ${match.substring(6, 10)}` : ''
      // return `${part1}${part2}${part3}`
   }

   phoneWithZero = (phoneCode, phone) => {
      const numberPattern = /\d+/g;
      let number = phone.match(numberPattern)?.join('');
      let code = phoneCode ? phoneCode.match(numberPattern)?.join('') : '';

      if (code && number.startsWith(code))
         return number.replace(code, '0');
      else if (number && number.startsWith('0'))
         return number;
      else
         return `0${number}`
   }

   validPhone = (phone, phoneCode) => {
      let code = "";
      if (phoneCode) code = phoneCode;
      if (phone) {
         const numberPattern = /\d+/g;
         let number = phone.match(numberPattern)?.join('');

         if (number && number.startsWith('0')) {
            number = number.substring(1);
         }

         if (number && number.startsWith(`${code}0`)) {
            number = number.slice(0, 3) + number.slice(4);
         }
         if (number && !number.startsWith(code)) {
            number = "+" + code + number;
         }
         if (number && !number.startsWith('+')) {
            number = "+" + number;
         }
         if (number?.length !== 12) {
            return number;
         }
         return number;
      }
      else return phone;
   }

   validPhoneZeroPrefix = (phone, phoneCode) => {
      //console.log('validPhoneZeroPrefix', phone, phoneCode)
      let code = "";
      if (phoneCode) code = phoneCode;
      if (phone) {
         const numberPattern = /\d+/g;
         let number = phone.match(numberPattern)?.join('');

         if (number && (number.startsWith('0') || number.startsWith(code))) {
            return number;
         }
         else if (number) {
            return "0" + number;
         }
         else return phone;
      }
      else return phone;
   }

   //#endregion PHONE FORMAT

   displayContactName(titles, _contact, alternate, byName) {
      if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.title ? (byName ? this.getNameByValue(titles, _contact.title, '') : this.getLabelByValue(titles, _contact.title, '')) + ' ' : '') +
            (_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : ''));
      }
      return alternate ? alternate : '';
   }

   displayContact(titles, _contact, alternate, byName) {
      if (!_.isEmpty(_contact) && _contact.businessContact && !_.isEmpty(_contact.company)) {
         return (_contact.company.name ? _contact.company.name : alternate);
      }
      else if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.title ? (byName ? this.getNameByValue(titles, _contact.title, '') : this.getLabelByValue(titles, _contact.title, '')) + ' ' : '') +
            (_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : ''));
      }
      return alternate ? alternate : '';
   }

   displayPreferredName(_contact, alternate) {
      if (!_.isEmpty(_contact) && _contact.businessContact && !_.isEmpty(_contact.company)) {
         return '';
      }
      else if (!_.isEmpty(_contact)) {
         return (_contact.preferredName ? ' (' + _contact.preferredName.replace(/\u2028/g, '') + ')' : '');
      }
      return alternate ? alternate : '';
   }

   displayName(enquiry) {
      return (enquiry.isContact && !_.isEmpty(enquiry.contact) && (!_.isEmpty(enquiry.contact.firstName) || !_.isEmpty(enquiry.contact.lastName)))
         ? this.displayContact([], enquiry.contact, '--')
         : (!_.isEmpty(enquiry.engageNewContact) ? this.displayContact([], enquiry.engageNewContact, '--') : (enquiry.displayID ? enquiry.displayID : enquiry.documentID))
   }

   displayBusinessAltContact(titles, _contact, alternate, byName) {
      if (!_.isEmpty(_contact) && _contact.businessContact && !_.isEmpty(_contact.company)) {
         return this.getFullName(titles, _contact, alternate, byName);
      }
      return alternate ? alternate : '';
   }

   displayFullContact(titles, _contact, alternate, byName) {
      if (!_.isEmpty(_contact) && _contact.businessContact && !_.isEmpty(_contact.company)) {
         return (_contact.company.name ? _contact.company.name : alternate);
      }
      else if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.title ? (byName ? this.getNameByValue(titles, _contact.title, '') : this.getLabelByValue(titles, _contact.title, '')) + ' ' : '') +
            (_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : '') +
            (_contact.preferredName ? ' (' + _contact.preferredName.replace(/\u2028/g, '') + ')' : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.firstName ? _contact.firstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.middleName ? _contact.middleName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.lastName ? _contact.lastName.replace(/\u2028/g, '') : ''));
      }
      return alternate ? alternate : '';
   }

   displayFullSecondaryContact(titles, _contact, alternate, byName) {
      if (!_.isEmpty(titles) && !_.isEmpty(_contact)) {
         return ((_contact.secondaryTitle ? (byName ? this.getNameByValue(titles, _contact.secondaryTitle, '') : this.getLabelByValue(titles, _contact.secondaryTitle, '')) + ' ' : '') +
            (_contact.secondaryFirstName ? _contact.secondaryFirstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.secondaryLastName ? _contact.secondaryLastName.replace(/\u2028/g, '') : '') +
            (_contact.secondaryPreferredName ? ' (' + _contact.secondaryPreferredName.replace(/\u2028/g, '') + ')' : ''));
      }
      else if (!_.isEmpty(_contact)) {
         return ((_contact.secondaryFirstName ? _contact.secondaryFirstName.replace(/\u2028/g, '') + ' ' : '') +
            (_contact.secondaryLastName ? _contact.secondaryLastName.replace(/\u2028/g, '') : ''));
      }
      return alternate ? alternate : '';
   }

   displayContactIcon(_contact, margin) {
      if (!_.isEmpty(_contact)) {
         return <>
            {_contact.businessContact ? (
               <i className={`ico icon-company ${_contact.contactType && _contact.contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} ${margin ? margin : ''}`}></i>
            ) : (
               <i className={`ico icon-Contacts ${_contact.contactType && _contact.contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} ${margin ? margin : ''}`}></i>
            )}</>
      }
      return <i className={`ico icon-Contacts ${margin ? margin : ''}`}></i>
   }

   displayPreferredContact(_contact) {
      if (!_.isEmpty(_contact) && _contact.businessContact && !_.isEmpty(_contact.company)) {
         return <></>;
      }
      else if (!_.isEmpty(_contact)) {
         return (
            _contact.preferredName ?
               (<span className='enquiry-details-preferred'> ({_contact.preferredName})</span>) :
               (<></>)
         );
      }
      return <></>;
   }

   getBaseDate(timestamp) {
      if (!timestamp) {
         timestamp = window.firebase.firestore.Timestamp.now()
      }
      var date = moment.unix(timestamp.seconds).format("YYYY-MM-DD")
      var convertDate = moment(date + " 00:00:00")._d
      return window.firebase.firestore.Timestamp.fromDate(convertDate)
   }
   getEndDate(timestamp) {
      if (!timestamp) {
         timestamp = window.firebase.firestore.Timestamp.now()
      }
      var date = moment.unix(timestamp.seconds).format("YYYY-MM-DD")
      var convertDate = moment(date + " 23:59:00")._d
      return window.firebase.firestore.Timestamp.fromDate(convertDate)
   }
   limit = (val, max) => {
      if (val.length === 1 && val[0] > max[0]) {
         val = '0' + val;
      }
      if (val.length === 2) {
         if (Number(val) === 0) {
            val = '01';
         } else if (val > max) {
            val = max;
         }
      }
      return val;
   }
   showCombineDate = (_sDate, _eDate, timezone) => {
      if (_sDate && _eDate) {
         let _start = _.isString(_sDate) ? moment(_sDate).tz(timezone || moment.tz.guess()) : moment.unix(_sDate.seconds).tz(timezone || moment.tz.guess())
         let _end = _.isString(_eDate) ? moment(_eDate).tz(timezone || moment.tz.guess()) : moment.unix(_eDate.seconds).tz(timezone || moment.tz.guess())
         if (_start.format('DD MMM YYYY') === _end.format('DD MMM YYYY')) {
            return _start.format('DD MMM YYYY')
         }
         else if (_start.format('MMM YYYY') === _end.format('MMM YYYY')) {
            return `${_start.format('DD')} - ${_end.format('DD MMM YYYY')}`
         }
         else if ((_start.format('YYYY') === _end.format('YYYY')) && (_start.format('MMM') !== _end.format('MMM'))) {
            return `${_start.format('DD MMM')} - ${_end.format('DD MMM YYYY')}`
         }
         else {
            return `${_start.format('DD MMM YYYY')} - ${_end.format('DD MMM YYYY')}`
         }

      }
      else if (_sDate) {
         return moment.unix(_sDate.seconds).tz(timezone || moment.tz.guess()).format('DD MMM YYYY')
      }
      else {
         return null
      }
   }

   showCombineTime = (_sDate, _eDate, timezone, eventSettings) => {
      if (_sDate && _eDate) {
         let _start = _.isString(_sDate) ? moment(_sDate).tz(timezone || moment.tz.guess()).format(`${eventSettings?.timeFormat || 'hh:mm A'}`) : moment.unix(_sDate.seconds).tz(timezone || moment.tz.guess()).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)
         let _end = _.isString(_eDate) ? moment(_eDate).tz(timezone || moment.tz.guess()).format(`${eventSettings?.timeFormat || 'hh:mm A'}`) : moment.unix(_eDate.seconds).tz(timezone || moment.tz.guess()).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)
         return (_start.includes(':00') && _end.includes(':00')) ? (_start.replace(':00', '') === _end.replace(':00', '') ? `${_start.replace(':00', '')}` : `${_start.replace(':00', '')} - ${_end.replace(':00', '')}`) : (_start === _end ? `${_start}` : `${_start} - ${_end}`)
      }
      else if (_sDate) {
         return moment.unix(_sDate.seconds).tz(timezone || moment.tz.guess()).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)
      }
      else {
         return null
      }
   }
   getdateFormat = (val) => {
      let date = this.limit(val.substring(0, 2), '31');
      let month = this.limit(val.substring(2, 4), '12');
      let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
      let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
      return fullDate
   }
   convertminstoDate = (val) => {
      var minutes = Math.floor(val / 60);
      var seconds = val - minutes * 60;
      var datehours = moment().format('YYYY-MM-DD H')
      return moment(datehours + ':' + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'))
   }
   convertsecstostr = (val) => {
      var minutes = Math.floor(val / 60);
      var seconds = val - minutes * 60;
      return minutes + ' Min ' + (seconds > 0 ? seconds + ' Sec' : '')
   }
   showFileSize = (size) => {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
   }
   getImageThumbnail = async (filePath, size) => {
      if (filePath && filePath.indexOf('base64') < 0 && size) {
         var __filePath = filePath.substring(0, filePath.lastIndexOf("?"));
         var __token = filePath.substring(filePath.lastIndexOf("?"), filePath.length);
         if (__filePath) {
            var __fileRest = __filePath.substring(0, __filePath.lastIndexOf("%2F") + 3);
            var __fileFullName = __filePath.substring(__filePath.lastIndexOf("%2F") + 3, __filePath.length);
            if (__fileFullName) {
               var __fileNAME = __fileFullName.substring(0, __fileFullName.lastIndexOf("."));
               var __fileEXT = __fileFullName.substring(__fileFullName.lastIndexOf(".") + 1, __fileFullName.length);
               var __thumb = "thumbs%2F";
               var thumbPath = __fileRest + __thumb + __fileNAME + '_' + size + (__fileEXT ? '.' + __fileEXT : '') + __token;
               // debugger
               // var resp = await urlExists(thumbPath)
               // console.log(resp)
               return thumbPath;
            }
         }
      }
      return filePath;
   }

   isJSON(str) {
      try {
         JSON.parse(str);
      } catch (e) {
         return false;
      }
      return true;
   }

   handleDateRangeFilter = (_filter, dealersetttings) => {
      if (!_.isEmpty(_filter) && !_.isEmpty(_filter.date)) {
         let _filterDate = Object.assign({}, _filter.date);

         moment.updateLocale('en', {
            week: {
               dow: dealersetttings?.client?.weekStart ? moment().day(dealersetttings.client.weekStart).day() : 1,
            },
         })

         if (_filterDate.name)
            _filter.dateType = _filterDate.name;

         if (_filterDate.type) {
            if (_filterDate.type === 'today') {
               _filter.startDate = moment().format('YYYY-MM-DD');
               _filter.endDate = moment().format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'yesterday') {
               _filter.startDate = moment().add(-1, 'days').format('YYYY-MM-DD');
               _filter.endDate = moment().add(-1, 'days').format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'thisWeek') {
               _filter.startDate = moment().startOf('week').format('YYYY-MM-DD');
               _filter.endDate = moment().endOf('week').format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'lastWeek') {
               _filter.startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
               _filter.endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'thisMonth') {
               _filter.startDate = moment().startOf('month').format('YYYY-MM-DD');
               _filter.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'lastMonth') {
               _filter.startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
               _filter.endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            }
            else if (_filterDate.type === 'lastQuarter') {
               _filter = {
                  ...this.getCurrentPreviousQuarter('previous')
               }
            }
            else if (_filterDate.type === 'thisQuarter') {
               _filter = {
                  ...this.getCurrentPreviousQuarter('current')
               }
            }
            else if (_filterDate.type === 'custom' && !_.isEmpty(_filterDate.range)) {
               let _start = _filterDate.range[0] ? _filterDate.range[0] : null;
               let _end = _filterDate.range[1] ? _filterDate.range[1] : null;
               if (_start)
                  _filter.startDate = moment(_start).format('YYYY-MM-DD');
               if (_end)
                  _filter.endDate = moment(_end).format('YYYY-MM-DD');
            }
         }
      }
      return _filter
   }

   handleDynamicDateRangeFilter = (_filterDate, dealersetttings) => {

      moment.updateLocale('en', {
         week: {
            dow: dealersetttings.client.weekStart ? moment().day(dealersetttings.client.weekStart).day() : 1,
         },
      })

      if (_filterDate.dateType) {
         if (_filterDate.dateType === 'today') {
            _filterDate.startDate = moment().format('YYYY-MM-DD');
            _filterDate.endDate = moment().format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'yesterday') {
            _filterDate.startDate = moment().add(-1, 'days').format('YYYY-MM-DD');
            _filterDate.endDate = moment().add(-1, 'days').format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'thisWeek') {
            _filterDate.startDate = moment().startOf('week').format('YYYY-MM-DD');
            _filterDate.endDate = moment().endOf('week').format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'lastWeek') {
            _filterDate.startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
            _filterDate.endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'thisMonth') {
            _filterDate.startDate = moment().startOf('month').format('YYYY-MM-DD');
            _filterDate.endDate = moment().endOf('month').format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'lastMonth') {
            _filterDate.startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            _filterDate.endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
         }
         else if (_filterDate.dateType === 'lastQuarter') {
            _filterDate = {
               ...this.getCurrentPreviousQuarter('previous')
            }
         }
         else if (_filterDate.dateType === 'thisQuarter') {
            _filterDate = {
               ...this.getCurrentPreviousQuarter('current')
            }
         }
         else if (_filterDate.dateType === 'custom' && !_.isEmpty(_filterDate.range)) {
            let _start = _filterDate.range[0] ? _filterDate.range[0] : null;
            let _end = _filterDate.range[1] ? _filterDate.range[1] : null;
            if (_start)
               _filterDate.startDate = moment(_start).format('YYYY-MM-DD');
            if (_end)
               _filterDate.endDate = moment(_end).format('YYYY-MM-DD');
         }
      }

      return _filterDate
   }

   showFilterLength = (_filterValue) => {
      let length = 0
      if (!_.isEmpty(_filterValue?.dynamicFields)) { length = (length + _filterValue.dynamicFields.length) - 1; }
      length = length + Object.keys(_filterValue).length;

      return length;

   }

   handleMakeModelFilter = (_filter, dealersetttings) => {
      if (!_.isEmpty(_filter) &&
         !_.isEmpty(_filter.make) &&
         _.isEmpty(_filter.model) &&
         (!_.isEmpty(_filter.vehicleGroup) || !_.isEmpty(_filter.vehicleType)) &&
         !_.isEmpty(dealersetttings)) {

         let models = [];
         const brands = dealersetttings.allMakeModels;
         let _models = !_.isEmpty(brands.filter(m => m.value === _filter.make)[0]) ? brands.filter(m => m.value === _filter.make)[0].models : [];

         let _goups = !_.isEmpty(_filter.vehicleGroup) ? _filter.vehicleGroup.split(',') : null;
         let _types = !_.isEmpty(_filter.vehicleType) ? _filter.vehicleType.split(',') : null;
         models = _models && _models.filter(m => (_types ? _types.indexOf(m.type) >= 0 : true) && (_goups ? _goups.indexOf(m.group) >= 0 : true))
         let _model = Array.prototype.map.call(models, function (item) { return item.value; }).join(",")

         if (_model) {
            _filter.model = _model;
            _filter.autofillModel = true;
         }

      }
      else if (!_.isEmpty(_filter) &&
         !_.isEmpty(_filter.make) &&
         !_.isEmpty(_filter.model) &&
         (!_.isEmpty(_filter.vehicleGroup) || !_.isEmpty(_filter.vehicleType)) &&
         !_.isEmpty(dealersetttings)) {
         _filter.vehicleGroup = null;
         _filter.vehicleType = null;
      }
      return _filter
   }

   bindEvalModelDescription = (data) => {

      let _description = null;
      if (data) {
         if (data.Make)
            _description = data.Make + ' '
         if (data.Series)
            _description = (_description ? _description : '') + data.Series + ' '
         if (data.Model)
            _description = (_description ? _description : '') + data.Model + ' '
         if (data.Variant)
            _description = (_description ? _description : '') + data.Variant + ' '
         if (data.Cylinders)
            _description = (_description ? _description : '') + data.Cylinders + 'cyl '
         if (data.Body)
            _description = (_description ? _description : '') + data.Body + ' '
         if (data.Transmission)
            _description = (_description ? _description : '') + data.Transmission + ' '
         if (data.Drive)
            _description = (_description ? _description : '') + data.Drive + ' '
      }


      return _description ? _.trim(_description) : null;

   }

   bindAutoGrabDescription = (vehicle) => {
      let _description = null;

      if (vehicle?.title)
         _description = vehicle.title
      else if (vehicle) {
         if (vehicle.year)
            _description = (_description ? _description : '') + vehicle.year + ' '
         if (vehicle.make)
            _description = (_description ? _description : '') + vehicle.make + ' '
         if (vehicle.model)
            _description = (_description ? _description : '') + vehicle.model + ' '
         if (vehicle.badge)
            _description = (_description ? _description : '') + vehicle.badge + ' '
         if (vehicle.body_type)
            _description = (_description ? _description : '') + vehicle.body_type + ' '
         if (vehicle.transmission_type)
            _description = (_description ? _description : '') + vehicle.transmission_type + ' '

      }

      return _description ? _.trim(_description) : null;
   }

   bindAutoGrabDetail = (state, _data, _fields) => {
      let data = Object.assign({}, _data?.vehicle);
      if (data.make) state.make = data.make;
      if (data.model) state.model = data.model;
      if (data.body_type) state.type = data.body_type;
      if (data.year) state.year = data.year;

      if (data.title)
         state.modelDescription = data.title;
      else state.modelDescription = this.bindAutoGrabDescription(data);

      if (data.capacity_cc) state.engineSize = data.capacity_cc.toString();
      if (data.num_doors) state.doors = data.num_doors.toString();
      if (data.engine_type) state.engineType = data.engine_type;

      if (data.drive_type) state.driveType = data.drive_type;
      if (data.fuel_type) state.fuelType = data.fuel_type;
      if (data.num_cylinders) state.cylinders = data.num_cylinders;
      if (data.num_gears) state.gears = data.num_gears;
      if (data.num_seats) state.seats = data.num_seats;
      if (data.power_kw) state.powerKw = data.power_kw;
      if (data.range) state.range = data.range;
      if (data.series) state.series = data.series;
      if (data.torque_nm) state.torqueNm = data.torque_nm.toString();
      if (data.transmission) state.transmissionType = data.transmission;
      if (data.wheelbase) state.wheelbase = data.wheelbase;
      if (data.badge) state.badge = data.badge;
      if (data.region) state.region = data.region;
      if (data.release_month && data.release_year) {
         state.buildDate = `${data.release_year}-${this.limit(data.release_month, '12')}-01`
      }

      if (data.regNo) state.regNo = data.regNo; else if (_fields?.regNum) state.regNo = _fields.regNum;
      if (data.vinNo) state.chassisNo = data.vinNo; else if (_fields?.vinNum) state.chassisNo = _fields.vinNum;
      if (_data.colour) state.exteriorColor = _data.colour;

      if (data.compliance_plate) state.complianceDate = data.compliance_plate + '-01';
      if (data.registration_expiry) state.regDate = data.registration_expiry;
      if (data.plate_number) state.regNo = data.plate_number;
      if (data.vin) state.chassisNo = data.vin;
      if (data.registration_status) state.regStatus = data.registration_status;
      if (data.state) state.regState = data.state;
      if (data.engineNo) state.engineNo = data.engineNo;

      if (data.transmission_type) {
         if (data.transmission_type.toLowerCase().indexOf('auto') > -1)
            state.transmission = 'Automatic';
         else if (data.transmission_type.toLowerCase().indexOf('manu') > -1)
            state.transmission = 'Manual';
      }

      if (data.id) {
         state.autograbID = data.id;
      }

      return state;
   }

   isGroupOrRegionOrOemUser = (dealersettings, pipelineView) => {
      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
         if ((dealersettings.level === 'oem' && dealersettings.oemID) || (dealersettings.level === 'group' && dealersettings.groupID) || (dealersettings.level === 'region' && dealersettings.regionID))
            return true;
         else
            return false;
      }
      return false;
   }

   saveChangeLog = (clientID, groupID, recordID, logNotes, type, subType, batch) => {
      const newlogVM = Object.assign({}, objChangeLog);
      newlogVM.type = type ? type : '';
      newlogVM.subType = subType ? subType : '';
      newlogVM.logBy = localStorage.uid;
      newlogVM.logDate = window.firebase.firestore.Timestamp.now();
      newlogVM.clientID = clientID ? clientID : null;
      newlogVM.groupID = groupID ? groupID : null;
      newlogVM.recordId = recordID ? recordID : null;
      newlogVM.documentID = window.firebase.firestore().collection('changeLogs').doc().id;
      newlogVM.notes = logNotes ? logNotes : '';
      newlogVM.modifiedFrom = 'web';

      if (!_.isEmpty(batch)) {
         batch.set(window.firebase.firestore().doc(`changeLogs/${newlogVM.documentID}`), newlogVM, { merge: true });
      }
      else {
         window.firebase.firestore().doc(`changeLogs/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
               console.error(error);
            });
      }


   };

   saveFileChangeLog = (objLog, subType, batch) => {
      const newlogVM = Object.assign({}, objChangeLog);
      if (objLog.stockID)
         newlogVM.type = 'stock';
      else
         newlogVM.type = objLog.type ? objLog.type : '';

      newlogVM.subType = subType ? subType : '';
      newlogVM.logBy = localStorage.uid;
      newlogVM.logDate = window.firebase.firestore.Timestamp.now();
      newlogVM.clientID = objLog.clientID ? objLog.clientID : null;
      newlogVM.groupID = objLog.groupID ? objLog.groupID : null;
      newlogVM.recordId = objLog.serviceID ? objLog.serviceID :
         (objLog.tradeinProID ? objLog.tradeinProID :
            (objLog.enquiryID ? objLog.enquiryID :
               (objLog.stockID ? objLog.stockID :
                  (objLog.companyID ? objLog.companyID :
                     (objLog.contactID ? objLog.contactID : null)))));
      newlogVM.path = objLog.path ? objLog.path : null;
      newlogVM.documentID = window.firebase.firestore().collection('changeLogs').doc().id;
      newlogVM.notes = objLog.notes ? objLog.notes : '';
      newlogVM.modifiedFrom = 'web';

      if (!_.isEmpty(batch)) {
         batch.set(window.firebase.firestore().doc(`changeLogs/${newlogVM.documentID}`), newlogVM, { merge: true });
      }
      else {
         window.firebase.firestore().doc(`changeLogs/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
               console.error(error);
            });
      }
   };

   saveAuditLog = (objLog, batch) => {
      const newlogVM = Object.assign({}, objLog);
      newlogVM.logBy = localStorage.uid;
      newlogVM.logDate = window.firebase.firestore.Timestamp.now();
      newlogVM.documentID = window.firebase.firestore().collection('auditLogs').doc().id;
      newlogVM.platform = 'web';

      // newlogVM.type = type ? type : '';
      // newlogVM.subType = subType ? subType : '';
      // newlogVM.clientID = clientID ? clientID : null;
      // newlogVM.groupID = groupID ? groupID : null;
      // newlogVM.recordId = recordID ? recordID : null;
      // newlogVM.notes = logNotes ? logNotes : '';
      if (!_.isEmpty(batch)) {
         batch.set(window.firebase.firestore().doc(`auditLogs/${newlogVM.documentID}`), newlogVM, { merge: true });
      }
      else {
         window.firebase.firestore().doc(`auditLogs/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
               console.error(error);
            });
      }
   };

   saveTradeInProLog = (dealersettings, objLog, batch) => {
      const newlogVM = Object.assign({}, objLog);
      if (newlogVM.tradeinProID && newlogVM.offerID) {
         newlogVM.addedBy = localStorage.uid;
         newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
         newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;
         newlogVM.addedFrom = 'web';
         newlogVM.isDeleted = false;
         if (!newlogVM.projectId) newlogVM.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

         if (!_.isEmpty(batch)) {
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${newlogVM.tradeinProID}/offerLogs/${newlogVM.documentID}`), newlogVM, { merge: true });
         }
         else {
            firestoreDB(dealersettings).firestore().doc(`tradeinPro/${newlogVM.tradeinProID}/offerLogs/${newlogVM.documentID}`)
               .set(newlogVM, { merge: true })
               .catch(error => {
                  console.error(error);
               });
         }
      }

   };

   saveTradeInProNotes = (dealersettings, objLog, batch) => {
      const newlogVM = Object.assign({}, objLog);
      if (newlogVM.tradeinProID && newlogVM.offerID) {
         newlogVM.addedBy = localStorage.uid;
         newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
         newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;
         newlogVM.addedFrom = 'web';
         newlogVM.isDeleted = false;
         if (!newlogVM.projectId) newlogVM.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

         if (!_.isEmpty(batch)) {
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${newlogVM.tradeinProID}/offerNotes/${newlogVM.documentID}`), newlogVM, { merge: true });
         }
         else {
            firestoreDB(dealersettings).firestore().doc(`tradeinPro/${newlogVM.tradeinProID}/offerNotes/${newlogVM.documentID}`)
               .set(newlogVM, { merge: true })
               .catch(error => {
                  console.error(error);
               });
         }
      }

   };

   saveActivityLog = (_log, logNotes, type, subType, mentions, dealersettings) => {
      if (_.trim(logNotes)) {
         const newlogVM = Object.assign({}, objActivitylog);
         newlogVM.type = type;
         newlogVM.subType = subType ? subType : null;;
         newlogVM.isDone = true;
         newlogVM.owner = localStorage.uid;
         newlogVM.addedBy = localStorage.uid;
         newlogVM.modifiedBy = localStorage.uid;
         newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
         newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
         newlogVM.groupID = _log?.groupID ? _log.groupID : (_log?.enquiry?.groupID ? _log.enquiry.groupID : null);
         newlogVM.clientID = _log?.clientID ? _log.clientID : (_log?.enquiry?.clientID ? _log.enquiry.clientID : null);
         newlogVM.enquiryID = _log?.enquiryID ? _log.enquiryID : (_log?.enquiry?.documentID ? _log.enquiry.documentID : null);
         newlogVM.contactID = _log?.contactID ? _log.contactID : (_log?.enquiry?.contact?.documentID ? _log.enquiry.contact.documentID : null);
         newlogVM['enquiry'] = this.getMinifiedData(_log?.enquiry, 'enquiry');
         newlogVM['contact'] = this.getMinifiedData(_log?.enquiry?.contact, 'contact');
         newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
         newlogVM.startDate = window.firebase.firestore.Timestamp.now();
         newlogVM.notes = logNotes ? _.trim(logNotes) : '';

         if (!_.isEmpty(mentions)) {
            if (mentions.length > 0) {
               let convertedIDs = []
               let rawIDs = mentions.map(mentionObject => mentionObject.id)
               rawIDs.forEach(id => {
                  if (id.includes(',')) {
                     let allIDs = id.split(',')
                     allIDs.forEach(a => {
                        convertedIDs.push(a)
                     })
                  } else {
                     convertedIDs.push(id)
                  }
               })

               if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                  newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
               } else {
                  newlogVM.mentions = _.uniq(convertedIDs)
               }
            }
         }
         Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
         newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
         firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
               console.error(error);
            });
      }

   };

   convertEnquiryVM = (doc, props) => {
      // if (doc.convertVM === true)
      //    return doc
      const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
      const clientBrands = props.dealersettings && props.dealersettings.client && _.isArray(props.dealersettings.client.brands) ? props.dealersettings.client.brands : [];
      doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
      doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
      doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
      doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
      doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
      doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
      doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
      doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
      doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;
      doc.appointmentDate = doc.appointmentDate && doc.appointmentDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.appointmentDate._seconds)._d) : doc.appointmentDate;
      doc.convertedDateOEM = doc.convertedDateOEM && doc.convertedDateOEM._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateOEM._seconds)._d) : doc.receivedDate;
      doc.convertedDateRegion = doc.convertedDateRegion && doc.convertedDateRegion._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateRegion._seconds)._d) : doc.receivedDate;
      doc.convertedDateGroup = doc.convertedDateGroup && doc.convertedDateGroup._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateGroup._seconds)._d) : doc.receivedDate;
      if (!_.isEmpty(doc.lastNote)) {
         doc.lastNote.modifiedDate = doc.lastNote.modifiedDate && doc.lastNote.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lastNote.modifiedDate._seconds)._d) : doc.lastNote.modifiedDate;
      }
      if (_.isEmpty(doc.stageDate)) {
         doc.stageDate = doc.addedDate;
      }
      doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
         return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
      })]

      let listVM = doc;
      listVM.objDoc = Object.assign({}, doc);
      if (doc.pipeline === 'LeadsBucket' && doc.status != 'prospectLost' && doc.status != 'lost' && _.isEmpty(doc.systemType)) {
         doc.status = '';
      }

      if (doc.stage === 'InboundLead') {
         doc.status = inboundLeadStatus[doc.status] ? inboundLeadStatus[doc.status].toLowerCase() : 'pending';
      }

      if (doc.status === 'won' || doc.status === 'delivered') {
         listVM.soldCount = doc.soldCount > 0 ? doc.soldCount : 1;
      }

      let dealersettings = props.dealersettings;

      if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
         listVM.dealerName = this.getOtherDealerName(props.dealersettings, doc.clientID);
      }
      else
         listVM.dealerName = '';


      let _setttings = doc.clientID && dealersettings && dealersettings.client && dealersettings.client.settings;

      const enquiryAllStatus = (_setttings ? _setttings.enquiryStatus : []);
      listVM.originValue = doc.origin;
      listVM.leadSourceValue = doc.leadSource;
      listVM.enquiryTypeValue = doc.enquiryType;
      listVM.campaignValue = doc.campaign;
      listVM.statusValue = doc.stage === 'InboundLead' ? 'pending' : doc.status;
      listVM.labelValue = doc.label;
      listVM.pipelineValue = doc.pipeline;
      listVM.stageValue = doc.stage;
      listVM.ownerValue = doc.owner;
      listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : this.getNameByValue(enquiryAllStatus, doc.status, '');
      listVM.lastStatus = doc.lastStatus;
      listVM.stageDateValue = doc.stageDate;
      var inboundSource = props.dealersettings.client && props.dealersettings.client.integrations ?
         props.dealersettings.client.integrations.filter(e => e.inbound === true && e.active === true && e.enabled === true).map(rec => { return rec.value }) : []
      if (!_.isEmpty(doc.systemType)) {
         listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
         doc.tags = [{ type: doc.systemType }];
      }
      else if ((doc.pipeline === 'LeadsBucket') || (doc.pipeline !== 'LeadsBucket' && Boolean(doc.isConverted) === true)) {
         listVM.inboundType = _.isArray(doc.tags) && _.filter(doc.tags, (v) => _.indexOf(inboundSource, v.type) >= 0).length > 0 ?
            doc.tags.map(r => { return r.type }) : ['LeadsBucket']
      }
      // if (doc.status === 'pendingLost') {
      //    doc.status = 'lost'
      // }

      listVM.pipeline = this.getNameByValue(props.dealersettings.client.settings.allPipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.client.settings.allPipelines, { value: listVM.pipelineValue })) {
         listVM.stage = this.getNameByValue(_.find(props.dealersettings.client.settings.allPipelines, { value: listVM.pipelineValue }).stages, doc.stage, '');
      }

      listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered') ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.deliveryOn = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      listVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      listVM.deliveryDate = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
      listVM.strResponseTime = doc.responseTime > 0 ? this.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
      listVM.strAllocatedTime = doc.allocatedTime > 0 ? this.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
      listVM.strContactedTime = doc.contactedTime > 0 ? this.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : '';
      listVM.confirmedBy = doc.confirmedBy ? this.getUserNamebyId(allUsers, doc.confirmedBy) : '';
      listVM.confirmedDate = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.verifiedBy = doc.verifiedBy ? this.getUserNamebyId(allUsers, doc.verifiedBy) : '';
      listVM.verifiedDate = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.financeStatus = this.getNameByValue(_setttings && _setttings.financeStatus, doc.financeStatus, '');
      listVM.afterMarketStatus = this.getNameByValue(_setttings && _setttings.afterMarketStatus, doc.afterMarketStatus, '');
      listVM.convertedOwner = doc.convertedBy ? this.getUserNamebyId(allUsers, doc.convertedBy) : '';

      listVM.convertedOnOEM = doc.convertedDateOEM ? moment.unix(doc.convertedDateOEM.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.convertedOwnerOEM = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.convertedOwnerRegion = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.convertedOwnerGroup = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.nextActivityDate = doc?.nextActivity?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc?.nextActivity?.startDate?._seconds)._d) : doc?.nextActivity?.startDate;


      //BIND LOST REASONS
      if (doc.lostReason) {
         let lostReasons = _setttings ? _setttings.lostReasons : [];
         let _lostReason = doc.lostReason;
         listVM.lostReasonValue = doc.lostReason;
         listVM.lostSubReasonValue = doc.lostSubReason;
         listVM.lostReason = this.getNameByValue(lostReasons, doc.lostReason, '');
         if (doc.lostSubReason && doc.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
            let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
               lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
            listVM.lostSubReason = this.getNameByValue(lostSubReasons, doc.lostSubReason, '');
         }
      }

      let _objCurrentStatus = doc.status && enquiryAllStatus.filter(e => e.value === doc.status)[0];
      let _statusDate = _.orderBy(Object.assign([], doc.statusHistory), ['date'], ['desc']).filter(e => e.status === doc.status)[0]?.date;
      listVM.currentStatusDate = _statusDate && _statusDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_statusDate._seconds)._d) : _statusDate;
      listVM.statusDate = listVM.currentStatusDate ? moment.unix(listVM.currentStatusDate.seconds).format('DD MMM YYYY hh:mm A') : '';
      listVM.origin = this.getNameByValue(_setttings && _setttings.origins, doc.origin, '');
      listVM.leadSource = this.getNameByValue(_setttings && _setttings.leadSource, doc.leadSource, '');
      listVM.enquiryType = this.getNameByValue(_setttings && _setttings.enquiryTypes, doc.enquiryType, '');
      listVM.campaign = this.getNameByValue(_setttings && _setttings.campaigns, doc.campaign, '');
      listVM.labelName = this.getNameByValue(_setttings && _setttings.enquiryLabels, doc.label, '');
      listVM.label = this.bindEnquiryLabel(_setttings && _setttings.enquiryLabels, doc.label);
      if (doc.stage === 'InboundLead')
         listVM.status = <span className={`status-pending`}>{'Pending'}</span>;
      else if (doc.status === 'pendingLost') {
         const lostStatus = _.find(enquiryAllStatus, { value: 'lost' }) || { name: 'Lost', color: '#ef4136' }
         listVM.status = (<><span
            className={`status-empty`}
            style={{ background: (lostStatus.color ? lostStatus.color : '#333') }}
         >
            {lostStatus.name}
         </span>
            <span className="pipeline-pendinglost ml-1" style={{ float: 'none', paddingLeft: '0px' }}> <span className="status-pendinglost-circle">PA</span> </span>
         </>)
      }
      else
         listVM.status = !_.isEmpty(doc.status) ?
            (
               _objCurrentStatus
                  ?
                  <>
                     <span key={doc.status}
                        className={`status-${doc.status === 'open' ? 'open' : 'empty'}`}
                        style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                     >
                        {_objCurrentStatus.name}
                     </span>
                  </>
                  :
                  <>

                  </>
            ) : ('');


      listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.owner = this.getUserNamebyId(allUsers, listVM.ownerValue);
      listVM.ownerInbound = this.getUserNamebyId(allUsers, doc.ownerInbound);
      listVM.financeBM = this.getUserNamebyId(allUsers, doc.financeBM);
      listVM.afterMarketBM = this.getUserNamebyId(allUsers, doc.afterMarketBM);
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.modifiedBy = this.getUserNamebyId(allUsers, doc.modifiedBy);
      listVM.overdueActivity = 0;
      listVM.todayActivity = 0;
      listVM.upcomingActivity = 0;
      listVM.doneActivity = 0;
      listVM.totalActivity = 0;
      listVM.depositAmount = doc.depositAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.depositAmount}` : '';
      listVM.grossAmount = doc.grossAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.grossAmount}` : '';

      listVM.csvQuoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.quotation.amount}` : '';
      listVM.quoteAmount = !_.isEmpty(doc.quotation) && doc.quotation.amount ? <NumberFormat
         thousandSeparator={true}
         prefix={`${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}`}
         displayType={'text'}
         decimalScale={2}
         value={doc.quotation.amount}
      /> : '';

      listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
      props.pipeline &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0] &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0].stages.forEach(col => {
            if (listVM.stageValue === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })
      if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
         listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) && !_.isEmpty(doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : !_.isEmpty(doc.requirement.make) ? doc.requirement.make + ' ' + doc.requirement.model : '';
         listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;
         listVM.vehicleSaletype = this.getNameByValue(_setttings && _setttings.salesType, doc.requirement.saleType, '');
         listVM.vehicleType = <>
            <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
            <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
         </>
         listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
         listVM.requirement = {
            ...doc.requirement,
            make: doc.requirement.make && clientBrands.some(a => a.value.toLowerCase().trim() === doc.requirement.make.toLowerCase().trim()) ? clientBrands.filter(a => a.value.toLowerCase().trim() === doc.requirement.make.toLowerCase().trim())[0].value : doc.requirement.make,
            saleType: (doc.requirement.saleType === 'new' || doc.requirement.saleType === 'demo') ? 'New' : (doc.requirement.saleType === 'used' || doc.requirement.saleType === 'preowned') ? 'Preowned' : doc.requirement.saleType,
            stock: doc.requirement.stock ? {
               ...doc.requirement.stock,
               saleType: (doc.requirement.stock.saleType === 'new' || doc.requirement.stock.saleType === 'demo') ? 'New' : (doc.requirement.stock.saleType === 'used' || doc.requirement.stock.saleType === 'Preowned') ? 'Preowned' : doc.requirement.stock.saleType,
               make: doc.requirement.stock.make && clientBrands.some(a => a.value.toLowerCase().trim() === doc.requirement.stock.make.toLowerCase().trim()) ? clientBrands.filter(a => a.value.toLowerCase().trim() === doc.requirement.stock.make.toLowerCase().trim())[0].value : doc.requirement.stock.make,
            } : {}
         }
      }
      if (!_.isEmpty(doc.contact)) {
         doc.contact.addedDate = doc.contact.addedDate && doc.contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.addedDate._seconds)._d) : doc.contact.addedDate;
         doc.contact.modifiedDate = doc.contact.modifiedDate && doc.contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.modifiedDate._seconds)._d) : doc.contact.modifiedDate;

         //listVM.existingCustomer = doc.contact.isRepeat === true ? true : false;
         listVM.contactID = doc.contact.documentID;
         listVM.contactName = this.displayFullContact(_setttings && _setttings.titles, doc.contact, '', true);
         listVM.firstName = (doc.contact.firstName || '');
         listVM.middleName = (doc.contact.middleName || '');
         listVM.lastName = (doc.contact.lastName || '');
         listVM.contactNumber = doc.contact.displayID;
         listVM.phone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings);
         listVM.contactPhone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings, true);
         listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
         listVM.phoneCode = (doc.contact.phoneCode ? doc.contact.phoneCode : '');
         listVM.phoneNoCode = doc.contact.phone || '';
         listVM.email = doc.contact.email;
         listVM.licenseNo = doc.contact.licenseNo;
         listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
         listVM.contactType = doc.contact.contactType;
         listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
         listVM.gender = doc.contact.gender;
         listVM.maritalStatus = doc.contact.maritalStatus;
         listVM.language = this.getNameByCode(props?.dealersettings?.languages, doc.contact.language, '');
         listVM.nationality = this.getNameByValue(props?.dealersettings?.nationalities, doc.contact.nationality, doc.contact.nationality);
         listVM.state = this.getNameByCode(props?.dealersettings?.states?.filter(a => doc?.contact?.country ? a.country_code === doc.contact.country : true), doc.contact.state, '');
         listVM.country = this.getNameByCode(props?.dealersettings?.countries, doc.contact.country, '');
         listVM.contactMethod = doc.contact.contactMethod;
         listVM.licenseType = doc.contact.licenseType;
         listVM.areaCode = doc.contact.areaCode;

         if (_setttings) {
            listVM.gender = this.getNameByValue(_setttings.genders, doc.contact.gender, '');
            listVM.maritalStatus = this.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
            listVM.contactMethod = this.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
            listVM.interests = this.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
            listVM.licenseType = this.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
            listVM.contactcampaign = this.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
            listVM.contactorigin = this.getNameByValue(_setttings.origins, doc.contact.origin, '');
         }

         // if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
         //     listVM.contactdealerName = this.getOtherDealerName(dealersettings, doc.contact.clientID);
         // }
         // else
         //     listVM.contactdealerName = '';

         listVM.address = doc.contact.address;
         listVM.companyName = doc.contact.companyName;
         listVM.designation = doc.contact.designation;
         listVM.licenseState = doc.contact.licenseState;
         listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
         listVM.secondaryPhone = doc.contact.secondaryPhone ? ((doc.contact.secondaryPhoneCode ? doc.contact.secondaryPhoneCode : '') + doc.contact.secondaryPhone) : '';
         listVM.secondaryEmail = doc.contact.secondaryEmail;
         listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
         listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
         listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
         listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
         listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
         listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
         listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
      }
      else if (!_.isEmpty(doc.engageNewContact)) {
         let _contact = Object.assign({}, doc.engageNewContact);
         listVM.contactName = this.displayFullContact([], _contact, '', true);
         listVM.firstName = (_contact.firstName || '');
         listVM.middleName = (_contact.middleName || '');
         listVM.lastName = (_contact.lastName || '');
         listVM.phone = this.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings);
         listVM.contactPhone = this.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings, true);
         listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
         listVM.email = _contact.email ? _contact.email : '';
         listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
      }

      if (!_.isEmpty(doc.dynamicFields) && _setttings && _setttings.enquiryOptionsDF) {

         _setttings && _setttings.enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               listVM[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
            }
            else if (rec.type === 'date') {
               doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
            }
            else if (rec.type === 'multiselect') {
               listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                  {
                     doc.dynamicFields[rec.value].map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>
                     })
                  }
               </div>) : (<></>)
            }
            else {
               listVM[rec.value] = doc.dynamicFields[rec.value]
            }
         })
      }

      if (!_.isEmpty(doc.td)) {
         listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
         listVM.testdriveSaletype = this.getNameByValue(_setttings && _setttings.salesType, doc.td.saleType, '');
      }

      if (!_.isEmpty(doc.lastNote)) {
         listVM.lastNotes = doc.lastNote.notes ? doc.lastNote.notes : ''
         listVM.actActionOn = doc.lastNote.modifiedDate ? moment.unix(doc.lastNote.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         listVM.actActionBy = doc.lastNote.modifiedBy ? this.getUserNamebyId(allUsers, doc.lastNote.modifiedBy) : '';
         if (doc.lastNote.type === 'activity')
            listVM.lastActivityType = _setttings.activityTypes && _.find(_setttings.activityTypes, { value: doc.lastNote.subType }) ? _.find(_setttings.activityTypes, { value: doc.lastNote.subType }).name : '';
         if (doc.lastNote.type === 'log')
            listVM.lastActivityType = `${this.autoCaps(doc.lastNote.subType)} ${doc.lastNote.subType === 'call' ? 'Log' : ''}`;
      }
      if (!_.isEmpty(doc.nextActivity)) {
         listVM.nextActivityStr = listVM.nextActivityDate ? moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         listVM.nextActivityOn = listVM.nextActivityDate ? <span className={`${moment.unix(listVM.nextActivityDate.seconds) < moment().startOf('day') ? 'activity-due' : ''}`}>{moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A')}</span> : '';
         listVM.nextActivityBy = doc.nextActivity.owner ? this.getUserNamebyId(allUsers, doc.nextActivity.owner) : '';
      }

      if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
         listVM.domleadSource = (<div className="Vehicle-availability">
            {
               doc.tags.map((rec, index) => {
                  return <div key={index}><span>{this.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>
               })
            }
         </div>);
         listVM.strleadSource = doc.tags.map((rec, index) => {
            return this.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
         }).join(',');
      }

      listVM.convertVM = true
      return listVM
   }

   convertSalesLogVM = (doc, props, settings) => {
      // if (doc.convertVM === true)
      //    return doc
      const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
      const clientBrands = props.dealersettings && props.dealersettings.client && _.isArray(props.dealersettings.client.brands) ? props.dealersettings.client.brands : [];
      doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;
      doc.convertedDate = doc.convertedDate && doc.convertedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDate._seconds)._d) : doc.convertedDate;
      doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
      doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;
      doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
      doc.verifiedDate = doc.verifiedDate && doc.verifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.verifiedDate._seconds)._d) : doc.verifiedDate;
      doc.confirmedDate = doc.confirmedDate && doc.confirmedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.confirmedDate._seconds)._d) : doc.confirmedDate;
      doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;
      doc.receivedDate = doc.receivedDate && doc.receivedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;
      doc.appointmentDate = doc.appointmentDate && doc.appointmentDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.appointmentDate._seconds)._d) : doc.appointmentDate;
      doc.convertedDateOEM = doc.convertedDateOEM && doc.convertedDateOEM._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateOEM._seconds)._d) : doc.receivedDate;
      doc.convertedDateRegion = doc.convertedDateRegion && doc.convertedDateRegion._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateRegion._seconds)._d) : doc.receivedDate;
      doc.convertedDateGroup = doc.convertedDateGroup && doc.convertedDateGroup._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.convertedDateGroup._seconds)._d) : doc.receivedDate;
      if (!_.isEmpty(doc.lastNote)) {
         doc.lastNote.modifiedDate = doc.lastNote.modifiedDate && doc.lastNote.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lastNote.modifiedDate._seconds)._d) : doc.lastNote.modifiedDate;
      }
      if (_.isEmpty(doc.stageDate)) {
         doc.stageDate = doc.addedDate;
      }
      doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
         return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
      })]

      let listVM = doc;
      listVM.objDoc = Object.assign({}, doc);
      if (doc.pipeline === 'LeadsBucket' && doc.status != 'prospectLost' && doc.status != 'lost' && _.isEmpty(doc.systemType)) {
         doc.status = '';
      }

      if (doc.stage === 'InboundLead') {
         doc.status = inboundLeadStatus[doc.status] ? inboundLeadStatus[doc.status].toLowerCase() : 'pending';
      }

      if (doc.status === 'won' || doc.status === 'delivered') {
         listVM.soldCount = doc.soldCount > 0 ? doc.soldCount : 1;
      }

      let dealersettings = props.dealersettings;

      if (doc.clientID && (doc.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
         listVM.dealerName = this.getOtherDealerName(props.dealersettings, doc.clientID);
      }
      else
         listVM.dealerName = '';


      let _setttings = doc.clientID && dealersettings && dealersettings.client && dealersettings.client.settings;

      const enquiryAllStatus = (_setttings ? _setttings.enquiryStatus : []);
      listVM.status = doc.stage === 'InboundLead' ? 'pending' : doc.status;
      listVM.statusName = doc.stage === 'InboundLead' ? 'pending' : this.getNameByValue(enquiryAllStatus, doc.status, '');
      var inboundSource = props.dealersettings.client && props.dealersettings.client.integrations ?
         props.dealersettings.client.integrations.filter(e => e.inbound === true && e.active === true && e.enabled === true).map(rec => { return rec.value }) : []
      if (!_.isEmpty(doc.systemType)) {
         listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
         doc.tags = [{ type: doc.systemType }];
      }
      else if ((doc.pipeline === 'LeadsBucket') || (doc.pipeline !== 'LeadsBucket' && Boolean(doc.isConverted) === true)) {
         listVM.inboundType = _.isArray(doc.tags) && _.filter(doc.tags, (v) => _.indexOf(inboundSource, v.type) >= 0).length > 0 ?
            doc.tags.map(r => { return r.type }) : ['LeadsBucket']
      }
      // if (doc.status === 'pendingLost') {
      //    doc.status = 'lost'
      // }

      listVM.pipelineName = this.getNameByValue(props.dealersettings.client.settings.allPipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.client.settings.allPipelines, { value: listVM.pipeline })) {
         listVM.stageName = this.getNameByValue(_.find(props.dealersettings.client.settings.allPipelines, { value: listVM.pipeline }).stages, doc.stage, '');
      }

      listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.wonOn = doc.wonDate && doc.status && (doc.status.toLowerCase() === 'won' || doc.status.toLowerCase() === 'delivered' || enquiryAllStatus.some(a => a.afterSold && a.value === doc.status)) ? moment.unix(doc.wonDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.lostOn = doc.lostDate && doc.status && (doc.status.toLowerCase() === 'lost' || doc.status.toLowerCase() === 'prospectLost') ? moment.unix(doc.lostDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.deliveredOn = doc.deliveredDate && doc.status && doc.status.toLowerCase() === 'delivered' ? moment.unix(doc.deliveredDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.deliveryOn = doc.deliveryDate ? moment.unix(doc.deliveryDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.warrantyExpiryOn = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.warrantyStartDateOn = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
      listVM.appointmentDateOn = doc.appointmentDate ? moment.unix(doc.appointmentDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.bdcStatusName = doc.bdcStatus === 'show' ? 'Show' : doc.bdcStatus === 'noshow' ? 'No Show' : doc.bdcStatus === 'allocated' ? 'Allocated' : '';
      listVM.strResponseTime = doc.responseTime > 0 ? this.timeformatfromSeconds(doc.responseTime, props.dealersettings.client) : '';
      listVM.strAllocatedTime = doc.allocatedTime > 0 ? this.timeformatfromSeconds(doc.allocatedTime, props.dealersettings.client) : '';
      listVM.strContactedTime = doc.contactedTime > 0 ? this.timeformatfromSeconds(doc.contactedTime, props.dealersettings.client) : '';
      listVM.confirmedByName = doc.confirmedBy ? this.getUserNamebyId(allUsers, doc.confirmedBy) : '';
      listVM.confirmedDateOn = doc.confirmedDate ? moment.unix(doc.confirmedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.verifiedByName = doc.verifiedBy ? this.getUserNamebyId(allUsers, doc.verifiedBy) : '';
      listVM.verifiedDateOn = doc.verifiedDate ? moment.unix(doc.verifiedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.receivedDateOn = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.purchaseIntention = doc.purchaseIntention || '';

      let _objCurrentFinanceStatus = _setttings?.financeStatus?.filter(e => e.value === doc.financeStatus)[0];
      listVM.financeStatusName = _objCurrentFinanceStatus?.name || '';
      let _objCurrentafterMarketStatus = _setttings?.afterMarketStatus?.filter(e => e.value === doc.afterMarketStatus)[0];
      listVM.afterMarketStatusName = _objCurrentafterMarketStatus?.name || '';

      listVM.convertedOnOEM = doc.convertedDateOEM ? moment.unix(doc.convertedDateOEM.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.convertedOnRegion = doc.convertedDateRegion ? moment.unix(doc.convertedDateRegion.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.convertedOnGroup = doc.convertedDateGroup ? moment.unix(doc.convertedDateGroup.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.convertedOwnerOEM = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.convertedOwnerRegion = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.convertedOwnerGroup = doc.oemConvertedBy ? this.getUserNamebyId(allUsers, doc.oemConvertedBy) : '';
      listVM.nextActivityDate = doc?.nextActivity?.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc?.nextActivity?.startDate?._seconds)._d) : doc?.nextActivity?.startDate;


      //BIND LOST REASONS
      if (doc.lostReason) {
         let lostReasons = _setttings ? _setttings.lostReasons : [];
         let _lostReason = doc.lostReason;
         listVM.lostReasonName = this.getNameByValue(lostReasons, doc.lostReason, '');
         if (doc.lostSubReason && doc.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
            let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
               lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
            listVM.lostSubReasonName = this.getNameByValue(lostSubReasons, doc.lostSubReason, '');
         }
      }

      let _objCurrentStatus = doc.status && enquiryAllStatus.filter(e => e.value === doc.status)[0];
      listVM.originName = this.getNameByValue(_setttings && _setttings.origins, doc.origin, '');
      listVM.leadSourceName = this.getNameByValue(_setttings && _setttings.leadSource, doc.leadSource, '');
      listVM.enquiryTypeName = this.getNameByValue(_setttings && _setttings.enquiryTypes, doc.enquiryType, '');
      listVM.campaignName = this.getNameByValue(_setttings && _setttings.campaigns, doc.campaign, '');
      listVM.labelName = this.bindEnquiryLabel(_setttings && _setttings.enquiryLabels, doc.label);
      if (doc.stage === 'InboundLead')
         listVM.statusName = 'Pending';
      else if (doc.status === 'pendingLost') {
         const lostStatus = _.find(enquiryAllStatus, { value: 'lost' }) || { name: 'Lost', color: '#ef4136' }
         listVM.statusName = `${lostStatus.name}`
      }
      else
         listVM.statusName = _objCurrentStatus?.name || doc.status || '';


      listVM.stageDateOn = doc.stageDate ? moment.unix(doc.stageDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.ownerName = _.find(allUsers, { id: doc.owner })?.name || '';
      listVM.ownerInboundName = _.find(allUsers, { id: doc.ownerInbound })?.name || '';
      listVM.financeBMName = _.find(allUsers, { id: doc.financeBM })?.name || '';
      listVM.afterMarketBMName = _.find(allUsers, { id: doc.afterMarketBM })?.name || '';
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.addedByName = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
      listVM.modifiedByName = this.getUserNamebyId(allUsers, doc.modifiedBy);
      // listVM.strdepositAmount = doc.depositAmount ? doc.depositAmount : null// `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : '$'} ${Math.floor(doc.depositAmount).toLocaleString()}` : '';
      // listVM.strgrossAmount = doc.grossAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : '$'} ${Math.floor(doc.grossAmount).toLocaleString()}` : '';
      // listVM.strquoteAmount = doc.quoteAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : '$'} ${Math.floor(doc.quoteAmount).toLocaleString()}` : '';
      // listVM.strfinanceAmount = doc.financeAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : '$'} ${Math.floor(doc.financeAmount).toLocaleString()}` : '';
      // listVM.strfinanceRate = doc.financeRate ? `${doc.financeRate}%` : '';
      listVM.enquiryAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
      props.pipeline &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0] &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0].stages.forEach(col => {
            if (listVM.stageValue === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })
      if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
         listVM.isStock = !_.isEmpty(doc?.requirement?.stock?.make);
         listVM.requirementID = doc.requirement.documentID;
         listVM.make = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.make : doc.requirement.make;
         listVM.model = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.model : doc.requirement.model;
         listVM.group = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.group : doc.requirement.group;
         listVM.type = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.type : doc.requirement.type;
         listVM.saleType = doc?.requirement?.saleType || '';

         listVM.vehicleModel = !_.isEmpty(doc?.requirement?.stock?.make) && !_.isEmpty(doc.requirement.stock.make) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : !_.isEmpty(doc.requirement.make) ? doc.requirement.make + ' ' + doc.requirement.model : '';
         listVM.vehicleYear = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.year : doc.requirement.year;
         listVM.vehicleSaletype = this.getNameByValue(_setttings && _setttings.salesType, doc.requirement.saleType, '');
         listVM.vehicleType = <>
            <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
            <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
         </>
         listVM.vehicleBodytype = !_.isEmpty(doc?.requirement?.stock?.make) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
         listVM.requirement = {
            ...doc.requirement,
            make: doc.requirement.make && clientBrands.some(a => a.value.toLowerCase().trim() === doc.requirement.make.toLowerCase().trim()) ? clientBrands.filter(a => a.value.toLowerCase().trim() === doc.requirement.make.toLowerCase().trim())[0].value : doc.requirement.make,
            saleType: (doc.requirement.saleType === 'new' || doc.requirement.saleType === 'demo') ? 'New' : (doc.requirement.saleType === 'used' || doc.requirement.saleType === 'preowned') ? 'Preowned' : doc.requirement.saleType,
            stock: doc?.requirement?.stock?.make ? {
               ...doc.requirement.stock,
               saleType: (doc.requirement.stock.saleType === 'new' || doc.requirement.stock.saleType === 'demo') ? 'New' : (doc.requirement.stock.saleType === 'used' || doc.requirement.stock.saleType === 'Preowned') ? 'Preowned' : doc.requirement.stock.saleType,
               make: doc.requirement.stock.make && clientBrands.some(a => a.value.toLowerCase().trim() === doc.requirement.stock.make.toLowerCase().trim()) ? clientBrands.filter(a => a.value.toLowerCase().trim() === doc.requirement.stock.make.toLowerCase().trim())[0].value : doc.requirement.stock.make,
            } : {}
         }
      }
      if (!_.isEmpty(doc.contact)) {
         doc.contact.addedDate = doc.contact.addedDate && doc.contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.addedDate._seconds)._d) : doc.contact.addedDate;
         doc.contact.modifiedDate = doc.contact.modifiedDate && doc.contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.modifiedDate._seconds)._d) : doc.contact.modifiedDate;

         //listVM.existingCustomer = doc.contact.isRepeat === true ? true : false;
         listVM.contactID = doc.contact.documentID;
         listVM.contactName = this.displayFullContact(_setttings && _setttings.titles, { ...doc.contact, preferredName: null }, '', true);
         listVM.preferredName = (doc.contact.preferredName || '');
         listVM.title = (doc.contact.title || '');
         listVM.titleName = this.getNameByValue(_setttings && _setttings.titles, doc.contact.title, '');
         listVM.firstName = (doc.contact.firstName || '');
         listVM.middleName = (doc.contact.middleName || '');
         listVM.lastName = (doc.contact.lastName || '');
         listVM.contactNumber = doc.contact.displayID;
         listVM.phone = this.getFullPhone(doc.contact.phoneCode, doc.contact.phone);
         listVM.contactPhone = doc.contact.phone ? (doc.contact.phoneCode ? doc.contact.phoneCode : '') + doc.contact.phone : '';
         listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
         listVM.phoneCode = (doc.contact.phoneCode ? doc.contact.phoneCode : '');
         listVM.phoneNoCode = doc.contact.phone || '';
         listVM.email = doc.contact.email;
         listVM.licenseNo = doc.contact.licenseNo;
         listVM.licenseExpiryOn = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
         listVM.contactType = doc.contact.contactType;
         listVM.dobOn = doc.contact.dob ? moment(doc.contact.dob).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
         listVM.gender = doc.contact.gender;
         listVM.maritalStatus = doc.contact.maritalStatus;
         listVM.languageName = this.getNameByCode(props?.dealersettings?.languages, doc.contact.language, '');
         listVM.nationalityName = this.getNameByValue(props?.dealersettings?.nationalities, doc.contact.nationality, doc.contact.nationality);
         listVM.stateName = this.getNameByCode(props?.dealersettings?.states?.filter(a => doc?.contact?.country ? a.country_code === doc.contact.country : true), doc.contact.state, '');
         listVM.countryName = this.getNameByCode(props?.dealersettings?.countries, doc.contact.country, '');
         listVM.contactMethod = doc.contact.contactMethod;
         listVM.licenseTypeName = this.getNameByValue(_setttings && _setttings.licenseType, doc.contact.licenseType, '');
         listVM.areaCode = doc.contact.areaCode;
         listVM.language = doc.contact.language;
         listVM.nationality = doc.contact.nationality;
         listVM.state = doc.contact.state;
         listVM.country = doc?.contact?.country || dealersettings?.client?.countryCode || null;
         if (_setttings) {
            listVM.genderName = this.getNameByValue(_setttings.genders, doc.contact.gender, '');
            listVM.maritalStatusName = this.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
            listVM.contactMethod = this.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
            listVM.interests = this.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
            listVM.licenseType = this.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
            listVM.contactcampaign = this.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
            listVM.contactorigin = this.getNameByValue(_setttings.origins, doc.contact.origin, '');
         }

         // if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
         //     listVM.contactdealerName = this.getOtherDealerName(dealersettings, doc.contact.clientID);
         // }
         // else
         //     listVM.contactdealerName = '';

         listVM.address = doc.contact.address;
         listVM.companyID = doc?.contact?.company?.documentID || '';
         listVM.companyName = doc?.contact?.company?.name || '';
         listVM.businessContact = doc?.contact?.businessContact || false;
         listVM.designation = doc.contact.designation;
         listVM.licenseStateName = this.getNameByCode(props?.dealersettings?.states?.filter(a => doc?.contact?.country ? a.country_code === doc.contact.country : true), doc.contact.licenseState, '');
         listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
         listVM.secondaryPhone = doc.contact.secondaryPhone ? ((doc.contact.secondaryPhoneCode ? doc.contact.secondaryPhoneCode : '') + doc.contact.secondaryPhone) : '';
         listVM.secondaryEmail = doc.contact.secondaryEmail;
         listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
         listVM.optinPhone = doc.contact.optinPhone;
         listVM.optinPhoneName = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Opt In' : 'Opt Out');
         listVM.optinEmail = doc.contact.optinEmail;
         listVM.optinEmailName = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Opt In' : 'Opt Out');
         listVM.optinPost = doc.contact.optinPost;
         listVM.optinPostName = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Opt In' : 'Opt Out');
         listVM.optinSMS = doc.contact.optinSMS;
         listVM.optinSMSName = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Opt In' : 'Opt Out');
         listVM.marketingOptinEmail = doc.contact.marketingOptinEmail;
         listVM.marketingOptinEmailName = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Opt In' : 'Opt Out');
         listVM.marketingOptinSMS = doc.contact.marketingOptinSMS;
         listVM.marketingOptinSMSName = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Opt In' : 'Opt Out');
      }
      else if (!_.isEmpty(doc.engageNewContact)) {
         let _contact = Object.assign({}, doc.engageNewContact);
         listVM.contactName = this.displayFullContact([], _contact, '', true);
         listVM.firstName = (_contact.firstName || '');
         listVM.middleName = (_contact.middleName || '');
         listVM.lastName = (_contact.lastName || '');
         listVM.phone = this.getFullPhone(_contact.phoneCode, _contact.phone);
         listVM.contactPhone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
         listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
         listVM.email = _contact.email ? _contact.email : '';
         listVM.csvPhone = listVM.contactPhone ? `${listVM.contactPhone}` : ''
      }

      if (!_.isEmpty(doc.dynamicFields) && _setttings && _setttings.enquiryOptionsDF) {

         _setttings && _setttings.enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               listVM[`${rec.value}`] = doc.dynamicFields[rec.value] || null;
               listVM[`str${rec.value}`] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               listVM[`${rec.value}`] = doc.dynamicFields[rec.value] || null
               //listVM[`str${rec.value}`] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : '$'} ${Math.floor(doc.dynamicFields[rec.value]).toLocaleString()}` : ''
            }
            else if (rec.type === 'date') {
               listVM[`${rec.value}`] = doc?.dynamicFields?.[rec.value]?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : null
               listVM[`str${rec.value}`] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'}`) : '';
            }
            else if (rec.type === 'multiselect') {
               listVM[`${rec.value}`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value] : null
               listVM[`str${rec.value}`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
            }
            else {
               listVM[`${rec.value}`] = doc.dynamicFields[rec.value]
               listVM[`str${rec.value}`] = doc.dynamicFields[rec.value]
            }
         })
      }

      if (!_.isEmpty(doc.td)) {
         listVM.testdriveModel = !_.isEmpty(doc.td) && !_.isEmpty(doc.td.make) ? doc.td.make + ' ' + doc.td.model : ''
         listVM.testdriveSaletype = this.getNameByValue(_setttings && _setttings.salesType, doc.td.saleType, '');
      }

      if (!_.isEmpty(doc.lastNote)) {
         listVM.lastNotes = doc.lastNote.notes ? doc.lastNote.notes : ''
         listVM.actActionOn = doc.lastNote.modifiedDate ? moment.unix(doc.lastNote.modifiedDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
         listVM.actActionBy = doc.lastNote.modifiedBy ? this.getUserNamebyId(allUsers, doc.lastNote.modifiedBy) : '';
         if (doc.lastNote.type === 'activity')
            listVM.lastActivityType = _setttings.activityTypes && _.find(_setttings.activityTypes, { value: doc.lastNote.subType }) ? _.find(_setttings.activityTypes, { value: doc.lastNote.subType }).name : '';
         if (doc.lastNote.type === 'log')
            listVM.lastActivityType = `${this.autoCaps(doc.lastNote.subType)} ${doc.lastNote.subType === 'call' ? 'Log' : ''}`;
      }
      if (!_.isEmpty(doc.nextActivity)) {
         listVM.nextActivityStr = listVM.nextActivityDate ? moment.unix(listVM.nextActivityDate.seconds).format(`${settings?.dateFormat || 'DD/MM/YYYY'} ${settings?.timeFormat || 'hh:mm A'}`) : '';
         listVM.nextActivityOn = listVM.nextActivityDate ? <span className={`${moment.unix(listVM.nextActivityDate.seconds) < moment().startOf('day') ? 'activity-due' : ''}`}>{moment.unix(listVM.nextActivityDate.seconds).format('DD/MM/YYYY hh:mm A')}</span> : '';
         listVM.nextActivityBy = doc.nextActivity.owner ? this.getUserNamebyId(allUsers, doc.nextActivity.owner) : '';
      }

      if (!_.isEmpty(doc.tags) && _.isArray(doc.tags)) {
         listVM.domleadSource = (<div className="Vehicle-availability">
            {
               doc.tags.map((rec, index) => {
                  return <div key={index}><span>{this.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)}</span></div>
               })
            }
         </div>);
         listVM.strleadSource = doc.tags.map((rec, index) => {
            return this.getNameByValue(props.dealersettings.integrations, rec.type, rec.type)
         }).join(',');
      }

      listVM.convertVM = true
      return listVM
   }

   convertServiceJobsVM = (doc, props) => {

      const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
      const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

      const clientBrands = props.dealersettings && props.dealersettings.client && _.isArray(props.dealersettings.client.brands) ? props.dealersettings.client.brands : [];

      doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;

      doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.checkInDate = doc.checkInDate && doc.checkInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.checkInDate._seconds)._d) : doc.checkInDate;
      doc.completionDate = doc.completionDate && doc.completionDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.completionDate._seconds)._d) : doc.completionDate;
      doc.clientContactDate = doc.clientContactDate && doc.clientContactDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.clientContactDate._seconds)._d) : doc.clientContactDate;

      doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
         return obj.date && obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
      })]


      // const objenquiryData = Object.assign({}, doc);
      // const listVM = Object.assign({}, objServiceListVM);
      // for (let [key, value] of Object.entries(objenquiryData)) {
      //     if (listVM.hasOwnProperty(key))
      //         listVM[key] = value;
      // }
      let listVM = doc;
      listVM.objDoc = Object.assign({}, doc);
      listVM.displayID = listVM.displayID ? listVM.displayID : '--';
      listVM.originValue = doc.origin;
      listVM.leadSourceValue = doc.leadSource;
      listVM.enquiryTypeValue = doc.enquiryType;

      listVM.serviceTypeValue = doc.serviceType;
      listVM.appointmentTypeValue = doc.appointmentType;
      listVM.pipelineValue = doc.pipeline;
      listVM.stageValue = doc.stage;
      listVM.ownerValue = doc.owner;
      listVM.slotValue = doc.slot ? doc.slot.value : '';
      listVM.slotName = doc.slot ? doc.slot.name : '';
      listVM.technicianStatusValue = doc.technicianStatus;

      listVM.jobAllocated = doc.jobAllocated ? 'Yes' : '';
      listVM.jobConfirmed = doc.jobConfirmed ? 'Yes' : '';
      listVM.partsConfirmed = doc.partsConfirmed ? 'Yes' : '';
      listVM.workshopConfirmed = doc.workshopConfirmed ? 'Yes' : '';


      let dealersettings = props.dealersettings;
      listVM.pipeline = this.getNameByValue(props.dealersettings.client.services.pipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.client.services.pipelines, { value: listVM.pipelineValue })) {
         listVM.stage = this.getNameByValue(_.find(props.dealersettings.client.services.pipelines, { value: listVM.pipelineValue }).stages, doc.stage, '');
      }

      let _serviceSetttings = doc.clientID && dealersettings && dealersettings.group &&
         dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].services;
      if (_.isEmpty(_serviceSetttings))
         _serviceSetttings = dealersettings && dealersettings.client && dealersettings.client.services;

      if (_serviceSetttings) {
         //listVM.serviceType = this.getNameByValue(_serviceSetttings.serviceTypes, doc.serviceType, '');
         listVM.appointmentType = this.getNameByValue(_serviceSetttings.appointmentTypes, doc.appointmentType, '');
      }

      listVM.appointmentDate = doc.appointmentDate ? moment(doc.appointmentDate).format('DD/MM/YYYY hh:mm A') : '';
      listVM.checkInDate = doc.checkInDate ? moment.unix(doc.checkInDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.clientContactDate = doc.clientContactDate ? moment.unix(doc.clientContactDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.owner = doc.owner ? this.getUserNamebyId(allUsers, doc.owner) : '';
      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.modifiedBy = doc.modifiedBy ? this.getUserNamebyId(allUsers, doc.modifiedBy) : '';
      listVM.checkInBy = doc.checkInBy ? this.getUserNamebyId(allUsers, doc.checkInBy) : '';
      listVM.serviceAdvisor = doc.serviceAdvisor ? this.getUserNamebyId(allUsers, doc.serviceAdvisor) : '';
      listVM.technician = doc.technician ? this.getUserNamebyId(allUsers, doc.technician) : '';
      listVM.partsInterpretor = doc.partsInterpretor ? this.getUserNamebyId(allUsers, doc.partsInterpretor) : '';
      listVM.workshopManager = doc.workshopManager ? this.getUserNamebyId(allUsers, doc.workshopManager) : '';

      const _jobStatus = doc.status === serviceStatus.OWNERRESPONDED ? doc.ownerResponse : doc.status;
      const serviceAllStatus = Object.assign([], _serviceSetttings?.serviceStatus)
      const _servicePipeline = (!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.services) &&
         !_.isEmpty(props.dealersettings.client.services.pipelines)) ? props.dealersettings.client.services.pipelines : [];

      let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0];
      listVM.status = _objCurrentStatus ? (<span key={doc.status}
         className={`status-empty`}
         style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      >
         {_objCurrentStatus.name}
      </span>) : '';

      listVM.statusValue = _jobStatus;
      listVM.statusName = this.getNameByValue(serviceAllStatus, _jobStatus, '');

      const technicianAllStatus = Object.assign([], _serviceSetttings?.technicianStatus)
      let _objCurrentTechStatus = doc.technicianStatus && technicianAllStatus.filter(e => e.value === doc.technicianStatus)[0];
      listVM.technicianStatus = _objCurrentTechStatus ? (<span key={doc.technicianStatus}
         className={`status-empty`}
         style={{ background: (_objCurrentTechStatus.color ? _objCurrentTechStatus.color : '#333') }}
      >
         {_objCurrentTechStatus.name}
      </span>) : '';
      listVM.technicianStatusName = this.getNameByValue(technicianAllStatus, listVM.technicianStatusValue, '');

      listVM.slot = !_.isEmpty(doc.slot) ? <div className="badge-slot"
         style={{
            color: `${doc.slot.color ? doc.slot.color : '#f47815 '}`,
            backgroundColor: `${doc.slot.color ? doc.slot.color + '1f' : '#f478151f'}`,
            boxShadow: `inset 0 0 0 1px ${doc.slot.color ? doc.slot.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
         }}>
         <i className="ico icon-service-timer"></i> {doc.slot.name}
      </div> : '';


      listVM.totalServiceCost = doc.totalServiceCost ? currencySymbol + doc.totalServiceCost : '';
      listVM.serviceAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
      _servicePipeline &&
         _servicePipeline.filter(e => e.value === listVM.pipelineValue)[0] &&
         _servicePipeline.filter(e => e.value === listVM.pipelineValue)[0].stages.forEach(col => {
            if (listVM.stageValue === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })
      if (!_.isEmpty(doc.vehicle)) {
         listVM.vehicleModel = doc.vehicle.make + ' ' + doc.vehicle.model
         listVM.vehicleYear = doc.vehicle.year ? doc.vehicle.year : '';
         listVM.vehicleVinNo = doc.vehicle.vinNo ? doc.vehicle.vinNo : '';
         listVM.vehicleRegNo = doc.vehicle.rego ? doc.vehicle.rego : (doc.vehicle.regNo ? doc.vehicle.regNo : '');
      }
      if (!_.isEmpty(doc.contact)) {
         doc.contact.addedDate = doc.contact.addedDate && doc.contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.addedDate._seconds)._d) : doc.contact.addedDate;
         doc.contact.modifiedDate = doc.contact.modifiedDate && doc.contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.contact.modifiedDate._seconds)._d) : doc.contact.modifiedDate;

         let _setttings = doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings;
         if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

         //listVM.existingCustomer = doc.contact.isRepeat === true ? true : false;
         listVM.contactID = doc.contact.documentID;
         listVM.contactName = this.displayFullContact(_setttings && _setttings.titles, doc.contact, '', true);
         listVM.firstName = (doc.contact.firstName || '');
         listVM.middleName = (doc.contact.middleName || '');
         listVM.lastName = (doc.contact.lastName || '');
         listVM.contactNumber = doc.contact.displayID;
         listVM.phone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings);
         listVM.displayPhone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings, true);
         listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
         listVM.email = doc.contact.email;
         listVM.licenseNo = doc.contact.licenseNo;
         listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
         listVM.contactType = doc.contact.contactType;
         listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
         listVM.gender = doc.contact.gender;
         listVM.maritalStatus = doc.contact.maritalStatus;
         listVM.contactMethod = doc.contact.contactMethod;
         listVM.licenseType = doc.contact.licenseType;
         listVM.language = this.getLabelByValue(props.dealersettings.languages, doc.contact.language, '');
         listVM.nationality = this.getLabelByValue(props.dealersettings.nationalities, doc.contact.nationality, doc.contact.nationality);
         listVM.campaign = this.getLabelByValue(props.dealersettings.client.settings.campaigns, doc.contact.campaign, '');
         listVM.origin = this.getLabelByValue(props.dealersettings.client.settings.origins, doc.contact.origin, '');



         if (_setttings) {
            listVM.gender = this.getNameByValue(_setttings.genders, doc.contact.gender, '');
            listVM.maritalStatus = this.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
            listVM.contactMethod = this.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
            listVM.interests = this.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
            listVM.licenseType = this.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
         }

         if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
            listVM.dealerName = this.getOtherDealerName(dealersettings, doc.contact.clientID);
         }
         else
            listVM.dealerName = '';

         listVM.address = doc.contact.address;
         listVM.companyName = doc.contact.companyName;
         listVM.designation = doc.contact.designation;

         listVM.licenseState = doc.contact.licenseState;
         listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
         listVM.secondaryPhone = this.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, dealersettings);
         listVM.secondaryEmail = doc.contact.secondaryEmail;
         listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
         listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
         listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
         listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
         listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
         listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
         listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
      }
      else if (!_.isEmpty(doc.engageNewContact)) {
         let _contact = Object.assign({}, doc.engageNewContact);
         listVM.contactName = this.displayFullContact([], _contact, '', true);
         listVM.firstName = (_contact.firstName || '');
         listVM.middleName = (_contact.middleName || '');
         listVM.lastName = (_contact.lastName || '');
         listVM.phone = this.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings);
         listVM.displayPhone = this.phoneFormat(_contact.phoneCode, _contact.phone, dealersettings, true);
         listVM.phoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
         listVM.email = _contact.email ? _contact.email : '';
         listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
      }


      return listVM
   }

   convertTradeinProVM = (doc, props) => {

      doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;

      doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

      doc.completedDate = doc.completedDate && doc.completedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.completedDate._seconds)._d) : doc.completedDate;
      doc.wonDate = doc.wonDate && doc.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.wonDate._seconds)._d) : doc.wonDate;
      doc.lostDate = doc.lostDate && doc.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.lostDate._seconds)._d) : doc.lostDate;


      doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
         return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
      })]
      let dealersettings = props.dealersettings;
      const _tradeinProPipeline = (!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.tradeinPro) &&
         !_.isEmpty(props.dealersettings.client.tradeinPro.pipelines)) ? props.dealersettings.client.tradeinPro.pipelines : [];
      let allUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers);
      const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');



      const objenquiryData = Object.assign({}, doc);
      const listVM = Object.assign({});
      for (let [key, value] of Object.entries(objenquiryData)) {
         //if (listVM.hasOwnProperty(key))
         listVM[key] = value;
      }
      listVM.objDoc = Object.assign({}, objenquiryData);
      listVM.displayID = listVM.displayID ? listVM.displayID : '--';
      listVM.originValue = doc.origin;
      listVM.enquiryTypeValue = doc.enquiryType;



      listVM.pipelineValue = doc.pipeline;
      listVM.stageValue = doc.stage;
      listVM.ownerValue = doc.owner;




      listVM.pipeline = this.getNameByValue(props.dealersettings.client.tradeinPro.pipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.client.tradeinPro.pipelines, { value: listVM.pipelineValue })) {
         listVM.stage = this.getNameByValue(_.find(props.dealersettings.client.tradeinPro.pipelines, { value: listVM.pipelineValue }).stages, doc.stage, '');
      }

      let _tip_setttings = doc.clientID && dealersettings && dealersettings.group &&
         dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].tradeinPro;
      if (_.isEmpty(_tip_setttings))
         _tip_setttings = dealersettings && dealersettings.client && dealersettings.client.tradeinPro;


      listVM.wonDate = doc.wonDate ? moment.unix(doc.wonDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.lostDate = doc.lostDate ? moment.unix(doc.lostDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.completedDate = doc.completedDate ? moment.unix(doc.completedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';


      //BIND LOST REASONS
      if (doc.lostReason) {
         let lostReasons = _tip_setttings ? _tip_setttings.lostReasons : [];
         let _lostReason = doc.lostReason;
         listVM.lostReasonValue = doc.lostReason;
         listVM.lostSubReasonValue = doc.lostSubReason;
         listVM.lostReason = this.getNameByValue(lostReasons, doc.lostReason, '');
         if (doc.lostSubReason && doc.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
            let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
               lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
            listVM.lostSubReason = this.getNameByValue(lostSubReasons, doc.lostSubReason, '');
         }
      }

      listVM.origin = this.getNameByValue(_tip_setttings && _tip_setttings.origins, doc.origin, '');
      listVM.owner = doc.owner ? this.getUserNamebyId(allUsers, doc.owner) : '';
      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.modifiedBy = doc.modifiedBy ? this.getUserNamebyId(allUsers, doc.modifiedBy) : '';

      const tradeinProAllStatus = Object.assign([], _tip_setttings?.tradeinProStatus)
      let _objCurrentStatus = doc.status && tradeinProAllStatus.filter(e => e.value === doc.status)[0];
      const _jobStatus = doc.status;

      listVM.statusValue = _jobStatus;
      listVM.statusName = this.getNameByValue(tradeinProAllStatus, doc.status, '');
      listVM.status = _objCurrentStatus ? (<span key={doc.status}
         className={`status-${doc.status === tipStatus.NEW ? 'open' : 'empty'}`}
         style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      >
         {_objCurrentStatus.name}
      </span>) : '';

      const _permissions = ((!_.isEmpty(dealersettings) &&
         !_.isEmpty(dealersettings.rolePermissions) &&
         !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);
      const lockFinalPrice = ((!_.isEmpty(_permissions) && _permissions.lockFinalPrice) ? true : false);
      const lockValuerPrice = ((!_.isEmpty(_permissions) && _permissions.lockValuerPrice) ? true : false);
      let _client = _.find(dealersettings?.clients, { id: doc.clientID })
      let managerWorkflow = _client?.clientSettings?.managerWorkflow ? true : false;

      if (managerWorkflow) {
         listVM.offerPrice = lockFinalPrice ? `` : (doc.finalPrice ? this.formatCurrency(currencySymbol, doc.finalPrice) : (doc.offer?.dealerPrice ? this.formatCurrency(currencySymbol, doc.offer.dealerPrice) : ''));
         listVM.valuerPrice = lockValuerPrice ? `` : (doc.valuerPrice ? this.formatCurrency(currencySymbol, doc.valuerPrice) : (doc.offer?.dealerPrice ? this.formatCurrency(currencySymbol, doc.offer.dealerPrice) : ''));
      }
      else {
         listVM.offerPrice = doc.offer && doc.offer.dealerPrice ? (lockFinalPrice ? `` : this.formatCurrency(currencySymbol, doc.offer.dealerPrice)) : '';
         listVM.valuerPrice = doc.offer && doc.offer.dealerPrice ? (lockValuerPrice ? `` : this.formatCurrency(currencySymbol, doc.offer.dealerPrice)) : '';
      }


      listVM.tradeinAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
      _tradeinProPipeline.filter(e => e.default)[0] &&
         _tradeinProPipeline.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })

      if (!_.isEmpty(doc.tradein)) {
         listVM.vehicleModel = doc.tradein.make ? doc.tradein.make + ' ' + (doc.tradein.model ? doc.tradein.model : '') : ''
         listVM.vehicleYear = doc.tradein.year ? doc.tradein.year : '';
         listVM.vehicleVinNo = doc.tradein.chassisNo ? doc.tradein.chassisNo : '';
         listVM.vehicleRegNo = doc.tradein.rego ? doc.tradein.rego : (doc.tradein.regNo ? doc.tradein.regNo : '');

      }

      let clientIDs = this.getTradeinProDealers(dealersettings, true);
      if (doc.clientID && clientIDs && clientIDs.filter(e => ![dealersettings.client.id].includes(e)).length > 0) {
         listVM.dealerName = this.getOtherDealerName(dealersettings, doc.clientID);
      }
      else
         listVM.dealerName = '';
      if (!_.isEmpty(doc.contact)) {

         let _setttings = doc.contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.contact.clientID] && dealersettings.group.clients[doc.contact.clientID].settings;
         if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

         listVM.contactName = this.displayFullContact(_setttings?.titles, doc.contact, '', true);
         listVM.firstName = (doc.contact.firstName || '');
         listVM.middleName = (doc.contact.middleName || '');
         listVM.lastName = (doc.contact.lastName || '');
         listVM.contactNumber = doc.contact.displayID;
         listVM.phone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings);
         listVM.displayPhone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings, true);
         listVM.csvPhone = listVM.displayPhone ? `${listVM.displayPhone}` : ''
         listVM.email = doc.contact.email;
         listVM.licenseNo = doc.contact.licenseNo;
         listVM.licenseExpiry = doc.contact.licenseExpiry ? moment(doc.contact.licenseExpiry).format('DD/MM/YYYY') : '';
         listVM.contactType = doc.contact.contactType;
         listVM.dob = doc.contact.dob ? moment(doc.contact.dob).format('DD/MM/YYYY') : '';
         listVM.gender = doc.contact.gender;
         listVM.maritalStatus = doc.contact.maritalStatus;
         listVM.contactMethod = doc.contact.contactMethod;
         listVM.licenseType = doc.contact.licenseType;
         listVM.language = this.getLabelByValue(props.dealersettings.languages, doc.contact.language, '');
         listVM.nationality = this.getLabelByValue(props.dealersettings.nationalities, doc.contact.nationality, doc.contact.nationality);

         if (_setttings) {
            listVM.gender = this.getNameByValue(_setttings.genders, doc.contact.gender, '');
            listVM.maritalStatus = this.getNameByValue(_setttings.maritalStatus, doc.contact.maritalStatus, '');
            listVM.contactMethod = this.getNameByValue(_setttings.contactMethod, doc.contact.contactMethod, '');
            listVM.interests = this.getLabelByMultipleValues(_setttings.interests, doc.contact.interests, '', true);
            listVM.licenseType = this.getNameByValue(_setttings.licenseType, doc.contact.licenseType, '');
            listVM.contactcampaign = this.getNameByValue(_setttings.campaigns, doc.contact.campaign, '');
            listVM.contactorigin = this.getNameByValue(_setttings.origins, doc.contact.origin, '');
         }

         listVM.address = doc.contact.address;
         listVM.areaCode = doc.contact.areaCode;
         listVM.companyName = doc.contact.companyName;
         listVM.designation = doc.contact.designation;

         listVM.licenseState = doc.contact.licenseState;
         listVM.secondaryName = doc.contact.secondaryFirstName ? (doc.contact.secondaryFirstName + ' ' + doc.contact.secondaryLastName) : '';
         listVM.secondaryPhone = this.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, dealersettings);
         listVM.secondaryEmail = doc.contact.secondaryEmail;
         listVM.isDLScan = doc.contact.isDLScan ? 'Yes' : 'No';
         listVM.optinPhone = doc.contact.optinPhone === null ? '' : (doc.contact.optinPhone ? 'Yes' : 'No');
         listVM.optinEmail = doc.contact.optinEmail === null ? '' : (doc.contact.optinEmail ? 'Yes' : 'No');
         listVM.optinPost = doc.contact.optinPost === null ? '' : (doc.contact.optinPost ? 'Yes' : 'No');
         listVM.optinSMS = doc.contact.optinSMS === null ? '' : (doc.contact.optinSMS ? 'Yes' : 'No');
         listVM.marketingOptinEmail = doc.contact.marketingOptinEmail === null ? '' : (doc.contact.marketingOptinEmail ? 'Yes' : 'No');
         listVM.marketingOptinSMS = doc.contact.marketingOptinSMS === null ? '' : (doc.contact.marketingOptinSMS ? 'Yes' : 'No');
      }

      if (!_.isEmpty(doc.dynamicFields) && _tip_setttings && _tip_setttings.enquiryOptionsDF) {

         _tip_setttings && _tip_setttings.enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               listVM[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
            }
            else if (rec.type === 'date') {
               doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
            }
            else if (rec.type === 'multiselect') {
               listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                  {
                     doc.dynamicFields[rec.value].map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>
                     })
                  }
               </div>) : (<></>)
            }
            else {
               listVM[rec.value] = doc.dynamicFields[rec.value]
            }
         })
      }

      return listVM
   }

   convertEventsProVM = (doc, props) => {

      doc.stageDate = doc.stageDate && doc.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stageDate._seconds)._d) : doc.stageDate;

      doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

      doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
      doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;

      doc.closedDate = doc.closedDate && doc.closedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.closedDate._seconds)._d) : doc.closedDate;
      doc.publishedDate = doc.publishedDate && doc.publishedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.publishedDate._seconds)._d) : doc.publishedDate;
      doc.liveDate = doc.liveDate && doc.liveDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.liveDate._seconds)._d) : doc.liveDate;


      doc.stageHistory = [..._.map(doc.stageHistory, function (obj) {
         return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
      })]
      let dealersettings = props.dealersettings;
      const _Pipelines = (!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.eventsPro) &&
         !_.isEmpty(props.dealersettings.client.eventsPro.pipelines)) ? props.dealersettings.client.eventsPro.pipelines : [];
      let allUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers);
      const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

      const objenquiryData = Object.assign({}, doc);
      const listVM = Object.assign({});
      for (let [key, value] of Object.entries(objenquiryData)) {
         //if (listVM.hasOwnProperty(key))
         listVM[key] = value;
      }
      listVM.objDoc = Object.assign({}, objenquiryData);
      listVM.displayID = listVM.displayID ? listVM.displayID : '--';
      listVM.pipelineValue = doc.pipeline;
      listVM.stageValue = doc.stage;
      listVM.ownerValue = doc.owner;


      listVM.pipeline = this.getNameByValue(props.dealersettings.client.eventsPro.pipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.client.eventsPro.pipelines, { value: listVM.pipelineValue })) {
         listVM.stage = this.getNameByValue(_.find(props.dealersettings.client.eventsPro.pipelines, { value: listVM.pipelineValue }).stages, doc.stage, '');
      }

      let _eventsPro_setttings = doc.clientID && dealersettings && dealersettings.group &&
         dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].eventsPro;
      if (_.isEmpty(_eventsPro_setttings))
         _eventsPro_setttings = dealersettings && dealersettings.client && dealersettings.client.eventsPro;


      listVM.publishedDate = doc.publishedDate ? moment.unix(doc.publishedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.liveDate = doc.liveDate ? moment.unix(doc.liveDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.stageDate = doc.stageDate ? moment.unix(doc.stageDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.closedDate = doc.closedDate ? moment.unix(doc.closedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.owner = doc.owner ? this.getUserNamebyId(allUsers, doc.owner) : '';
      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.modifiedBy = doc.modifiedBy ? this.getUserNamebyId(allUsers, doc.modifiedBy) : '';
      listVM.eventDate = this.showCombineDate(doc.startDate, doc.endDate, doc.timezone)
      listVM.eventTime = moment.unix(doc.startDate.seconds).tz(doc.timezone || moment.tz.guess()).format('hh:mm A') + (doc.endDate ? ' - ' + moment.unix(doc.endDate.seconds).tz(doc.timezone || moment.tz.guess()).format('hh:mm A') : '');

      const eventsProAllStatus = Object.assign([], _eventsPro_setttings?.eventsProStatus)
      let _objCurrentStatus = doc.status && eventsProAllStatus.filter(e => e.value === doc.status)[0];
      const _jobStatus = doc.status;

      listVM.statusValue = _jobStatus;
      listVM.statusName = this.getNameByValue(eventsProAllStatus, doc.status, '');
      listVM.status = _objCurrentStatus ? (<span key={doc.status}
         className={`status-${doc.status === tipStatus.NEW ? 'open' : 'empty'}`}
         style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      >
         {_objCurrentStatus.name}
      </span>) : '';


      listVM.eventAge = (doc.addedDate ? (moment.unix(doc.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(doc.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '');
      _Pipelines.filter(e => e.default)[0] &&
         _Pipelines.filter(e => e.default)[0].stages.forEach(col => {
            if (listVM.stage === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })

      listVM.dealerName = doc.clientName ? doc.clientName : doc.clientID ? this.getOtherDealerName(dealersettings, doc.clientID) : doc.level ? this.autoCaps(doc.level) : '';
      if (!_.isEmpty(doc.clientIDs)) {
         // let _clientNames = [];
         // doc.clientIDs.forEach((clientID) => {
         //    _clientNames.push(this.getOtherDealerName(dealersettings, clientID))
         // })
         let _clientNames = this.getDataByLevel(dealersettings, userLevel.INDIVIDUAL, doc.clientIDs, 'name')
         listVM.dealersName = _clientNames ? _clientNames.join(',') : '';
         listVM.dealersNameTag = <div className="Vehicle-availability">
            {
               _clientNames.map((rec, index) => {
                  return <div key={index} title={'client'}><span>{rec}</span></div>
               })
            }
         </div>
      }
      else if (doc.level && doc.levelID) {
         let _clientNames = this.getDataByLevel(dealersettings, doc.level, doc.levelID, 'name')
         listVM.dealersName = _clientNames ? _clientNames.join(',') : '';
         listVM.dealersNameTag = !_.isEmpty(_clientNames) ? <div className="Vehicle-availability">
            {
               _clientNames.map((rec, index) => {
                  return <div key={index} title={doc.level}><span>{rec}</span></div>
               })
            }
         </div> : <></>
      }

      listVM.visibilityDOM = doc.visibility === 'private' ?
         (<div className="badge badge-pill badge-private"  >Private </div>)
         : (<div className="badge badge-pill badge-shared" >Shared  </div>)



      if (!_.isEmpty(doc.dynamicFields) && _eventsPro_setttings && _eventsPro_setttings.enquiryOptionsDF) {

         _eventsPro_setttings && _eventsPro_setttings.enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               listVM[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
            }
            else if (rec.type === 'date') {
               doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
            }
            else if (rec.type === 'multiselect' && doc.dynamicFields[rec.value]) {
               listVM[`${rec.value}Value`] = _.isArray(doc.dynamicFields[rec.value]) ? doc.dynamicFields[rec.value].join(',') : doc.dynamicFields[rec.value]
               listVM[rec.value] = _.isArray(doc.dynamicFields[rec.value]) ? (<div className="Vehicle-availability">
                  {
                     doc.dynamicFields[rec.value].map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>
                     })
                  }
               </div>) : (<div className="Vehicle-availability">{doc.dynamicFields[rec.value]}</div>)
            }
            else {
               listVM[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value] : ''
            }
         })
      }

      return listVM
   }

   convertFleetProVM = (doc, props, startDate, endDate) => {

      doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.defleetDate = doc.defleetDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.defleetDate._seconds)._d) : doc.defleetDate;

      let dealersettings = props.dealersettings;
      let allUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers);
      const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

      const objenquiryData = Object.assign({}, doc);
      const listVM = Object.assign({});
      for (let [key, value] of Object.entries(objenquiryData)) {
         listVM[key] = value;
      }
      listVM.objDoc = Object.assign({}, objenquiryData);
      listVM.displayID = listVM.displayID ? listVM.displayID : '--';

      listVM.ownerValue = doc.fleetOwner;

      let _fleetPro_setttings = dealersettings?.client?.fleetPro;

      listVM.categoryValue = doc.category;
      listVM.category = this.getNameByValue(_fleetPro_setttings.category, doc.category, '');
      listVM.country = this.getNameByCode(props.dealersettings.countries, doc.country, '');
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.defleetOn = doc.defleetDate ? moment.unix(doc.defleetDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.owner = doc.fleetOwner ? this.getUserNamebyId(allUsers, doc.fleetOwner) : '';
      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.modifiedBy = doc.modifiedBy ? this.getUserNamebyId(allUsers, doc.modifiedBy) : '';

      if (!_.isEmpty(doc.statusDaterange)) {
         let _startDate = startDate ? startDate : moment().format('YYYY-MM-DD')
         let _endDate = endDate ? endDate : moment().format('YYYY-MM-DD')
         Object.keys(doc.statusDaterange).forEach((key) => {
            // debugger;
            if (doc?.statusDaterange?.[key]?.startDate?.seconds
               && doc?.statusDaterange?.[key]?.endDate?.seconds
               && (
                  moment(`${_startDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]') ||
                  moment(`${_endDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]')
               )) {
               doc.status = key;
               return false;
            }
         })
      }

      if (!_.isEmpty(doc.statusBooking)) {
         let _startDate = startDate ? startDate : moment().format('YYYY-MM-DD')
         let _endDate = endDate ? endDate : moment().format('YYYY-MM-DD')
         doc.statusBooking.forEach(rec => {
            if (rec?.startDate?.seconds
               && rec?.endDate?.seconds
               && (
                  moment(`${_startDate} 09:00`).isBetween(`${moment.unix(rec.startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(rec.endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]') ||
                  moment(`${_endDate} 09:00`).isBetween(`${moment.unix(rec.startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(rec.endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]')
               )) {
               doc.status = rec.status;
               return false;
            }
         });
      }


      const fleetProAllStatus = Object.assign([], _fleetPro_setttings?.fleetProStatus)
      let _objCurrentStatus = doc.status && fleetProAllStatus.filter(e => e.value === doc.status)[0];
      const _jobStatus = doc.status;

      listVM.statusValue = _jobStatus;
      listVM.statusName = this.getNameByValue(fleetProAllStatus, doc.status, '');
      listVM.status = _objCurrentStatus ? (<span key={doc.status}
         className={`status-${doc.status === tipStatus.NEW ? 'open' : 'empty'}`}
         style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      >
         {_objCurrentStatus.name}
      </span>) : '';

      let _clientNames = [];

      if (doc.groupID) _clientNames.push(this.getNameByCode(dealersettings.groups, doc.groupID, ''))
      else if (doc.subregionID) _clientNames.push(this.getNameByCode(dealersettings.subregions, doc.subregionID, ''))
      else if (doc.regionID) _clientNames.push(this.getNameByCode(dealersettings.regions, doc.regionID, ''))
      listVM.dealersName = _clientNames ? _clientNames.join(', ') : '';
      listVM.dealersNameTag = !_.isEmpty(_clientNames) ? <div className="Vehicle-availability">
         {
            _clientNames.map((rec, index) => {
               return <div key={index} title={doc.level}><span>{rec}</span></div>
            })
         }
      </div> : <></>

      if (!_.isEmpty(doc.dynamicFields) && _fleetPro_setttings && _fleetPro_setttings.enquiryOptionsDF) {

         _fleetPro_setttings && _fleetPro_setttings.enquiryOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               listVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               listVM[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
            }
            else if (rec.type === 'date') {
               doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
            }
            else if (rec.type === 'multiselect') {
               listVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
               listVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                  {
                     doc.dynamicFields[rec.value].map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>
                     })
                  }
               </div>) : (<></>)
            }
            else {
               listVM[rec.value] = doc.dynamicFields[rec.value]
            }
         })
      }

      return listVM
   }

   convertFleetProAssignVM = (doc, props, startDate, endDate) => {

      doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;


      doc.startDate = doc.startDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
      doc.endDate = doc.endDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
      doc.departureDate = doc.departureDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.departureDate._seconds)._d) : doc.departureDate;
      doc.expectedArrivalDate = doc.expectedArrivalDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedArrivalDate._seconds)._d) : doc.expectedArrivalDate;
      doc.expectedReturnDate = doc.expectedReturnDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedReturnDate._seconds)._d) : doc.expectedReturnDate;

      doc.requestedDate = doc.requestedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.requestedDate._seconds)._d) : doc.requestedDate;
      doc.assignDate = doc.assignDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.assignDate._seconds)._d) : doc.assignDate;
      doc.approvedDate = doc.approvedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.approvedDate._seconds)._d) : doc.approvedDate;
      doc.receivedDate = doc.receivedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.receivedDate._seconds)._d) : doc.receivedDate;
      doc.releasedDate = doc.releasedDate?._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.releasedDate._seconds)._d) : doc.releasedDate;

      let dealersettings = props.dealersettings;
      let allUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers);
      // const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
      //    !_.isEmpty(props.dealersettings.client) &&
      //    !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

      const objenquiryData = Object.assign({}, doc);
      const listVM = Object.assign({});
      for (let [key, value] of Object.entries(objenquiryData)) {
         listVM[key] = value;
      }
      listVM.objDoc = Object.assign({}, objenquiryData);
      listVM.displayID = listVM.displayID ? listVM.displayID : '--';

      listVM.statusValue = doc.status;

      let _fleetPro_setttings = dealersettings?.client?.fleetPro;

      listVM.typeOfActivity = this.getNameByValue(_fleetPro_setttings?.typeOfActivity, doc.typeOfActivity, '');
      listVM.methodOfTransport = this.getNameByValue(_fleetPro_setttings?.methodOfTransport, doc.methodOfTransport, '');
      listVM.transportCompany = this.getNameByValue(_fleetPro_setttings?.transportCompany, doc.transportCompany, '');
      listVM.eventDate = this.showCombineDate(doc.startDate, doc.endDate)

      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.startDate = doc.startDate ? moment.unix(doc.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.endDate = doc.endDate ? moment.unix(doc.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.departureDate = doc.departureDate ? moment.unix(doc.departureDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.expectedArrivalDate = doc.expectedArrivalDate ? moment.unix(doc.expectedArrivalDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.expectedReturnDate = doc.expectedReturnDate ? moment.unix(doc.expectedReturnDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.requestedDate = doc.requestedDate ? moment.unix(doc.requestedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.assignDate = doc.assignDate ? moment.unix(doc.assignDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.approvedDate = doc.approvedDate ? moment.unix(doc.approvedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.receivedDate = doc.receivedDate ? moment.unix(doc.receivedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.releasedDate = doc.releasedDate ? moment.unix(doc.releasedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      listVM.addedBy = this.getUserNamebyId(allUsers, doc.addedBy);
      listVM.modifiedBy = this.getUserNamebyId(allUsers, doc.modifiedBy);
      listVM.requestedBy = doc.requestedBy ? this.getUserNamebyId(allUsers, doc.requestedBy) : '';
      listVM.assignBy = doc.assignBy ? this.getUserNamebyId(allUsers, doc.assignBy) : '';
      listVM.approvedBy = doc.approvedBy ? this.getUserNamebyId(allUsers, doc.approvedBy) : '';
      listVM.receivedBy = doc.receivedBy || doc.receivedByName ? this.getUserNamebyId(allUsers, doc.receivedBy, doc.receivedByName) : '';
      listVM.releasedBy = doc.releasedBy || doc.releasedByName ? this.getUserNamebyId(allUsers, doc.releasedBy, doc.releasedByName) : '';

      listVM.dealerName = doc.clientName ? doc.clientName : doc.clientID ? this.getOtherDealerName(dealersettings, doc.clientID) : '';
      listVM.malTechnicalSupportReq = doc.malTechnicalSupportReq ? (doc.malTechnicalSupportReq === true ? 'Yes' : 'No') : '';

      if (!_.isEmpty(doc.fleetPro)) {
         let _fleetPro = doc.fleetPro;
         listVM.vehicleModel = _fleetPro.make ? _fleetPro.make + ' ' + (_fleetPro.model || '') : '';
         listVM.vehicleREG = _fleetPro.regNo;
         listVM.vehicleVIN = _fleetPro.vinNo;
         listVM.fleetOwner = _fleetPro.fleetOwner ? this.getUserNamebyId(allUsers, _fleetPro.fleetOwner) : '';
      }

      listVM.status = (doc.status ?
         <div key={doc.status} className={`badge badge-pill badge-status-${doc.status.toLowerCase()} float-left`}>
            {doc.status.split(/(?=[A-Z])/).join(' ')}
         </div> :
         <></>);

      // if (doc.statusDaterange) {
      //    let _startDate = startDate ? startDate : moment().format('YYYY-MM-DD')
      //    let _endDate = endDate ? endDate : moment().format('YYYY-MM-DD')
      //    Object.keys(doc.statusDaterange).forEach((key) => {
      //       // debugger;
      //       if (doc?.statusDaterange?.[key]?.startDate?.seconds
      //          && doc?.statusDaterange?.[key]?.endDate?.seconds
      //          && (
      //             moment(`${_startDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]') ||
      //             moment(`${_endDate} 09:00`).isBetween(`${moment.unix(doc.statusDaterange[key].startDate.seconds).format('YYYY-MM-DD')} 00:00`, `${moment.unix(doc.statusDaterange[key].endDate.seconds).format('YYYY-MM-DD')} 23:59`, undefined, '[]')
      //          )) {
      //          doc.status = key;
      //          return false;
      //       }
      //    })
      // }

      // const fleetProAllStatus = Object.assign([], _fleetPro_setttings?.fleetProStatus)
      // let _objCurrentStatus = doc.status && fleetProAllStatus.filter(e => e.value === doc.status)[0];
      // const _jobStatus = doc.status;

      // listVM.statusValue = _jobStatus;
      // listVM.statusName = this.getNameByValue(fleetProAllStatus, doc.status, '');
      // listVM.status = _objCurrentStatus ? (<span key={doc.status}
      //    className={`status-${doc.status === tipStatus.NEW ? 'open' : 'empty'}`}
      //    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      // >
      //    {_objCurrentStatus.name}
      // </span>) : '';

      // let _clientNames = [];

      // if (doc.groupID) _clientNames.push(this.getNameByCode(dealersettings.groups, doc.groupID, ''))
      // else if (doc.subregionID) _clientNames.push(this.getNameByCode(dealersettings.subregions, doc.subregionID, ''))
      // else if (doc.regionID) _clientNames.push(this.getNameByCode(dealersettings.regions, doc.regionID, ''))
      // listVM.dealersName = _clientNames ? _clientNames.join(', ') : '';
      // listVM.dealersNameTag = !_.isEmpty(_clientNames) ? <div className="Vehicle-availability">
      //    {
      //       _clientNames.map((rec, index) => {
      //          return <div key={index} title={doc.level}><span>{rec}</span></div>
      //       })
      //    }
      // </div> : <></>



      return listVM
   }


   convertActivityLogVM = (doc, props) => {
      if (doc.convertVM === true)
         return doc

      doc.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
      doc.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
      doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.completedDate = doc.completedDate && doc.completedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.completedDate._seconds)._d) : doc.completedDate;

      const logVM = doc;
      if (!doc.startDate) {
         doc.startDate = doc.addedDate
      }

      if (!doc.endDate) {
         doc.endDate = doc.startDate
      }

      if (!_.isObject(doc.addedDate)) {
         doc.addedDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate)._d)
      }

      let dealersettings = props.dealersettings;
      if (dealersettings && dealersettings.client && dealersettings.client.settings) {
         logVM.activityOutcomeName = this.getNameByValue(dealersettings.client.settings.activityOutcome, doc.activityOutcome, '');
      }
      let _allUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers);
      logVM.dueDate = moment.unix(doc.startDate.seconds).format('DD/MM/YYYY');
      logVM.dueTime = moment.unix(doc.startDate.seconds).format('hh:mm A') + ' - ' + moment.unix(doc.endDate.seconds).format('hh:mm A');
      logVM.activityAddedDate = moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
      logVM.activityOwner = this.getUserNamebyId(_allUsers, doc.owner);

      logVM.activityCompletedDate = doc.completedDate ? moment.unix(doc.completedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      logVM.activityCompletedBy = this.getUserNamebyId(_allUsers, doc.completedBy);

      logVM.pipelineValue = doc.enquiry ? doc.enquiry.pipelineValue ? doc.enquiry.pipelineValue : doc.enquiry.pipeline : '';
      logVM.originValue = doc.enquiry ? doc.enquiry.originValue ? doc.enquiry.originValue : doc.enquiry.origin : '';
      logVM.leadSourceValue = doc.enquiry ? doc.enquiry.leadSourceValue ? doc.enquiry.leadSourceValue : doc.enquiry.leadSource : '';
      logVM.enquiryTypeValue = doc.enquiry ? doc.enquiry.enquiryTypeValue ? doc.enquiry.enquiryTypeValue : doc.enquiry.enquiryType : '';
      logVM.campaignValue = doc.enquiry ? doc.enquiry.campaignValue ? doc.enquiry.campaignValue : doc.enquiry.campaign : '';
      logVM.statusValue = doc.enquiry ? doc.enquiry.statusValue ? doc.enquiry.statusValue : doc.enquiry.status : '';
      logVM.labelValue = doc.enquiry ? doc.enquiry.labelValue ? doc.enquiry.labelValue : doc.enquiry.label : '';
      logVM.lostReasonValue = doc.enquiry ? doc.enquiry.lostReasonValue ? doc.enquiry.lostReasonValue : doc.enquiry.lostReason : '';
      logVM.lostSubReasonValue = doc.enquiry ? doc.enquiry.lostSubReasonValue ? doc.enquiry.lostSubReasonValue : doc.enquiry.lostSubReason : '';

      if (!_.isEmpty(doc.thread)) {
         logVM.notes = doc.thread.snippet
      }

      if (!_.isEmpty(doc.enquiry)) {
         if (doc.enquiry.status === 'pendingLost') {
            doc.enquiry.status = 'lost'
         }
         logVM.enquiryStatus = this.getNameByValue(props.dealersettings.client.settings.enquiryStatus, doc.enquiry.status, '')
         logVM.enqNumber = doc.enquiry.displayID;
         logVM.enqPipeline = this.getNameByValue((props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.allPipelines : []), doc.enquiry.pipeline, '');

         if (!_.isEmpty(doc.enquiry.requirement) && (!_.isEmpty(doc.enquiry.requirement.make) || !_.isEmpty(doc.enquiry.requirement.stock))) {
            logVM.vehicleModel = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.make + ' ' + doc.enquiry.requirement.stock.model : doc.enquiry.requirement.make + ' ' + doc.enquiry.requirement.model
            logVM.vehicleYear = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.year : doc.enquiry.requirement.year;
            logVM.vehicleSaletype = this.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType, doc.enquiry.requirement.saleType, '');
            logVM.vehicleBodytype = !_.isEmpty(doc.enquiry.requirement.stock) ? doc.enquiry.requirement.stock.bodyType : doc.enquiry.requirement.bodyType;
         }
      }
      if (!_.isEmpty(doc.tradeinPro)) {
         logVM.enquiryStatus = this.getNameByValue(props?.dealersettings?.client?.tradeinPro?.tradeinProStatus, doc?.tradeinPro?.status, '')
         logVM.enqNumber = doc.tradeinPro.displayID;
         logVM.enqPipeline = this.getNameByValue((props.dealersettings && props.dealersettings.client && props.dealersettings.client.tradeinPro ? props.dealersettings.client.tradeinPro.allPipelines : []), doc.tradeinPro.pipeline, '');

         if (!_.isEmpty(doc.tradeinPro.tradein)) {
            logVM.vehicleModel = doc.tradeinPro.tradein.make + ' ' + doc.tradeinPro.tradein.model
            logVM.vehicleYear = doc?.tradeinPro?.tradein?.year || '';
            logVM.vehicleSaletype = '';
            logVM.vehicleBodytype = doc?.tradeinPro?.tradein?.bodyType || '';
         }
      }
      if (!_.isEmpty(doc.stock)) {
         logVM.testdriveModel = doc.stock.make + ' ' + doc.stock.model;
      }

      let _contact = !_.isEmpty(doc.contact) ? doc.contact :
         !_.isEmpty(doc.enquiry?.contact) ? doc.enquiry.contact :
            !_.isEmpty(doc.tradeinPro?.contact) ? doc.tradeinPro.contact : null;
      if (!_.isEmpty(_contact)) {

         _contact.addedDate = _contact.addedDate && _contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.addedDate._seconds)._d) : _contact.addedDate;
         _contact.modifiedDate = _contact.modifiedDate && _contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.modifiedDate._seconds)._d) : _contact.modifiedDate;

         //logVM.existingCustomer =_contact.isRepeat === true ? true : false;
         logVM.contactID = _contact.documentID;
         logVM.existingCustomer = _contact.isRepeat === true ? true : false;
         logVM.contactName = this.displayFullContact(props?.dealersettings?.client?.settings?.titles, _contact, '', true);
         logVM.firstName = (_contact.firstName || '');
         logVM.middleName = (_contact.middleName || '');
         logVM.lastName = (_contact.lastName || '');
         logVM.phone = this.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
         logVM.contactPhone = this.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
         logVM.contactPhoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
         logVM.csvPhone = logVM.phone ? `${logVM.phone}` : ''
         logVM.contactEmail = _contact.email;
         logVM.licenseNo = _contact.licenseNo;
         logVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
         logVM.contactType = _contact.contactType;
         logVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
         logVM.gender = _contact.gender;
         logVM.maritalStatus = _contact.maritalStatus;
         logVM.contactMethod = _contact.contactMethod;
         logVM.licenseType = _contact.licenseType;
         logVM.language = this.getNameByCode(props.dealersettings.languages, _contact.language, '');
         logVM.nationality = this.getNameByValue(props.dealersettings.nationalities, _contact.nationality, _contact.nationality);
         logVM.contactNumber = _contact.displayID;

         let dealersettings = props.dealersettings;
         if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            logVM.gender = this.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
            logVM.maritalStatus = this.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
            logVM.contactMethod = this.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
            logVM.interests = this.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
            logVM.licenseType = this.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');

         }

         logVM.address = _contact.address;
         logVM.companyName = _contact.companyName;
         logVM.designation = _contact.designation;

         logVM.licenseState = _contact.licenseState;
         logVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
         logVM.secondaryContactPhone = this.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
         logVM.secondaryContactEmail = _contact.secondaryEmail;
         logVM.contactCreatedBy = this.getUserNamebyId(_allUsers, _contact.addedBy);
         logVM.contactModifiedby = this.getUserNamebyId(_allUsers, _contact.modifiedBy);
         logVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         logVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         logVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
         logVM.optinPhone = _contact.optinPhone === null ? '' : (_contact.optinPhone ? 'Yes' : 'No');
         logVM.optinEmail = _contact.optinEmail === null ? '' : (_contact.optinEmail ? 'Yes' : 'No');
         logVM.optinPost = _contact.optinPost === null ? '' : (_contact.optinPost ? 'Yes' : 'No');
         logVM.optinSMS = _contact.optinSMS === null ? '' : (_contact.optinSMS ? 'Yes' : 'No');
         logVM.marketingOptinEmail = _contact.marketingOptinEmail === null ? '' : (_contact.marketingOptinEmail ? 'Yes' : 'No');
         logVM.marketingOptinSMS = _contact.marketingOptinSMS === null ? '' : (_contact.marketingOptinSMS ? 'Yes' : 'No');

         if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
            logVM.dealerName = this.getOtherDealerName(dealersettings, _contact.clientID);
         }
         else
            logVM.dealerName = '';

      }
      //logVM.convertVM = true
      return logVM
   }

   convertTestdriveVM = (doc, props) => {
      if (doc.convertVM === true)
         return doc

      let dealersettings = props.dealersettings;
      var testDriveVM = doc;
      testDriveVM.startDate = doc.startDate && doc.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate._seconds)._d) : doc.startDate;
      testDriveVM.endDate = doc.endDate && doc.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate._seconds)._d) : doc.endDate;
      testDriveVM.expectedReturn = doc.expectedReturn && doc.expectedReturn._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.expectedReturn._seconds)._d) : doc.expectedReturn;
      testDriveVM.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      testDriveVM.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      testDriveVM.tdstatusName = doc.status;
      testDriveVM.startDateValue = doc.startDate;
      testDriveVM.addedByValue = doc.addedBy;
      testDriveVM.stockStatusName = !_.isEmpty(doc.stock) ? this.getNameByValue(dealersettings?.client?.settings?.stockStatus, doc.stock.status, '') : '';

      testDriveVM.enqStatusName = !_.isEmpty(doc.enquiry) ? doc.enquiry.status : '';
      testDriveVM.createdOn = testDriveVM.addedDate ? moment.unix(testDriveVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
      testDriveVM.updatedOn = testDriveVM.modifiedDate ? moment.unix(testDriveVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
      testDriveVM.addedBy = testDriveVM.addedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), testDriveVM.addedBy) : '--';
      testDriveVM.modifiedBy = testDriveVM.modifiedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), testDriveVM.modifiedBy) : '--';
      testDriveVM.pipelineValue = doc.enquiry ? doc.enquiry.pipeline : '';
      testDriveVM.originValue = doc.enquiry ? doc.enquiry.originValue ? doc.enquiry.originValue : doc.enquiry.origin : '';
      testDriveVM.leadSourceValue = doc.enquiry ? doc.enquiry.leadSourceValue ? doc.enquiry.leadSourceValue : doc.enquiry.leadSource : '';
      testDriveVM.enquiryTypeValue = doc.enquiry ? doc.enquiry.enquiryTypeValue ? doc.enquiry.enquiryTypeValue : doc.enquiry.enquiryType : '';
      testDriveVM.campaignValue = doc.enquiry ? doc.enquiry.campaignValue ? doc.enquiry.campaignValue : doc.enquiry.campaign : '';
      testDriveVM.statusValue = doc.enquiry ? doc.enquiry.statusValue ? doc.enquiry.statusValue : doc.enquiry.status : '';
      testDriveVM.labelValue = doc.enquiry ? doc.enquiry.labelValue ? doc.enquiry.labelValue : doc.enquiry.label : '';
      testDriveVM.lostReasonValue = doc.enquiry ? doc.enquiry.lostReasonValue ? doc.enquiry.lostReasonValue : doc.enquiry.lostReason : '';
      testDriveVM.lostSubReasonValue = doc.enquiry ? doc.enquiry.lostSubReasonValue ? doc.enquiry.lostSubReasonValue : doc.enquiry.lostSubReason : '';


      if (!_.isEmpty(testDriveVM.contact)) {
         let _contact = Object.assign({}, testDriveVM.contact);
         _contact.addedDate = _contact.addedDate && _contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.addedDate._seconds)._d) : _contact.addedDate;
         _contact.modifiedDate = _contact.modifiedDate && _contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.modifiedDate._seconds)._d) : _contact.modifiedDate;

         //testDriveVM.existingCustomer = _contact.isRepeat === true ? true : false;
         testDriveVM.contactID = doc.contact.documentID;
         testDriveVM.contactName = this.displayFullContact(props?.dealersettings?.client?.settings?.titles, doc.contact, '', true);
         testDriveVM.firstName = (doc.contact.firstName || '');
         testDriveVM.middleName = (doc.contact.middleName || '');
         testDriveVM.lastName = (doc.contact.lastName || '');
         testDriveVM.phone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings, true);
         testDriveVM.contactPhone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, dealersettings);
         testDriveVM.contactPhoneCode = (doc.contact.phoneCode ? doc.contact.phoneCode : '');
         testDriveVM.csvPhone = testDriveVM.phone ? `${testDriveVM.phone}` : ''
         testDriveVM.contactEmail = _contact.email;
         testDriveVM.licenseNo = _contact.licenseNo;
         testDriveVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
         testDriveVM.contactType = _contact.contactType;
         testDriveVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
         testDriveVM.gender = _contact.gender;
         testDriveVM.maritalStatus = _contact.maritalStatus;
         testDriveVM.contactMethod = _contact.contactMethod;
         testDriveVM.licenseType = _contact.licenseType;
         testDriveVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
         testDriveVM.language = this.getNameByCode(props.dealersettings.languages, _contact.language, '');
         testDriveVM.nationality = this.getNameByValue(props.dealersettings.nationalities, _contact.nationality, _contact.nationality);
         testDriveVM.contactNumber = doc.contact.displayID;
         testDriveVM.displayID = doc.contact.displayID;


         if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            testDriveVM.gender = this.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
            testDriveVM.maritalStatus = this.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
            testDriveVM.contactMethod = this.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
            testDriveVM.interests = this.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
            testDriveVM.licenseType = this.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
         }

         testDriveVM.address = _contact.address;
         testDriveVM.companyName = _contact.companyName;
         testDriveVM.designation = _contact.designation;

         testDriveVM.licenseState = _contact.licenseState;
         testDriveVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
         testDriveVM.secondaryContactPhone = this.phoneFormat(doc.contact.secondaryPhoneCode, doc.contact.secondaryPhone, props.dealersettings);
         testDriveVM.secondaryContactEmail = _contact.secondaryEmail;
         testDriveVM.contactCreatedBy = _contact.addedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _contact.addedBy) : '';
         testDriveVM.contactModifiedby = _contact.modifiedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _contact.modifiedBy) : '';
         testDriveVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         testDriveVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

         if (_contact.clientID && (_contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            testDriveVM.contactdealerName = this.getOtherDealerName(dealersettings, _contact.clientID);
         }
         else
            testDriveVM.contactdealerName = '';
      }

      if (!_.isEmpty(testDriveVM.enquiry)) {
         const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : []);
         let _enquiry = Object.assign({}, testDriveVM.enquiry);
         let _objCurrentStatus = _enquiry.status && enquiryAllStatus.filter(e => e.value === _enquiry.status)[0];
         if (_enquiry.status === 'pendingLost') {
            _enquiry.status = 'lost'
         }
         testDriveVM.enqNumber = _enquiry.displayID;
         testDriveVM.contractNumber = !_.isEmpty(_enquiry.contractNumber) ? _enquiry.contractNumber : '';
         testDriveVM.depositReciptNumber = !_.isEmpty(_enquiry.depositReciptNumber) ? _enquiry.depositReciptNumber : '';
         testDriveVM.purchaseIntention = !_.isEmpty(_enquiry.purchaseIntention) ? _enquiry.purchaseIntention : '';
         testDriveVM.enqStatus = !_.isEmpty(_enquiry.status) ? (
            _objCurrentStatus
               ?
               <>
                  <span key={_enquiry.status}
                     className={`status-${_enquiry.status === 'open' ? 'open' : 'empty'}`}
                     style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                  >
                     {_objCurrentStatus.name}
                  </span>
               </>
               :
               <>

               </>
         ) : '';
         testDriveVM.enqOwner = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _enquiry.owner);
         testDriveVM.origin = this.getNameByValue(props.dealersettings.client.settings.origins, _enquiry.origin, '');
         testDriveVM.leadSource = this.getNameByValue(props.dealersettings.client.settings.leadSource, _enquiry.leadSource, '');
         testDriveVM.enquiryType = this.getNameByValue(props.dealersettings.client.settings.enquiryTypes, _enquiry.enquiryType, '');
         testDriveVM.campaign = this.getNameByValue(props.dealersettings.client.settings.campaigns, _enquiry.campaign, '');
         testDriveVM.labelName = this.getNameByValue(props.dealersettings.client.settings.enquiryLabels, _enquiry.label, '');
         testDriveVM.label = this.bindEnquiryLabel(props.dealersettings.client.settings.enquiryLabels, _enquiry.label, '');
         testDriveVM.pipeline = this.getNameByValue(props.dealersettings.allPipelines, _enquiry.pipeline, '');

         //BIND LOST REASONS
         if (_enquiry.lostReason) {
            let lostReasons = props.dealersettings.client.settings.lostReasons;
            let _lostReason = _enquiry.lostReason;
            testDriveVM.lostReason = this.getNameByValue(lostReasons, _enquiry.lostReason, '');
            if (_enquiry.lostSubReason && _enquiry.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
               let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
                  lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
               testDriveVM.lostSubReason = this.getNameByValue(lostSubReasons, _enquiry.lostSubReason, '');
            }
         }

         if (_.find(props.dealersettings.allPipelines, { value: testDriveVM.pipelineValue })) {
            testDriveVM.stage = this.getNameByValue(_.find(props.dealersettings.allPipelines, { value: testDriveVM.pipelineValue }).stages, _enquiry.stage, '');
         }
         testDriveVM.grossAmount = _enquiry.grossAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.grossAmount}` : '';
         testDriveVM.depositAmount = _enquiry.depositAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.depositAmount}` : '';
         testDriveVM.csvQuoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.quotation.amount}` : '';

         testDriveVM.quoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? <NumberFormat
            thousandSeparator={true}
            prefix={`${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}`}
            displayType={'text'}
            decimalScale={2}
            value={_enquiry.quotation.amount}
         /> : '';

         if (!_.isEmpty(_enquiry.requirement) && (!_.isEmpty(_enquiry.requirement.make) || !_.isEmpty(_enquiry.requirement.stock))) {
            testDriveVM.reqModel = !_.isEmpty(_enquiry.requirement.stock) ? _enquiry.requirement.stock.make + ' ' + _enquiry.requirement.stock.model : _enquiry.requirement.make + ' ' + _enquiry.requirement.model

            testDriveVM.reqSaletype = this.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
               _enquiry.requirement.saleType, '');
         }



      }

      if (!_.isEmpty(doc.stock)) {
         testDriveVM.stockNo = doc.stock.stockNo;
         testDriveVM.make = doc.stock.make;
         testDriveVM.model = doc.stock.model;
         testDriveVM.modelDescription = doc.stock.modelDescription;
         testDriveVM.saleType = this.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
            doc.stock.saleType, '');
         testDriveVM.type = doc.stock.type;
         testDriveVM.year = doc.stock.year;
         testDriveVM.regNo = doc.stock.regNo;
         testDriveVM.vinNo = doc.stock.vinNo;
         testDriveVM.extColor = doc.stock.extColor;
         testDriveVM.intColor = doc.stock.intColor;
         testDriveVM.price = doc.stock.price;
         testDriveVM.purchasePrice = doc.stock.purchasePrice;
         testDriveVM.location = doc.stock.location;
         testDriveVM.stockStatus = doc.stock.status;
         testDriveVM.statusUpdatedBy = doc.stock.statusModifiedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.stock.statusModifiedBy) : '';

         if (doc.stock.clientID && (doc.stock.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            testDriveVM.dealerName = this.getOtherDealerName(props.dealersettings, doc.stock.clientID);
         }
         else
            testDriveVM.dealerName = '';
      }

      testDriveVM.startDate = testDriveVM.startDate ? moment.unix(testDriveVM.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      testDriveVM.expectedReturn = testDriveVM.expectedReturn ? moment.unix(testDriveVM.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
      testDriveVM.endDate = testDriveVM.endDate ? moment.unix(testDriveVM.endDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

      testDriveVM.status = (testDriveVM.status
         ?
         <div key={testDriveVM.status} className={`badge badge-pill badge-${testDriveVM.status === testDriveStatus.PENDING ? 'pending' : 'active-fill'}`}>
            {testDriveVM.status}
         </div>
         :
         <></>);

      testDriveVM.stockStatus = this.bindStockLabel(dealersettings?.client?.settings?.stockStatus, testDriveVM.stockStatus, '');

      // testDriveVM.stockStatus = (testDriveVM.stockStatus
      //    ?
      //    <div key={testDriveVM.stockStatus} className={`badge badge-pill badge-${testDriveVM.stockStatus.trim().toLowerCase()}-fill `}>
      //       {testDriveVM.stockStatus.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
      //    </div>
      //    :
      //    <></>);


      testDriveVM.convertVM = true
      return testDriveVM
   }

   convertTradeinVM = (doc, props) => {
      if (doc.convertVM === true)
         return doc

      doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

      const tradeinProAllStatus = Object.assign([], props.dealersettings?.client?.tradeinPro?.tradeinProStatus)
      let _objCurrentStatus = doc.tradeinProID && doc.status && tradeinProAllStatus.filter(e => e.value === doc.status)[0];
      var tradeinVM = doc;
      tradeinVM.objDoc = Object.assign({}, doc);

      tradeinVM.addedDateValue = doc.addedDate;
      tradeinVM.addedByValue = doc.addedBy;
      tradeinVM.pipelineValue = doc.enquiry ? doc.enquiry.pipelineValue ? doc.enquiry.pipelineValue : doc.enquiry.pipeline : '';
      tradeinVM.stageValue = doc.enquiry ? doc.enquiry.stageValue ? doc.enquiry.stageValue : doc.enquiry.stage : '';
      tradeinVM.originValue = doc.enquiry ? doc.enquiry.originValue ? doc.enquiry.originValue : doc.enquiry.origin : '';
      tradeinVM.leadSourceValue = doc.enquiry ? doc.enquiry.leadSourceValue ? doc.enquiry.leadSourceValue : doc.enquiry.leadSource : '';
      tradeinVM.enquiryTypeValue = doc.enquiry ? doc.enquiry.enquiryTypeValue ? doc.enquiry.enquiryTypeValue : doc.enquiry.enquiryType : '';
      tradeinVM.campaignValue = doc.enquiry ? doc.enquiry.campaignValue ? doc.enquiry.campaignValue : doc.enquiry.campaign : '';
      tradeinVM.statusValue = doc.enquiry ? doc.enquiry.statusValue ? doc.enquiry.statusValue : doc.enquiry.status : '';
      tradeinVM.labelValue = doc.enquiry ? doc.enquiry.labelValue ? doc.enquiry.labelValue : doc.enquiry.label : '';
      tradeinVM.lostReasonValue = doc.enquiry ? doc.enquiry.lostReasonValue ? doc.enquiry.lostReasonValue : doc.enquiry.lostReason : '';
      tradeinVM.lostSubReasonValue = doc.enquiry ? doc.enquiry.lostSubReasonValue ? doc.enquiry.lostSubReasonValue : doc.enquiry.lostSubReason : '';
      tradeinVM.tradeinstatusName = doc.tradeinProID ? this.getNameByValue(tradeinProAllStatus, doc.status, '') : doc.status;

      tradeinVM.enqStatusName = !_.isEmpty(doc.enquiry) ? doc.enquiry.status : '';
      tradeinVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
      tradeinVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
      tradeinVM.addedBy = doc.addedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.addedBy) : '--';
      tradeinVM.modifiedBy = doc.modifiedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.modifiedBy) : '--';

      tradeinVM.vehicleModel = doc.make ? doc.make + ' ' + doc.model : '';
      tradeinVM.status = _objCurrentStatus ? (<div key={doc.status}
         className={`status-${doc.status === tipStatus.NEW ? 'open' : 'empty'}`}
         style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
      >
         {_objCurrentStatus.name}
      </div>) : (doc.status ?
         <div key={doc.status} className={`badge badge-pill badge-status-${doc.status.toLowerCase()} float-left`}>
            {doc.status.split(/(?=[A-Z])/).join(' ')}
         </div> :
         <></>);

      tradeinVM.regDate = doc.regDate ? moment(doc.regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.warrantyExpiry = doc.warrantyExpiry ? moment(doc.warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.warrantyStartDate = doc.warrantyStartDate ? moment(doc.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.serviceContFrom = doc.serviceContFrom ? moment(doc.serviceContFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.serviceContTo = doc.serviceContTo ? moment(doc.serviceContTo, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.lastService = doc.lastService ? moment(doc.lastService, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      tradeinVM.buildDate = doc.buildDate ? moment(doc.buildDate, 'YYYY-MM-DD').format('MM/YYYY') : '';
      tradeinVM.complianceDate = doc.complianceDate ? moment(doc.complianceDate, 'YYYY-MM-DD').format('MM/YYYY') : '';

      tradeinVM.massageVentilated = doc.massageVentilated ? (doc.massageVentilated === true ? 'Yes' : 'No') : '';
      tradeinVM.camera = doc.camera ? (doc.camera === true ? 'Yes' : 'No') : '';
      tradeinVM.contrastStiching = doc.contrastStiching ? (doc.contrastStiching === true ? 'Yes' : 'No') : '';
      tradeinVM.woodSteering = doc.woodSteering ? (doc.woodSteering === true ? 'Yes' : 'No') : '';
      tradeinVM.sunroof = doc.sunroof ? (doc.sunroof === true ? 'Yes' : 'No') : '';
      tradeinVM.outstandingPayments = doc.outstandingPayments ? (doc.outstandingPayments === true ? 'Yes' : 'No') : '';
      tradeinVM.financed = doc.financed ? (doc.financed === true ? 'Yes' : 'No') : '';
      tradeinVM.warranty = doc.warranty ? (doc.warranty === true ? 'Yes' : 'No') : '';

      const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.client) &&
         !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
      tradeinVM.finalPrice = doc.offerPrice ? this.formatCurrency(currencySymbol, doc.offerPrice) : '';
      tradeinVM.valuerPrice = doc.valuerPrice ? this.formatCurrency(currencySymbol, doc.valuerPrice) : doc.offerPrice ? this.formatCurrency(currencySymbol, doc.offerPrice) : '';

      let _setttings = doc.clientID && props.dealersettings?.client?.settings;
      if (!_.isEmpty(doc.dynamicFields) && _setttings && _setttings.tradeinOptionsDF) {

         _setttings && _setttings.tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
            if (rec.type === 'toggle') {
               tradeinVM[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
            }
            else if (rec.type === 'price') {
               tradeinVM[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
            }
            else if (rec.type === 'date') {
               doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
               tradeinVM[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
            }
            else if (rec.type === 'multiselect') {
               tradeinVM[`${rec.value}Value`] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
               tradeinVM[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                  {
                     doc.dynamicFields[rec.value].map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>
                     })
                  }
               </div>) : (<></>)
            }
            else {
               tradeinVM[rec.value] = doc.dynamicFields[rec.value]
            }
         })
      }

      // if (!_.isEmpty(doc.enquiryID) && _.isEmpty(doc.enquiry)) {
      //    const enquiryDoc = await window.firebase
      //       .firestore()
      //       .doc('enquiries/' + doc.enquiryID)
      //       .get();
      //    if (enquiryDoc.exists) {
      //       const enquiryData = enquiryDoc.data();
      //       enquiryData.documentID = enquiryDoc.id;
      //       doc.enquiry = enquiryData
      //    }
      // }


      // if (!_.isEmpty(doc.contactID) && _.isEmpty(doc.contact)) {
      //    const contactDoc = await window.firebase
      //       .firestore()
      //       .doc('contactDetails/' + doc.contactID)
      //       .get();
      //    if (contactDoc.exists) {
      //       const contactData = contactDoc.data();
      //       contactData.documentID = contactDoc.id;
      //       doc.contact = contactData;
      //    }
      // }
      let _contact = Object.assign({}, doc.contact ? doc.contact : (doc.enquiry && doc.enquiry.contact ? doc.enquiry.contact : null));
      if (!_.isEmpty(_contact)) {

         _contact.addedDate = _contact.addedDate && _contact.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.addedDate._seconds)._d) : _contact.addedDate;
         _contact.modifiedDate = _contact.modifiedDate && _contact.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_contact.modifiedDate._seconds)._d) : _contact.modifiedDate;

         //tradeinVM.existingCustomer = _contact.isRepeat === true ? true : false;
         tradeinVM.contactID = _contact.documentID;
         tradeinVM.contactName = this.displayFullContact(props?.dealersettings?.client?.settings?.titles, doc.contact, '', true);
         tradeinVM.firstName = (doc.contact.firstName || '');
         tradeinVM.middleName = (doc.contact.middleName || '');
         tradeinVM.lastName = (doc.contact.lastName || '');
         tradeinVM.phone = this.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings, true);
         tradeinVM.contactPhone = this.phoneFormat(_contact.phoneCode, _contact.phone, props.dealersettings);
         tradeinVM.contactPhoneCode = (_contact.phoneCode ? _contact.phoneCode : '');
         tradeinVM.csvPhone = tradeinVM.phone ? `${tradeinVM.phone}` : ''
         tradeinVM.contactEmail = _contact.email;
         tradeinVM.licenseNo = _contact.licenseNo;
         tradeinVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
         tradeinVM.contactType = _contact.contactType;
         tradeinVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
         tradeinVM.gender = _contact.gender;
         tradeinVM.maritalStatus = _contact.maritalStatus;
         tradeinVM.contactMethod = _contact.contactMethod;
         tradeinVM.licenseType = _contact.licenseType;
         tradeinVM.language = this.getNameByCode(props.dealersettings.languages, _contact.language, '');
         tradeinVM.nationality = this.getNameByValue(props.dealersettings.nationalities, _contact.nationality, _contact.nationality);
         tradeinVM.contactNumber = _contact.displayID;

         let dealersettings = props.dealersettings;
         if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            tradeinVM.gender = this.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
            tradeinVM.maritalStatus = this.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
            tradeinVM.contactMethod = this.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
            tradeinVM.interests = this.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
            tradeinVM.licenseType = this.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
         }

         tradeinVM.address = _contact.address;
         tradeinVM.companyName = _contact.companyName;
         tradeinVM.designation = _contact.designation;

         tradeinVM.licenseState = _contact.licenseState;
         tradeinVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
         tradeinVM.secondaryContactPhone = this.phoneFormat(_contact.secondaryPhoneCode, _contact.secondaryPhone, props.dealersettings);
         tradeinVM.secondaryContactEmail = _contact.secondaryEmail;
         tradeinVM.contactCreatedBy = _contact.addedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _contact.addedBy) : '';
         tradeinVM.contactModifiedby = _contact.modifiedBy ? this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _contact.modifiedBy) : '';
         tradeinVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         tradeinVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      }

      if (!_.isEmpty(doc.enquiry)) {
         const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : []);
         let _enquiry = Object.assign({}, doc.enquiry);
         let _objCurrentStatus = _enquiry.status && enquiryAllStatus.filter(e => e.value === _enquiry.status)[0];
         if (_enquiry.status === 'pendingLost') {
            _enquiry.status = 'lost'
         }
         tradeinVM.enqNumber = _enquiry.displayID;
         tradeinVM.contractNumber = !_.isEmpty(_enquiry.contractNumber) ? _enquiry.contractNumber : '';
         tradeinVM.depositReciptNumber = !_.isEmpty(_enquiry.depositReciptNumber) ? _enquiry.depositReciptNumber : '';
         tradeinVM.purchaseIntention = !_.isEmpty(_enquiry.purchaseIntention) ? _enquiry.purchaseIntention : '';
         tradeinVM.enqStatus = !_.isEmpty(_enquiry.status) ? (
            _objCurrentStatus
               ?
               <>
                  <span key={_enquiry.status}
                     className={`status-${_enquiry.status === 'open' ? 'open' : 'empty'}`}
                     style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                  >
                     {_objCurrentStatus.name}
                  </span>
               </>
               :
               <>

               </>
         ) : '';
         tradeinVM.enqOwner = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), _enquiry.owner);
         tradeinVM.origin = this.getNameByValue(props.dealersettings.client.settings.origins, _enquiry.origin, '');
         tradeinVM.leadSource = this.getNameByValue(props.dealersettings.client.settings.leadSource, _enquiry.leadSource, '');
         tradeinVM.enquiryType = this.getNameByValue(props.dealersettings.client.settings.enquiryTypes, _enquiry.enquiryType, '');
         tradeinVM.campaign = this.getNameByValue(props.dealersettings.client.settings.campaigns, _enquiry.campaign, '');
         tradeinVM.labelName = this.getNameByValue(props.dealersettings.client.settings.enquiryLabels, _enquiry.label, '');
         tradeinVM.label = this.bindEnquiryLabel(props.dealersettings.client.settings.enquiryLabels, _enquiry.label, '');
         tradeinVM.pipeline = this.getNameByValue(props.dealersettings.allPipelines, _enquiry.pipeline, '');

         //BIND LOST REASONS
         if (_enquiry.lostReason) {
            let lostReasons = props.dealersettings.client.settings.lostReasons;
            let _lostReason = _enquiry.lostReason;
            tradeinVM.lostReason = this.getNameByValue(lostReasons, _enquiry.lostReason, '');
            if (_enquiry.lostSubReason && _enquiry.lostReason && !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0])) {
               let lostSubReasons = !_.isEmpty(lostReasons.filter(e => e.value === _lostReason)[0].subList) ?
                  lostReasons.filter(e => e.value === _lostReason)[0].subList : [];
               tradeinVM.lostSubReason = this.getNameByValue(lostSubReasons, _enquiry.lostSubReason, '');
            }
         }

         if (_.find(props.dealersettings.allPipelines, { value: tradeinVM.pipelineValue })) {
            tradeinVM.stage = this.getNameByValue(_.find(props.dealersettings.allPipelines, { value: tradeinVM.pipelineValue }).stages, _enquiry.stage, '');
         }
         tradeinVM.grossAmount = _enquiry.grossAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.grossAmount}` : '';
         tradeinVM.depositAmount = _enquiry.depositAmount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.depositAmount}` : '';
         tradeinVM.csvQuoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol : ''}${_enquiry.quotation.amount}` : '';

         tradeinVM.quoteAmount = !_.isEmpty(_enquiry.quotation) && _enquiry.quotation.amount ? <NumberFormat
            thousandSeparator={true}
            prefix={`${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}`}
            displayType={'text'}
            decimalScale={2}
            value={_enquiry.quotation.amount}
         /> : '';

         if (!_.isEmpty(_enquiry.requirement) && (!_.isEmpty(_enquiry.requirement.make) || !_.isEmpty(_enquiry.requirement.stock))) {
            tradeinVM.reqModel = !_.isEmpty(_enquiry.requirement.stock) ? _enquiry.requirement.stock.make + ' ' + _enquiry.requirement.stock.model : _enquiry.requirement.make + ' ' + _enquiry.requirement.model

            tradeinVM.reqSaletype = this.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
               _enquiry.requirement.saleType, '');
         }


      }
      const _permissions = ((!_.isEmpty(props.dealersettings) &&
         !_.isEmpty(props.dealersettings.rolePermissions) &&
         !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

      tradeinVM.canModifyEnquiry = !_.isEmpty(props.dealersettings) ? (((!_.isEmpty(doc.enquiry) && doc.enquiry.owner === props.dealersettings.id) ||
         (!_.isEmpty(_permissions) && _permissions.modifyOtherEnquiry)) ? true : false) : false;

      tradeinVM.isDeivered = !_.isEmpty(doc.enquiry) && doc.enquiry.status === 'delivered' ? true : false;

      tradeinVM.availability = (<div className="Vehicle-availability text-capitalize">
         {tradeinVM.enquiryID ? <div><span>{'ams pro'}</span></div> : <></>}
         {tradeinVM.serviceID ? <div><span>{'service'}</span></div> : <></>}
         {tradeinVM.tradeinProID ? <div><span>{'trade-in pro'}</span></div> : <></>}
         {tradeinVM.stockID ? <div><span>{'stock'}</span></div> : <></>}
      </div>);

      tradeinVM.availabileFor = ((tradeinVM.enquiryID ? 'ams pro, ' : '') +
         (tradeinVM.serviceID ? 'service, ' : '') +
         (tradeinVM.tradeinProID ? 'trade-in pro, ' : '') +
         (tradeinVM.stockID ? 'stock' : ''));
      tradeinVM.convertVM = true
      return tradeinVM
   }

   mapTemplate = (template, contact, enquiry, dealersettings, users, tradeinPro, service, activity, device, eventsPro) => {
      let content = template;
      let vehicle = null;
      let client = dealersettings ? dealersettings.client : '';
      let owner = '';
      let salesowner = '';
      let user = null;
      let businessManger = null;
      let aftermarketManager = null;
      const dynamicOptions = dealersettings?.client?.settings?.enquiryOptionsDF;
      const currencySymbol = ((!_.isEmpty(dealersettings) &&
         !_.isEmpty(dealersettings.client) &&
         !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
      if (enquiry && enquiry.requirement && enquiry.requirement.stock) {
         vehicle = {
            make: enquiry.requirement.stock.make,
            model: enquiry.requirement.stock.model,
            regNo: enquiry.requirement.stock.regNo ? enquiry.requirement.stock.regNo : '',
            type: enquiry.requirement.stock.type ? enquiry.requirement.stock.type : enquiry.requirement.stock.model,
            group: enquiry.requirement.stock.group ? enquiry.requirement.stock.group : enquiry.requirement.stock.model,
            intColor: enquiry.requirement.stock.intColor ? enquiry.requirement.stock.intColor : null,
            extColor: enquiry.requirement.stock.extColor ? enquiry.requirement.stock.extColor : null,
            price: enquiry.requirement.stock.price ? enquiry.requirement.stock.price : null,
         };
      } else if (enquiry && enquiry.requirement) {
         vehicle = {
            make: enquiry.requirement.make,
            model: enquiry.requirement.model,
            regNo: '',
            type: enquiry.requirement.type ? enquiry.requirement.type : enquiry.requirement.model,
            group: enquiry.requirement.group ? enquiry.requirement.group : enquiry.requirement.model,
            intColor: enquiry.requirement.intColor ? enquiry.requirement.intColor : null,
            extColor: enquiry.requirement.extColor ? enquiry.requirement.extColor : null,
            price: enquiry.requirement.price ? enquiry.requirement.price : null,
         };
      }
      if (tradeinPro && tradeinPro.tradein) {
         vehicle = {
            make: tradeinPro.tradein.make,
            model: tradeinPro.tradein.model,
            regNo: tradeinPro.tradein.regNo ? tradeinPro.tradein.regNo : ''
         };
      }
      if (service && service.vehicle) {
         vehicle = {
            make: service.vehicle.make,
            model: service.vehicle.model,
            regNo: service.vehicle.rego ? service.vehicle.rego : (service.vehicle.regNo ? service.vehicle.regNo : ''),
            vinNo: service.vehicle.vinNo ? service.vehicle.vinNo : ''
         };
      }
      if (!_.isEmpty(enquiry) && !_.isEmpty(enquiry.regNo)) {
         vehicle = {
            ...vehicle,
            regNo: enquiry.regNo
         }
      }
      if (enquiry && enquiry.financeBM) {
         businessManger = _.find(users, { id: enquiry.financeBM })
      }
      if (enquiry && enquiry.afterMarketBM) {
         aftermarketManager = _.find(users, { id: enquiry.afterMarketBM })
      }
      if (enquiry && enquiry.owner) {
         owner = this.getUserNamebyId(users, enquiry.owner);
         user = _.find(users, { id: enquiry.owner })
      }
      else if (contact && contact.owner) {
         owner = this.getUserNamebyId(users, contact.owner)
         user = _.find(users, { id: contact.owner })
      }
      else if (tradeinPro && tradeinPro.owner) {
         owner = this.getUserNamebyId(users, tradeinPro.owner)
         salesowner = _.find(users, { id: tradeinPro.salesOwner })
         user = _.find(users, { id: tradeinPro.owner })
      }
      else if (service && service.owner) {
         owner = this.getUserNamebyId(users, service.owner)
         salesowner = _.find(users, { id: service.salesOwner })
         user = _.find(users, { id: service.owner })
      }
      else {
         owner = this.getUserNamebyId(users, localStorage.uid)
         user = _.find(users, { id: localStorage.uid })
      }
      // if (content && contact && activity) {
      //    if (contact.businessContact === true && contact.company && contact.company.name) {
      //       content = content.replace(/@FULL_NAME/g, contact.company.name);
      //    }
      // }
      if (content && contact) {
         if (contact && contact.firstName) content = content.replace(/@FIRST_NAME/g, _.trim(contact.firstName)); else content = content.replace(/@FIRST_NAME/g, '<first name>');
         if (contact && contact.lastName) content = content.replace(/@LAST_NAME/g, _.trim(contact.lastName)); else content = content.replace(/@LAST_NAME/g, '<last name>');
         if (contact && contact.middleName) content = content.replace(/@MIDDLE_NAME/g, _.trim(contact.middleName)); else content = content.replace(/@LAST_NAME/g, '<middle name>');
         if (contact && contact.email) content = content.replace(/@CUSTOMER_EMAIL/g, contact.email); else content = content.replace(/@CUSTOMER_EMAIL/g, '<email>');
         if (contact && contact.phone) content = content.replace(/@CUSTOMER_PHONE/g, contact.phone); else content = content.replace(/@CUSTOMER_PHONE/g, '<phone>');
         if (contact && (contact.firstName || contact.lastName || contact.middleName))
            content = content.replace(/@FULL_NAME/g, this.displayContactName([], contact));
         else
            content = content.replace(/@FULL_NAME/g, '<name>');
         if (contact && contact.company && contact.company.name)
            content = content.replace(/@COMPANY_NAME/g, contact.company.name);
         else
            content = content.replace(/@COMPANY_NAME/g, '<company>');

         if (contact && contact.documentID) content = content.replace(/@UNSUBSCRIBE_URL/g, `${window.location.origin}/contact/unsubscribe/${contact.documentID}`); else content = content.replace(/@UNSUBSCRIBE_URL/g, '#');
         if (contact && contact.firstName) content = content.replace(/@PREF_NAME/g, contact.preferredName); else content = content.replace(/@PREF_NAME/g, '<pref name>');
         if (client && client.name) content = content.replace(/@DEALER_NAME/g, client.name); else content = content.replace(/@DEALER_NAME/g, '<dealer name>');
         if (client && client.phone) content = content.replace(/@DEALER_PHONE/g, client.phone); else content = content.replace(/@DEALER_PHONE/g, '<dealer phone>');
         if (client && client.email) content = content.replace(/@DEALER_EMAIL/g, client.email); else content = content.replace(/@DEALER_EMAIL/g, '<dealer email>');
         if (client && client.address) content = content.replace(/@DEALER_ADDRESS/g, client.address); else content = content.replace(/@DEALER_ADDRESS/g, '<dealer address>');
         if (client && client.facebookURL) content = content.replace(/@FACEBOOK_URL/g, client.facebookURL); else content = content.replace(/@FACEBOOK_URL/g, '');
         if (client && client.xURL) content = content.replace(/@X_URL/g, client.xURL); else content = content.replace(/@X_URL/g, '');
         if (client && client.instagramURL) content = content.replace(/@INSTAGRAM_URL/g, client.instagramURL); else content = content.replace(/@INSTAGRAM_URL/g, '');
         if (client && client.youtubeURL) content = content.replace(/@YOUTUBE_URL/g, client.youtubeURL); else content = content.replace(/@YOUTUBE_URL/g, '');
         if (vehicle && vehicle.regNo) content = content.replace(/@VEHICLE_REGO/g, vehicle.regNo); else content = content.replace(/@VEHICLE_REGO/g, '<vehicle regno>');
         if (vehicle && vehicle.vinNo) content = content.replace(/@VEHICLE_VIN/g, vehicle.vinNo); else content = content.replace(/@VEHICLE_VIN/g, '<vehicle vin#>');
         if (vehicle && vehicle.model) content = content.replace(/@REQ_VEHICLE/g, vehicle.model); else content = content.replace(/@REQ_VEHICLE/g, '<vehicle model>');
         if (vehicle && vehicle.model) content = content.replace(/@REQ_MODEL/g, vehicle.model); else content = content.replace(/@REQ_MODEL/g, '<vehicle model>');
         if (vehicle && vehicle.make) content = content.replace(/@REQ_MAKE/g, vehicle.make); else content = content.replace(/@REQ_MAKE/g, '<vehicle make>');
         if (vehicle && vehicle.type) content = content.replace(/@MODEL_TYPE/g, vehicle.type); else content = content.replace(/@MODEL_TYPE/g, '<vehicle type>');
         if (vehicle && vehicle.group) content = content.replace(/@MODEL_GROUP/g, vehicle.group); else content = content.replace(/@MODEL_GROUP/g, '<vehicle model>');
         if (vehicle && vehicle.intColor) content = content.replace(/@INT_COLOR/g, vehicle.intColor); else content = content.replace(/@INT_COLOR/g, '<int. color>');
         if (vehicle && vehicle.extColor) content = content.replace(/@EXT_COLOR/g, vehicle.extColor); else content = content.replace(/@EXT_COLOR/g, '<ext. color>');
         if (vehicle && vehicle.price) content = content.replace(/@PRICE/g, `${currencySymbol} ${vehicle.price}`); else content = content.replace(/@PRICE/g, '<vehicle price>');
         if (enquiry && enquiry.warrantyExpiry) content = content.replace(/@WARRANTY_EXPIRY/g, enquiry.warrantyExpiry); else content = content.replace(/@WARRANTY_EXPIRY/g, '<warranty expiry>');
         if (enquiry?.deliveryDate?.seconds) content = content.replace(/@DELIVERY_DATE/g, moment.unix(enquiry.deliveryDate.seconds).format('DD/MM/YYYY')); else content = content.replace(/@DELIVERY_DATE/g, '<delivery date>');
         if (enquiry?.depositAmount) content = content.replace(/@DEPOSIT_AMOUNT/g, `${currencySymbol} ${enquiry.depositAmount}`); else content = content.replace(/@DEPOSIT_AMOUNT/g, '<deposit amount>');
         if (enquiry?.depositReciptNumber) content = content.replace(/@DEPOSIT_RECIPT_NUMBER/g, enquiry.depositReciptNumber); else content = content.replace(/@DEPOSIT_RECIPT_NUMBER/g, '<deposit recipt number>');
         // if (owner) content = content.replace(/@USER_NAME/g, owner); else content = content.replace(/@USER_NAME/g, '<salesperson name>');
         // if (user && user.email) content = content.replace(/@USER_EMAIL/g, user.email); else content = content.replace(/@USER_EMAIL/g, '<user email>');
         // if (user && user.phone) content = content.replace(/@USER_PHONE/g, user.phone); else content = content.replace(/@USER_PHONE/g, '<user phone>');
         // if (user && user.designation) content = content.replace(/@USER_DESIGNATION/g, user.designation); else content = content.replace(/@USER_DESIGNATION/g, '<designation>');

         if (vehicle && (content.includes("@REQ_VEHICLE") || content.includes("@REQ_MAKE") || content.includes("@TESTDRIVE_VEHICLE") || content.includes("@TRADEIN_VEHICLE"))) {
            let make = '', model = '';
            if (vehicle.make && dealersettings.client) {

               if (dealersettings.client.brands.length > 0) {
                  const brand = _.find(dealersettings.client.brands, { value: vehicle.make });
                  if (brand) {
                     make = brand.name;
                     if (vehicle.model) {
                        if (brand.models.length > 0) {
                           const modelName = brand.models.find(m => m.value === vehicle.model);
                           if (modelName) {
                              model = modelName.name;
                           }
                        }
                     }

                     const vehicleName = (make ? make + ' ' : '') + model;
                     content = content.replace(/@REQ_MAKE/g, make);
                     content = content.replace(/@REQ_VEHICLE/g, vehicleName);
                     content = content.replace(/@TESTDRIVE_VEHICLE/g, vehicleName);
                     content = content.replace(/@TRADEIN_VEHICLE/g, vehicleName);
                  }
               }
            }
         }
      }
      // else {
      //    if (owner) content = content.replace(/@USER_NAME/g, owner); else content = content.replace(/@USER_NAME/g, '<salesperson name>');
      //    if (user && user.email) content = content.replace(/@USER_EMAIL/g, user.email); else content = content.replace(/@USER_EMAIL/g, '<user email>');
      //    if (user && user.phone) content = content.replace(/@USER_PHONE/g, user.phone); else content = content.replace(/@USER_PHONE/g, '<user phone>');
      //    if (user && user.designation) content = content.replace(/@USER_DESIGNATION/g, user.designation); else content = content.replace(/@USER_DESIGNATION/g, '<designation>');
      // } 
      if (content && activity) {
         if (activity && activity.startDate) content = content.replace(/@SCHEDULE_DATETIME/g, moment.unix(activity.startDate.seconds).format('DD-MM-YYYY h:mm A')); else content = content.replace(/@SCHEDULE_DATETIME/g, '');
         if (activity && activity.startDate) content = content.replace(/@SCHEDULE_DATE/g, moment.unix(activity.startDate.seconds).format('DD/MM/YYYY')); else content = content.replace(/@SCHEDULE_DATE/g, '<schedule date>');
         if (activity && activity.startDate) content = content.replace(/@SCHEDULE_TIME/g, moment.unix(activity.startDate.seconds).format('h:mm A')); else content = content.replace(/@SCHEDULE_TIME/g, '<schedule time>');
         if (activity && activity.completedDate) content = content.replace(/@COMPLETED_DATE/g, moment.unix(activity.completedDate.seconds).format('DD/MM/YYYY')); else content = content.replace(/@COMPLETED_DATE/g, '<completed date>');
         if (activity && activity.completedDate) content = content.replace(/@COMPLETED_TIME/g, moment.unix(activity.completedDate.seconds).format('h:mm A')); else content = content.replace(/@COMPLETED_TIME/g, '<completed time>');

         if (activity && activity.startDate) content = content.replace(/@DD-MM-YYYY/g, moment.unix(activity.startDate.seconds).format('DD-MM-YYYY')); else content = content.replace(/@SCHEDULE_DATE/g, 'dd-mm-yyyy');
         if (activity && activity.startDate) content = content.replace(/@HH:MM/g, moment.unix(activity.startDate.seconds).format('h:mm A')); else content = content.replace(/@SCHEDULE_TIME/g, 'hh:mm');

      }
      if (content && service) {
         if (service && service.completionDate) content = content.replace(/@EST_COMPLETION_DATE/g, moment.unix(service.completionDate.seconds).format('DD/MM/YYYY')); else content = content.replace(/@EST_COMPLETION_DATE/g, '<est. completion date>');
         if (service && service.completionDate) content = content.replace(/@EST_COMPLETION_TIME/g, moment.unix(service.completionDate.seconds).format('h:mm A')); else content = content.replace(/@EST_COMPLETION_TIME/g, '<est. completion time>');

         if (service && service.nextService) content = content.replace(/@NEXT_SERVICE_DATE/g, moment.unix(service.nextService.seconds).format('DD/MM/YYYY')); else content = content.replace(/@NEXT_SERVICE_DATE/g, '<next service due date>');
         if (service && service.nextService) content = content.replace(/@NEXT_SERVICE_TIME/g, moment.unix(service.nextService.seconds).format('h:mm A')); else content = content.replace(/@NEXT_SERVICE_TIME/g, '<next service due time>');

      }
      if (content && device) {
         if (device && device.displayName) content = content.replace(/@ID/g, device.displayName); else content = content.replace(/@ID/g, '');
      }
      if (content) {
         content = content.replace(/@SCHEDULE_DATE/g, '<schedule date>');
         content = content.replace(/@SCHEDULE_TIME/g, '<schedule time>');
         content = content.replace(/@COMPLETED_DATE/g, '<completed date>');
         content = content.replace(/@COMPLETED_TIME/g, '<completed time>');
         content = content.replace(/@CURRENT_MONTH/g, moment().format('MMMM'));
         if (dealersettings && dealersettings.name) content = content.replace(/@USER_NAME/g, dealersettings.name); else content = content.replace(/@USER_NAME/g, '<salesperson name>');
         if (dealersettings && dealersettings.email) content = content.replace(/@USER_EMAIL/g, dealersettings.email); else content = content.replace(/@USER_EMAIL/g, '<user email>');
         if (dealersettings && dealersettings.phone) content = content.replace(/@USER_PHONE/g, dealersettings.phone); else content = content.replace(/@USER_PHONE/g, '<user phone>');
         if (dealersettings && dealersettings.designation) content = content.replace(/@USER_DESIGNATION/g, dealersettings.designation); else content = content.replace(/@USER_DESIGNATION/g, '<designation>');

         if (owner) content = content.replace(/@OWNER_NAME/g, owner); else content = content.replace(/@OWNER_NAME/g, '<owner name>');
         if (user && user.email) content = content.replace(/@OWNER_EMAIL/g, user.email); else content = content.replace(/@OWNER_EMAIL/g, '<owner email>');
         if (user && user.phone) content = content.replace(/@OWNER_PHONE/g, user.phone); else content = content.replace(/@OWNER_PHONE/g, '<owner phone>');
         if (user && user.designation) content = content.replace(/@OWNER_DESIGNATION/g, user.designation); else content = content.replace(/@OWNER_DESIGNATION/g, '<designation>');

         if (businessManger && businessManger.name) content = content.replace(/@BUSINESS_MANAGER/g, businessManger.name); else content = content.replace(/@BUSINESS_MANAGER/g, '<business manager name>');
         if (businessManger && businessManger.email) content = content.replace(/@FINANCEBM_EMAIL/g, businessManger.email); else content = content.replace(/@FINANCEBM_EMAIL/g, '<business manager email>');
         if (businessManger && businessManger.phone) content = content.replace(/@FINANCEBM_PHONE/g, businessManger.phone); else content = content.replace(/@FINANCEBM_PHONE/g, '<business manager phone>');
         if (businessManger && businessManger.designation) content = content.replace(/@FINANCEBM_DESIGNATION/g, businessManger.designation); else content = content.replace(/@FINANCEBM_DESIGNATION/g, '<business manager designation>');

         if (aftermarketManager && aftermarketManager.name) content = content.replace(/@AFTER_MARKET_MANAGER/g, aftermarketManager.name); else content = content.replace(/@AFTER_MARKET_MANAGER/g, '<after market manager name>');
         if (aftermarketManager && aftermarketManager.email) content = content.replace(/@AFTER_MARKET_MANAGER_EMAIL/g, aftermarketManager.email); else content = content.replace(/@AFTER_MARKET_MANAGER_EMAIL/g, '<after market manager email>');
         if (aftermarketManager && aftermarketManager.phone) content = content.replace(/@AFTER_MARKET_MANAGER_PHONE/g, aftermarketManager.phone); else content = content.replace(/@AFTER_MARKET_MANAGER_PHONE/g, '<after market manager phone>');
         if (aftermarketManager && aftermarketManager.designation) content = content.replace(/@AFTER_MARKET_MANAGER_DESIGNATION/g, aftermarketManager.designation); else content = content.replace(/@AFTER_MARKET_MANAGER_DESIGNATION/g, '<after market manager designation>');

         if (salesowner) content = content.replace(/@SALESOWNER_NAME/g, salesowner.name); else content = content.replace(/@SALESOWNER_NAME/g, '<sales owner name>');
         if (salesowner && salesowner.email) content = content.replace(/@SALESOWNER_EMAIL/g, salesowner.email); else content = content.replace(/@SALESOWNER_EMAIL/g, '<sales owner email>');
         if (salesowner && salesowner.phone) content = content.replace(/@SALESOWNER_PHONE/g, salesowner.phone); else content = content.replace(/@SALESOWNER_PHONE/g, '<sales owner phone>');
         if (salesowner && salesowner.designation) content = content.replace(/@SALESOWNER_DESIGNATION/g, salesowner.designation); else content = content.replace(/@SALESOWNER_DESIGNATION/g, '<sales owner designation>');


         if (dynamicOptions && enquiry) {
            dynamicOptions.forEach(rec => {
               if (content.includes(`@${rec.value}`)) {
                  let _dynValue = enquiry?.dynamicFields?.[rec.value];
                  if (rec.type === 'toggle' && _.isBoolean(_dynValue)) {
                     _dynValue = _dynValue ? 'Yes' : 'No';
                  }
                  else if (rec.type === 'date' && _dynValue) {
                     _dynValue = _dynValue.seconds ? moment.unix(_dynValue.seconds).format('DD/MM/YYYY') : ''
                  }
                  else if (rec.type === 'price' && _dynValue) {
                     _dynValue = `${currencySymbol} ${_dynValue}`
                  }
                  if (_dynValue) content = content.replace(new RegExp(`@${rec.value}`, 'g'), _dynValue); else content = content.replace(new RegExp(`@${rec.value}`, 'g'), `<${rec.name.toLowerCase()}>`);
               }
            })
         }
      }
      return content;
   }

   convertInboundLeadVM = (doc, props) => {
      if (doc.convertVM === true)
         return doc

      doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
      doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
      doc.stageDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;


      let listVM = doc;
      listVM.stageDateValue = doc.stageDate;
      listVM.originValue = doc.origin;
      listVM.leadSourceValue = doc.leadSource;
      listVM.enquiryTypeValue = doc.enquiryType;
      listVM.campaignValue = doc.campaign;
      listVM.statusValue = inboundLeadStatus[doc.status] ? inboundLeadStatus[doc.status].toLowerCase() : 'pending';
      listVM.pipelineValue = doc.pipeline;
      listVM.stageValue = doc.stage;
      listVM.statusName = inboundLeadStatus[doc.status];
      listVM.inboundType = (doc.systemType === 'oem' || doc.systemType === 'region' || doc.systemType === 'group') ? ['LeadsBucket'] : [doc.systemType]
      listVM.pipeline = this.getNameByValue(props.dealersettings.allPipelines, doc.pipeline, '');
      if (_.find(props.dealersettings.allPipelines, { value: listVM.pipelineValue })) {
         listVM.stage = this.getNameByValue(_.find(props.dealersettings.allPipelines, { value: listVM.pipelineValue }).stages, doc.stage, '');
      }
      listVM.origin = this.getNameByValue(props.dealersettings.client.settings.origins, doc.origin, '');
      listVM.leadSource = this.getNameByValue(props.dealersettings.client.settings.leadSource, doc.leadSource, '');
      listVM.enquiryType = this.getNameByValue(props.dealersettings.client.settings.enquiryTypes, doc.enquiryType, '');
      listVM.campaign = this.getNameByValue(props.dealersettings.client.settings.campaigns, doc.campaign, '');
      listVM.status = !_.isEmpty(inboundLeadStatus[doc.status]) ? (<span key={inboundLeadStatus[doc.status]} className={`status-${inboundLeadStatus[doc.status].toLowerCase()}`}>{inboundLeadStatus[doc.status].split(/(?=[A-Z])/).join(' ')}</span>) : '';
      listVM.stageDate = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
      listVM.modifiedBy = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.modifiedBy);
      listVM.wonDate = ''
      listVM.lostDate = ''
      listVM.deliveredDate = ''
      listVM.verifiedDate = ''
      listVM.confirmedDate = ''
      listVM.convertedDate = ''

      props.pipeline &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0] &&
         props.pipeline.filter(e => e.value === listVM.pipelineValue)[0].stages.forEach(col => {
            if (listVM.stageValue === col.value) {
               if (col.rottenDays) {
                  var enqage = listVM.modifiedDate ? moment().diff(moment.unix(listVM.modifiedDate.seconds), 'days') : 0
                  listVM.rottenDays = ((enqage - col.rottenDays) > 0 ? enqage - col.rottenDays : 0) + ' Days'
               }
            }
         })
      if (!_.isEmpty(doc.requirement) && (!_.isEmpty(doc.requirement.make) || !_.isEmpty(doc.requirement.stock))) {
         listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : doc.requirement.make + ' ' + doc.requirement.model
         listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;

         listVM.vehicleSaletype = this.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
            doc.requirement.saleType, '');
         listVM.vehicleType = <>
            <>{listVM.vehicleSaletype ? <>{listVM.vehicleSaletype}</> : <></>}</>
            <>{listVM.soldCount > 1 ? <div className="badge badge-pill badge-available badge-mini ml-1">{listVM.soldCount} WON</div> : <></>}</>
         </>
         listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
      }
      if (!_.isEmpty(doc.contact)) {
         listVM.contactName = this.displayFullContact(props?.dealersettings?.client?.settings?.titles, doc.contact, '', true);
         listVM.firstName = (doc.contact.firstName || '');
         listVM.middleName = (doc.contact.middleName || '');
         listVM.lastName = (doc.contact.lastName || '');
         listVM.phone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings);
         listVM.phoneCode = (doc.contact.phoneCode ? doc.contact.phoneCode : '');
         listVM.email = doc.contact.email;
         listVM.address = doc.contact.address;
         listVM.csvPhone = this.phoneFormat(doc.contact.phoneCode, doc.contact.phone, props.dealersettings, true);
         //listVM.existingCustomer = doc.contact.isRepeat === true ? true : false;

      }
      listVM.convertVM = true
      return listVM
   }

   convertTradeInMappingVM = (tradeinplus, tradein) => {
      tradein = Object.assign({}, tradein);
      if (!_.isEmpty(tradeinplus)) {
         tradein.make = tradeinplus['VehicleMake'];
         tradein.model = tradeinplus['VehicleModel'];
         tradein.modelDescription = tradeinplus['VehicleModelDescription'];
         tradein.notes = tradeinplus['Notes'];
         tradein.year = tradeinplus['VehicleYear'] ? tradeinplus['VehicleYear'].toString() : '';
         tradein.regNo = tradeinplus['RegNum'];
         tradein.regDate = tradeinplus['RegDate'] && tradeinplus['RegDate'] !== 'n/a' ? moment(tradeinplus['RegDate']).format('YYYY-MM-DD') : null; //DATE
         tradein.chassisNo = tradeinplus['ChassisNum'];
         tradein.engineNo = tradeinplus['EngineNum'];
         tradein.transmission = tradeinplus['Gear'];
         tradein.doors = tradeinplus['Doors'];
         tradein.mileage = tradeinplus['MileageKM'];
         tradein.exteriorColor = tradeinplus['VehicleColor'];
         tradein.interiorColor = tradeinplus['Interior'];
         tradein.trim = tradeinplus['Trim'];
         tradein.spareTyre = tradeinplus['SpareTyre'];
         tradein.massageVentilated = (tradeinplus['MassageVentilated'] === 'True' || tradeinplus['MassageVentilated'] === true) ? true : false;
         tradein.camera = (tradeinplus['Camera'] === 'True' || tradeinplus['Camera'] === true) ? true : false;
         tradein.contrastStiching = (tradeinplus['ContrastStiching'] === 'True' || tradeinplus['ContrastStiching'] === true) ? true : false;
         tradein.woodSteering = (tradeinplus['WoodSteering'] === 'True' || tradeinplus['WoodSteering'] === true) ? true : false;
         tradein.sunroof = (tradeinplus['Sunroof'] === 'True' || tradeinplus['Sunroof'] === true) ? true : false;
         tradein.owners = tradeinplus['Owners'];
         tradein.outstandingPayments = (tradeinplus['OutstandingPayments'] === 'True' || tradeinplus['OutstandingPayments'] === true) ? true : false;
         tradein.warranty = (tradeinplus['Warranty'] === 'True' || tradeinplus['Warranty'] === true) ? true : false;
         tradein.financeCo = tradeinplus['FinanceCo'];
         tradein.serviceContFrom = tradeinplus['ServiceContFrom'] && tradeinplus['ServiceContFrom'] !== 'n/a' ? moment(tradeinplus['ServiceContFrom']).format('YYYY-MM-DD') : null; //DATE
         tradein.serviceContTo = tradeinplus['ServiceContTo'] && tradeinplus['ServiceContTo'] !== 'n/a' ? moment(tradeinplus['ServiceContTo']).format('YYYY-MM-DD') : null; //DATE
         tradein.reconditioning = (tradeinplus['Reconditioning'] === 'True' || tradeinplus['Reconditioning'] === true) ? true : false;
         tradein.inspectionSnapshot = tradeinplus['VehicleImageURL'];
         tradein.tyreAge = tradeinplus['TyreAge'];
         tradein.financed = (tradeinplus['Financed'] === 'True' || tradeinplus['Financed'] === true) ? true : false;
         tradein.lastService = tradeinplus['LastService'] && tradeinplus['LastService'] !== 'n/a' ? moment(tradeinplus['LastService']).format('YYYY-MM-DD') : null; //DATE
         //tradein.status = tradeinplus['Status'];
         tradein.engineSize = tradeinplus['EngineSize'];
         tradein.type = tradeinplus['BodyType'];
         tradein.engineType = tradeinplus['EngineType'];
         tradein.frontLeftWheel = tradeinplus['FrontLeftWheel'];
         tradein.frontRightWheel = tradeinplus['FrontRightWheel'];
         tradein.rearLeftWheel = tradeinplus['RearLeftWheel'];
         tradein.rearRightWheel = tradeinplus['RearRightWheel'];
         tradein.vehicleCondition = tradeinplus['VehicleCondition'];
         tradein.buildDate = tradeinplus['BuildDate'] && tradeinplus['BuildDate'] !== 'n/a' ? moment(tradeinplus['BuildDate']).format('YYYY-MM-DD') : null; //DATE
         tradein.complianceDate = tradeinplus['ComplianceDate'] && tradeinplus['ComplianceDate'] !== 'n/a' ? moment(tradeinplus['ComplianceDate']).format('YYYY-MM-DD') : null; //DATE
         tradein.spareKey = tradeinplus['SpareKey'];
         tradein.logBooks = tradeinplus['LogBooks'];
         tradein.serviceHistory = tradeinplus['ServiceHistory'];
         tradein.cargoBlind = tradeinplus['CargoBlind'];
         tradein.headRests = tradeinplus['HeadRests'];
         tradein.sdCard = tradeinplus['SDCard'];
         tradein.warrantyExpiry = tradeinplus['WarrantyExpiryDate'] && tradeinplus['WarrantyExpiryDate'] !== 'n/a' ? moment(tradeinplus['WarrantyExpiryDate']).format('YYYY-MM-DD') : null; //DATE
         tradein.offerPrice = tradeinplus['FinalApprovedPrice'];
         tradein.isDeletedFromTradeIn = (tradeinplus['IsDeleted'] === 'True' || tradeinplus['IsDeleted'] === true) ? true : false;
         tradein.EvaluationNumber = tradeinplus['Id'];

         if (_.isArray(tradeinplus['TradeInImages'])) {
            const _images = [];
            tradeinplus['TradeInImages'].filter(e => e.ImageCategory === 'Detail').forEach((ele) => {
               _images.push(ele.ImageURL);
            });

            tradein.images = _images ? _images : tradein.images;
         }
         //console.log('convertTradeInMappingVM', tradein)

      }
      return tradein;

   }

   convertEvalMappingVM = (evalData, tradein) => {
      tradein = Object.assign({}, tradein);
      if (!_.isEmpty(evalData)) {
         if (evalData.BuildMth && evalData.BuildYr && evalData.BuildMth !== '0' && evalData.BuildYr !== '0')
            tradein.buildDate = `${evalData.BuildYr}-${evalData.BuildMth}-01`
         if (evalData.CompMth && evalData.CompYr && evalData.CompMth !== '0' && evalData.CompYr !== '0')
            tradein.complianceDate = `${evalData.CompYr}-${evalData.CompMth}-01`
         if (evalData.SvcMth && evalData.SvcYear && evalData.SvcMth !== '0' && evalData.SvcYear !== '0')
            tradein.lastService = `${evalData.SvcYear}-${evalData.SvcMth}-01`
         if (evalData.WtyMth && evalData.WtyYear && evalData.WtyMth !== '0' && evalData.WtyYear !== '0')
            tradein.warrantyExpiry = `${evalData.WtyYear}-${evalData.WtyMth}-01`

         if (evalData.Doors) tradein.doors = evalData.Doors;
         if (evalData.EngineNo) tradein.engineNo = evalData.EngineNo;
         if (evalData.Litres) tradein.engineSize = evalData.Litres;
         if (evalData.Ext_Colour) tradein.exteriorColor = evalData.Ext_Colour;
         if (evalData.Int_Colour) tradein.interiorColor = evalData.Int_Colour;
         if (evalData.ServiceHistory && _.isString(evalData.ServiceHistory)) tradein.serviceHistory = evalData.ServiceHistory;
         if (evalData.SpareKey && _.isString(evalData.SpareKey)) tradein.spareKey = evalData.SpareKey;
         if (evalData.customer && _.isString(evalData.customer)) tradein.owners = evalData.customer;
         if (evalData.FinanceName) tradein.financeCo = evalData.FinanceName;
         if (evalData.KM) tradein.mileage = evalData.KM;

         if (evalData.Make) tradein.make = evalData.Make;
         if (evalData.Model) tradein.model = evalData.Model;
         if (evalData.Year) tradein.year = evalData.Year;
         if (evalData.Body) tradein.type = evalData.Body;
         if (evalData.Registration) tradein.regNo = evalData.Registration;
         if (evalData.RegExpiry) tradein.regDate = moment(evalData.RegExpiry).format('YYYY-MM-DD')
         if (evalData.VIN) tradein.chassisNo = evalData.VIN;
         if (evalData.Reconditioning) tradein.reconditioning = this.stringToBoolean(evalData.Reconditioning);

         tradein.modelDescription = this.bindEvalModelDescription(evalData);

         if (evalData.FinalPrice) tradein.offerPrice = parseFloat(evalData.FinalPrice);
         if (evalData.Recon) tradein.reconditioningCost = parseFloat(evalData.Recon);

         if (evalData.Transmission) {
            if (evalData.Transmission.toLowerCase().indexOf('auto') > -1)
               tradein.transmission = 'Automatic';
            else if (evalData.Transmission.toLowerCase().indexOf('manu') > -1)
               tradein.transmission = 'Manual';
         }

         if (evalData.Fuel) {
            if (evalData.Fuel.toLowerCase().indexOf('petrol') > -1)
               tradein.engineType = 'Petrol';
            else if (evalData.Fuel.toLowerCase().indexOf('diesel') > -1)
               tradein.engineType = 'Diesel';
            else if (evalData.Fuel.toLowerCase().indexOf('electric') > -1)
               tradein.engineType = 'Electric';
            else if (evalData.Fuel.toLowerCase().indexOf('hybrid') > -1)
               tradein.engineType = 'Hybrid';
         }

         let _images = [];
         if (evalData.ImgURL && _.isString(evalData.ImgURL))
            _images.push(evalData.ImgURL);

         evalData.CarPics && evalData.CarPics.forEach((item) => {
            if (_.isString(item)) _images.push(item)
            else if (item.URL) _images.push(item.URL)
         })

         tradein.images = _images ? _images : tradein.images;

         if (evalData.EvalID) {
            tradein.evalexpertID = evalData.EvalID;
            let _tags = Object.assign([], tradein.tags);
            _tags = _tags.filter(e => e.type !== "evalexpert")
            _tags.push({ "refID": evalData.EvalID, "type": "evalexpert" })
            tradein.tags = _tags;
         }

         if (evalData.Notes) {
            tradein.notes = `${evalData.Notes}${(tradein.notes ? '\n' + tradein.notes : '')}`;
         }

         if (evalData.CarSpecs) {
            tradein.specifications = this.bindEvalSpecifications(evalData);
         }


      }
      return tradein;
   }

   bindEvalSpecifications = (data) => {
      let _description = null;
      if (data.CarSpecs && data.CarSpecs.Specifications) {
         data.CarSpecs.Specifications.forEach((item) => {
            if (item.Description)
               _description = (_description ? _description : '') + '\n' + item.Description;
         })
      }
      return _description ? _.trim(_description) : null;
   }

   stringToBoolean = (_data) => {
      switch (_data.toLowerCase().trim()) {
         case "true": case "yes": case "1": return true;
         case "false": case "no": case "0": case null: return false;
         default: return Boolean(_data);
      }
   }

   convertQuoteVM = (doc, props) => {
      if (doc.convertVM === true)
         return doc


      var quoteVM = doc;
      if (!_.isEmpty(doc.enquiry)) {
         quoteVM.owner = doc.enquiry.ownerValue;
         quoteVM.contactName = doc.enquiry.contactName;
         quoteVM.contactNumber = doc.enquiry.contactNumber;
         quoteVM.phone = doc.enquiry.phone;
         quoteVM.contactPhone = doc.enquiry.contactPhone;
         quoteVM.csvPhone = doc.enquiry.csvPhone;
         quoteVM.phoneCode = doc.enquiry.phoneCode;
         quoteVM.email = doc.enquiry.email;
         quoteVM.vehicleModel = doc.enquiry.vehicleModel;
         quoteVM.vehicleYear = doc.enquiry.vehicleYear;
         quoteVM.vehicleSaletype = doc.enquiry.vehicleSaletype;
         quoteVM.vehicleBodytype = doc.enquiry.vehicleBodytype;
         quoteVM.quoteAmount = <NumberFormat
            thousandSeparator={true}
            prefix={`${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}`}
            displayType={'text'}
            decimalScale={2}
            value={doc.payableOnDelivery ? doc.payableOnDelivery : 0}
         />
         quoteVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         quoteVM.addedBy = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.addedBy);
         quoteVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
         quoteVM.modifiedBy = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.modifiedBy);
         quoteVM.enqOwner = this.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.enquiry.ownerValue);
         quoteVM.csvQuoteNo = doc.displayID ? `${doc.displayID}` : ''
         quoteVM.csvQuoteAmount = `${!_.isEmpty(props.dealersettings.client.currency) ? props.dealersettings.client.currency.symbol + ' ' : '$ '}${doc.payableOnDelivery ? doc.payableOnDelivery : 0}`

      }

      quoteVM.convertVM = true
      return quoteVM
   }

   getAllWeeks = (startDate, endDate, weekStart) => {
      var dates = [];
      var weeks = [];

      moment.updateLocale('en', {
         week: {
            dow: weekStart,
         },
      })

      for (var m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
         dates.push({ date: m.startOf('day').toDate(), week: m.week(), month: m.month(), year: m.year() })
      }

      weeks = this.groupBy(dates, function (item) {
         return [item.year, item.month, item.week];
      }).map(rec => { return [rec[0].date, rec[rec.length - 1].date] });

      return weeks;
   }

   getAllMonths = (startDate, endDate) => {
      var dates = [];
      var months = [];

      for (var m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
         dates.push({ date: m.startOf('day').toDate(), month: m.month(), year: m.year() })
      }

      months = this.groupBy(dates, function (item) {
         return [item.year, item.month];
      }).map(rec => { return [rec[0].date, rec[rec.length - 1].date] });

      return months;
   }

   getAllDaysofWeek = (weekStart) => {
      var dates = [];
      var result = [];

      for (let i = 0; i < 7; i++) {
         dates = [];
         dates.push(moment().startOf('week')
            .add(i, 'day').startOf('day')._d)
         dates.push(moment().startOf('week')
            .add(i, 'day').endOf('day')._d)
         result.push(dates)

      }

      return result;
   }

   getAllDays = (startDate, endDate) => {
      var dates = [];
      var result = [];

      for (var m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
         dates = [];
         dates.push(m.startOf('day').toDate())
         dates.push(m.endOf('day').toDate())
         result.push(dates)
      }

      return result;
   }

   getCustomValue(obJArray, _value, name, value) {
      if (!_.isEmpty(obJArray) && (_value || _.isBoolean(_value)) && obJArray.filter(e => e[value] === _value).length > 0) {
         var label = obJArray.filter(e => e[value] === _value)[0][name]
         return (label ? label : _value)
      }
      return _value;
   }

   groupBy = (array, f) => {
      var groups = {};
      array.forEach(function (o) {
         var group = JSON.stringify(f(o));
         groups[group] = groups[group] || [];
         groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
         return groups[group];
      })
   }

   getCSVStyle = () => {
      let _headerStyle = {
         style:
         {
            font: {
               sz: 13,
               bold: true
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
         }
      }
      let _cellStyle = { style: { font: { sz: 11 }, alignment: { wrapText: true } } };
      let _footerStyle = {
         style:
         {
            font: {
               sz: 13,
               bold: true
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFdce6f1" } }
         }
      };

      return {
         header: _headerStyle,
         cell: _cellStyle,
         footer: _footerStyle
      }
   }

   setCustShowNoShow = (objData, dealersettings, _enableCustShowNoShow, _activityTypes) => {

      const enableCustShowNoShow = _.isBoolean(_enableCustShowNoShow) ? _enableCustShowNoShow : ((!_.isEmpty(dealersettings) &&
         !_.isEmpty(dealersettings.client) &&
         !_.isEmpty(dealersettings.client.clientSettings) &&
         dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

      const activityTypes = _activityTypes ? _activityTypes : (dealersettings &&
         dealersettings.client &&
         dealersettings.client.settings &&
         dealersettings.client.settings.activityTypes ? dealersettings.client.settings.activityTypes : []);

      if (enableCustShowNoShow && localStorage.defaultModule !== 'oem' &&
         (objData.subType === 'appointment' || (activityTypes && activityTypes.filter(e => e.value === objData.subType && e.appointment === true)[0]))
      ) {
         objData.inbound = true;
      }

      return objData;
   }

   getOtherDealerName = (dealersettings, cid, alternate) => {
      if (cid && cid === dealersettings?.client?.id) return dealersettings?.client?.name;

      let _allClients = Object.assign([], !_.isEmpty(dealersettings.allClients) ? dealersettings.allClients : dealersettings.clients)
      let clients = !_.isEmpty(_allClients) ?
         Object.keys(_allClients).map(_cid => {
            return {
               id: _allClients[_cid].id ? _allClients[_cid].id : _allClients[_cid].documentID,
               name: _allClients[_cid].name
            }
         }
         ) : []

      return cid && _.find(clients, { id: cid }) ? _.find(clients, { id: cid }).name : (alternate ? alternate : '')
   }

   getMultipleDealersName(obJArray, _value, alternate, viewType) {
      if (_value && !_.isEmpty(_value) && _.isArray(_value)) {
         let cells = [];
         _value.forEach((item) => {
            let _name = this.getOtherDealerName(obJArray, item, alternate)
            if (_name) cells.push(_name);
         })
         if (viewType === 'tags') {
            return <div className="Vehicle-availability">
               {
                  cells.map((rec, index) => {
                     return <div key={index}><span>{rec}</span></div>
                  })
               }
            </div>
         }
         else if (viewType === 'activityTags') {
            return <>
               {
                  cells.map((rec, index) => {
                     return <span key={index} className="events-dealership-span ml-1">{rec}</span>
                  })
               }
            </>
         }
         else
            return cells.join(', ');
      }
      return !_.isEmpty(_value) ? _value : alternate
   }

   setLevelIDs = (objData, dealersettings, pipelineView, _clientID) => {
      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
         //objData.level = dealersettings.level 
         if (dealersettings.level === 'oem' && dealersettings.oemID) {
            objData.oemID = dealersettings.oemID;
            objData.levelID = dealersettings.oemID;
            objData.level = 'oem'
         }
         else if (dealersettings.level === 'region' && dealersettings.regionID) {
            objData.regionID = dealersettings.regionID;
            objData.levelID = dealersettings.regionID;
            objData.level = 'region'
         }
         else if (dealersettings.level === 'group' && dealersettings.groupID) {
            objData.groupID = dealersettings.groupID;
            objData.levelID = dealersettings.groupID;
            objData.level = 'group'
         }
         else {
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.clientID = _clientID ? _clientID : dealersettings.client.id;
            objData.levelID = objData.clientID;
            objData.level = 'individual'
            // if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
            //    objData.levelID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            // }
         }
      }
      else {
         objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
         objData.clientID = _clientID ? _clientID : dealersettings.client.id;
         objData.levelID = objData.clientID;
         objData.level = 'individual'
         // if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
         //    objData.levelID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
         // }
      }
      return objData;
   }

   setClientOrGroupOrRegionOrOemID = (objData, dealersettings, pipelineView, _clientID, _isNoGroup) => {
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
         if (dealersettings.level === 'oem' && dealersettings.oemID) {
            objData.oemID = dealersettings.oemID;
         }
         else if (dealersettings.level === 'region' && dealersettings.regionID) {
            objData.regionID = dealersettings.regionID;
         }
         else if (dealersettings.level === 'group' && dealersettings.groupID) {
            objData.groupID = dealersettings.groupID;
         }
         else {
            if (!_isNoGroup && dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
               objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            }
            else {
               objData.clientID = _clientID ? _clientID : dealersettings.client.id;
            }
         }
      }
      else {
         if (!_isNoGroup && dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
            objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
         }
         else {
            objData.clientID = _clientID ? _clientID : dealersettings.client.id;
         }
      }
      return objData;
   }

   whereClientOrGroupTradeinPROContactClause = (dataRef, dealersettings, clientID) => {
      if (clientID && dealersettings.groups && dealersettings.groups.filter(e => e.client_ids && e.client_ids.includes(clientID) && e.enableSharedContact)[0]?.id)
         dataRef = dataRef.where('groupID', '==', dealersettings.groups.filter(e => e.client_ids && e.client_ids.includes(clientID) && e.enableSharedContact)[0]?.id)
      else if (clientID)
         dataRef = dataRef.where('clientID', '==', clientID)
      else
         dataRef = dataRef.where('clientID', 'in', this.getTradeinProDealers(dealersettings, true))


      return dataRef;
   }

   whereClientOrGroupOrRegionOrOemClause = (dataRef, dealersettings, pipelineView, _isPipeline, client_id) => {

      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {

         if (dealersettings.level === 'oem' && dealersettings.oemID)
            dataRef = dataRef.where('oemID', '==', dealersettings.oemID)
         else if (dealersettings.level === 'group' && dealersettings.groupID)
            dataRef = dataRef.where('groupID', '==', dealersettings.groupID)
         else if (dealersettings.level === 'region' && dealersettings.regionID)
            dataRef = dataRef.where('regionID', '==', dealersettings.regionID)
         else {
            if (!_isPipeline && dealersettings && dealersettings.group && dealersettings.group.enableSharedContact && dealersettings.client && dealersettings.client.group)
               dataRef = dataRef.where('groupID', '==', dealersettings.client.group)
            else
               dataRef = dataRef.where('clientID', '==', (client_id ? client_id : dealersettings.client.id))
         }
      }
      else {
         if (!_isPipeline && dealersettings && dealersettings.client && dealersettings.client.group && dealersettings.group && dealersettings.group.enableSharedContact)
            dataRef = dataRef.where('groupID', '==', dealersettings.client.group)
         else
            dataRef = dataRef.where('clientID', '==', (client_id ? client_id : dealersettings.client.id))
      }
      //console.log('whereClientOrGroupOrRegionOrOemClause', localStorage.defaultModule, dataRef)
      return dataRef;
   }

   whereClientOrGroupOrRegionOrOemLevel = (dataRef, dealersettings, client_id) => {

      if (dealersettings && localStorage.defaultModule === 'oem') {

         if (dealersettings.level === 'oem' && dealersettings.oemID)
            dataRef = dataRef.where('levelID', '==', dealersettings.oemID)
         else if (dealersettings.level === 'group' && dealersettings.groupID)
            dataRef = dataRef.where('levelID', '==', dealersettings.groupID)
         else if (dealersettings.level === 'region' && dealersettings.regionID)
            dataRef = dataRef.where('levelID', '==', dealersettings.regionID)
         else {
            dataRef = dataRef.where('clientID', '==', (client_id ? client_id : dealersettings.client.id))
         }
      }
      else {

         dataRef = dataRef.where('clientID', '==', (client_id ? client_id : dealersettings.client.id))
      }
      //console.log('whereClientOrGroupOrRegionOrOemClause', localStorage.defaultModule, dataRef)
      return dataRef;
   }

   whereClientOrGroupOrRegionOrOemClauseForConverted = (dataRef, dealersettings, pipelineView, _clientID) => {

      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
         if (dealersettings.level === 'oem' && dealersettings.oemID) {
            dataRef = dataRef.where('clientID', '==', dealersettings.oemID).where('isConverted', '==', true).orderBy('convertedDate', 'desc');
            //dataRef = dataRef.where('convertedOEMID', '==', dealersettings.oemID).where('isConvertedOEM', '==', true).orderBy('convertedDateOEM', 'desc');
         } else if (dealersettings.level === 'group' && dealersettings.groupID) {
            dataRef = dataRef.where('clientID', '==', dealersettings.groupID).where('isConverted', '==', true).orderBy('convertedDate', 'desc');
            //dataRef = dataRef.where('convertedGroupID', '==', dealersettings.groupID).where('isConvertedGroup', '==', true).orderBy('convertedDateGroup', 'desc');
         } else if (dealersettings.level === 'region' && dealersettings.regionID) {
            dataRef = dataRef.where('clientID', '==', dealersettings.regionID).where('isConverted', '==', true).orderBy('convertedDate', 'desc');
            //dataRef = dataRef.where('convertedRegionID', '==', dealersettings.regionID).where('isConvertedRegion', '==', true).orderBy('convertedDateRegion', 'desc');
         } else {
            dataRef = dataRef.where('clientID', '==', (_clientID ? _clientID : dealersettings.client.id)).where('isConverted', '==', true).orderBy('convertedDate', 'desc');
         }
      }
      else {
         dataRef = dataRef.where('clientID', '==', (_clientID ? _clientID : dealersettings.client.id)).where('isConverted', '==', true).orderBy('convertedDate', 'desc');
      }
      //console.log('whereClientOrGroupOrRegionOrOemClause', localStorage.defaultModule, dataRef)
      return dataRef;
   }

   whereClientOrGroupOrRegionOrOemClauseForConvertedDate = (dataRef, dealersettings, dateRange) => {

      if (dealersettings && localStorage.defaultModule === 'oem') {
         if (dealersettings.level === 'oem' && dealersettings.oemID)
            dataRef = dataRef.where('oemID', '==', dealersettings.oemID).where('oemStatus', '==', 'converted').where('oemConvertedDate', '>=', dateRange.startDate).where('oemConvertedDate', '<=', dateRange.endDate).orderBy('oemConvertedDate', 'desc');
         else if (dealersettings.level === 'group' && dealersettings.groupID)
            dataRef = dataRef.where('groupID', '==', dealersettings.groupID).where('oemStatus', '==', 'converted').where('oemConvertedDate', '>=', dateRange.startDate).where('oemConvertedDate', '<=', dateRange.endDate).orderBy('oemConvertedDate', 'desc');
         else if (dealersettings.level === 'region' && dealersettings.regionID)
            dataRef = dataRef.where('regionID', '==', dealersettings.regionID).where('oemStatus', '==', 'converted').where('oemConvertedDate', '>=', dateRange.startDate).where('oemConvertedDate', '<=', dateRange.endDate).orderBy('oemConvertedDate', 'desc');
         else {
            dataRef = dataRef.where('clientID', '==', dealersettings.client.id).where('isConverted', '==', true).where('convertedDate', '>=', dateRange.startDate).where('convertedDate', '<=', dateRange.endDate).orderBy('convertedDate', 'desc');
         }
      }
      else {
         dataRef = dataRef.where('clientID', '==', dealersettings.client.id).where('isConverted', '==', true).where('convertedDate', '>=', dateRange.startDate).where('convertedDate', '<=', dateRange.endDate).orderBy('convertedDate', 'desc');
      }
      //console.log('whereClientOrGroupOrRegionOrOemClause', localStorage.defaultModule, dataRef)
      return dataRef;
   }

   getLatestTransferHistory = (transferHistory, dealersettings, dontShowSame, stage) => {
      if (!_.isEmpty(transferHistory) && !_.isEmpty(dealersettings)) {
         let _record = _.last(transferHistory);
         if (stage === 'InboundLead') {
            if (!_.isEmpty(_record) && _record['fromOEM']) {
               return dealersettings.settingName ? Object.assign({}, { name: dealersettings.settingName, value: 'OEM' }) : null;
            }
            else if (!_.isEmpty(_record) && _record['fromRegion']) {
               return _.find(dealersettings.regions, { id: _record['fromRegion'] }) ? ((dontShowSame && dealersettings.level === 'region' && _record['fromRegion'] === dealersettings.regionID) ? null : Object.assign({}, { name: _.find(dealersettings.regions, { id: _record['fromRegion'] }).name, value: 'Region' })) : null;
            }
            else if (!_.isEmpty(_record) && _record['fromGroup']) {
               return _.find(dealersettings.groups, { id: _record['fromGroup'] }) ? ((dontShowSame && dealersettings.level === 'group' && _record['fromGroup'] === dealersettings.groupID) ? null : Object.assign({}, { name: _.find(dealersettings.groups, { id: _record['fromGroup'] }).name, value: 'Group' })) : null;
            }
            else if (!_.isEmpty(_record) && _record['fromClient']) {
               return _.find(dealersettings.clients, { id: _record['fromClient'] }) ? ((dontShowSame && localStorage.defaultModule !== 'oem' && _record['fromClient'] === dealersettings.client.id) ? null : Object.assign({}, { name: _.find(dealersettings.clients, { id: _record['fromClient'] }).name, value: 'Dealer' })) : null;
            }
            else
               return null;
         }
         else {
            if (!_.isEmpty(_record) && _record['toRegion']) {
               return _.find(dealersettings.regions, { id: _record['toRegion'] }) ? ((dontShowSame && dealersettings.level === 'region' && _record['toRegion'] === dealersettings.regionID) ? null : Object.assign({}, { name: _.find(dealersettings.regions, { id: _record['toRegion'] }).name, value: 'Region' })) : null;
            }
            else if (!_.isEmpty(_record) && _record['toGroup']) {
               return _.find(dealersettings.groups, { id: _record['toGroup'] }) ? ((dontShowSame && dealersettings.level === 'group' && _record['toGroup'] === dealersettings.groupID) ? null : Object.assign({}, { name: _.find(dealersettings.groups, { id: _record['toGroup'] }).name, value: 'Group' })) : null;
            }
            else if (!_.isEmpty(_record) && _record['toClient']) {
               return _.find(dealersettings.clients, { id: _record['toClient'] }) ? ((dontShowSame && localStorage.defaultModule !== 'oem' && _record['toClient'] === dealersettings.client.id) ? null : Object.assign({}, { name: _.find(dealersettings.clients, { id: _record['toClient'] }).name, value: 'Dealer' })) : null;
            }
            else
               return null;
         }
      }
      else
         return null;
   }

   getLatestTransferHistoryHTML = (transferHistory, dealersettings, dontShowSame, stage, alternate) => {
      //console.log('getLatestTransferHistoryHTML', transferHistory, dealersettings, dontShowSame, stage, alternate)
      let _transferHistoryData = this.getLatestTransferHistory(transferHistory, dealersettings, dontShowSame, stage);
      //console.log('getLatestTransferHistory', _transferHistoryData, _.last(transferHistory))
      if (!_.isEmpty(_transferHistoryData)) {
         return <div className="oem-tag-id"><i className="ico icon-location"></i>{_transferHistoryData.name} <span className="user-role-span ml-1">{_transferHistoryData.value}</span></div>
      }
      else
         return alternate ? alternate : ""

   }

   arrayContainsArray = (array1, array2) => {
      let j = 0
      for (var i = 0; i < array2.length; i++) {
         if (array1.indexOf(array2[i]) >= 0) {
            j++;
         }
      }
      return j > 0 ? true : false;
   }

   parseSearchText = (dealersettings, str) => {
      let text = str.trim()
      let phoneCode = ''

      if (!text) {
         return ''
      }

      const phoneReg = /^[+0-9 ]+$/;
      if (phoneReg.test(text)) {
         text = text.replace(/ /g, '')
      }

      if (!isNaN(text) && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
         let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
         let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
         if (!_.isEmpty(phone_code)) {
            phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
         }
      }

      if (!isNaN(text) && text !== '' && phoneCode) {
         if (text.startsWith(phoneCode)) {
            return text.replace(phoneCode, '')
         } else if (text.startsWith(`+${phoneCode}`)) {
            return text.replace(`+${phoneCode}`, '')
         } else {
            return text
         }
      } else {
         return text
      }
   }

   autoCaps = (str) => {
      if (str && str.length >= 1) {
         var firstChar = str.charAt(0);
         var remainingStr = str.slice(1);
         str = firstChar.toUpperCase() + (remainingStr ? remainingStr.toLowerCase() : '');
      }
      return str;
   }
   capitalizeString = (str) => {
      return str ? str.replace(/\b\w/g, char => char.toUpperCase()) : '';
   };

   updateClientSettings = async (clientID, _dSettings, _settingsName) => {
      try {
         const _refSettingsData = await window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get();
         let _settName = _settingsName ? _settingsName : 'settings';
         if (_dSettings && _refSettingsData.exists) {
            let _allClients = Object.assign([], _dSettings.allClients ? _dSettings.allClients : _dSettings.clients)
            let clientObj = _.find(_allClients, { id: clientID });

            let _settingData = _refSettingsData.data();
            if (_settingsName === 'eventsPro') {
               const _otherSettingsData = await window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}/${_settingsName}/${clientID}`).get();
               if (_otherSettingsData.exists) {
                  _settingData = {
                     ..._settingData,
                     [_settingsName]: _otherSettingsData.data()
                  }
               }
            }
            let ___settings = Object.assign({}, _settingData[_settName]);
            let results = Object.assign({}, { ..._dSettings });

            if (_settName === 'settings' && _.isEmpty(___settings.enquiryStatus)) {
               ___settings = {
                  ...___settings,
                  enquiryStatus: defaultenquiryStatus
               }
            }

            if (_.isEmpty(___settings.activityTypes)) {
               ___settings = {
                  ...___settings,
                  activityTypes: defaultActivityTypes
               }
            }

            if (_settName === 'settings' && _.isEmpty(___settings.enquiryLabels)) {
               ___settings = {
                  ...___settings,
                  enquiryLabels: defaultenquiryLabels
               }
            }

            if (_settName === 'tradeinPro' && _.isEmpty(___settings.tradeinProStatus)) {
               ___settings = {
                  ...___settings,
                  tradeinProStatus: defaulttipStatuses
               }
            }

            results = {
               ...results,
               client: {
                  ...results.client,
                  ['documentID']: _refSettingsData.id,
                  ['id']: _refSettingsData.id,
                  ['category']: _settingData.category ? _settingData.category : results.client.category,
                  ['countryCode']: _settingData.countryCode ? _settingData.countryCode : results.client.countryCode,
                  ['name']: _settingData.name ? _settingData.name : results?.client?.name,
                  ['brands']: _settingData.brands ? _settingData.brands : results?.client?.brands,
                  ['projectId']: _settingData.projectId ? _settingData.projectId : results?.client?.projectId,
                  ['timezone']: _settingData.timezone ? _settingData.timezone : results?.client?.timezone,
                  ['currency']: clientObj?.currency ? clientObj.currency : results?.client?.currency,
                  ['csID']: clientObj?.csID ? clientObj.csID : null,
                  [_settName]: ___settings,
                  ['integrations']: _settingData.integrations ? _settingData.integrations : {},
                  ['moduleSettings']: _settingData.moduleSettings ? _settingData.moduleSettings : {},
                  ['clientSettings']: clientObj?.clientSettings ? clientObj.clientSettings : {}
               },
               group: {
                  ...results.group,
                  ['clients']: results.group.clients ? {
                     ...results.group.clients,
                     [clientID]: {
                        ...results.client,
                        [_settName]: ___settings
                     }
                  } : {
                     [clientID]: {
                        ...results.client,
                        [_settName]: ___settings
                     }
                  }
               }
            }

            //console.log('updateClientSettings,', clientID, results);
            return results;
         }

         return _dSettings;
      }
      catch (err) {
         return _dSettings;
      }



   }

   setClientOrGroupEventsPROContactClause = (objData, dealersettings, clientID) => {
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      if (clientID) {
         if (dealersettings.groups && dealersettings.groups.filter(e => e.client_ids && e.client_ids.includes(clientID) && e.enableSharedContact)[0]?.id) {
            objData.isShared = true;
            objData.groupID = dealersettings.groups.filter(e => e.client_ids && e.client_ids.includes(clientID) && e.enableSharedContact)[0].id;
         }
         objData.clientID = clientID;
      }
      else {
         objData.clientID = dealersettings.client.id;
         if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
            objData.groupID = dealersettings?.client?.group ? dealersettings.client.group : null;
            objData.isShared = true;
         }
      }

      return objData;
   }

   setClientOrGroupOrRegionOrOemIDForContacts = (objData, dealersettings, _clientID, isCheck) => {
      let _oemID = dealersettings.oemID ? dealersettings.oemID : null;
      let _regionID = dealersettings.regionID ? dealersettings.regionID : null;
      let _groupID = dealersettings.groupID ? dealersettings.groupID : null;
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      if (dealersettings && localStorage.defaultModule === 'oem') {
         if (dealersettings.level === 'oem' && _oemID) {
            objData.oemID = _oemID;
            if (isCheck) { objData.isOEM = true; objData.isRegion = false; objData.isGroup = false; objData.isShared = false; }
         }
         else if (dealersettings.level === 'region' && _regionID) {
            objData.oemID = _oemID;
            objData.regionID = _regionID;
            if (isCheck) { objData.isOEM = false; objData.isRegion = true; objData.isGroup = false; objData.isShared = false; }
         }
         else if (dealersettings.level === 'group' && _groupID) {
            objData.oemID = _oemID;
            objData.regionID = _regionID;
            objData.groupID = _groupID;
            if (isCheck) { objData.isOEM = false; objData.isRegion = false; objData.isGroup = true; objData.isShared = false; }
         }
         else {
            objData.oemID = _oemID;
            objData.regionID = _regionID;
            objData.groupID = _groupID;
            objData.clientID = _clientID ? _clientID : dealersettings.client.id;
            if (isCheck) { objData.isOEM = false; objData.isRegion = false; objData.isGroup = false; objData.isShared = false; }
            if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
               objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
               if (isCheck) { objData.isOEM = false; objData.isRegion = false; objData.isGroup = true; }
               objData.isShared = true;
            }
         }
      }
      else {
         objData.oemID = _oemID;
         objData.regionID = _regionID;
         objData.groupID = _groupID;
         objData.clientID = _clientID ? _clientID : dealersettings.client.id;
         if (isCheck) { objData.isOEM = false; objData.isRegion = false; objData.isGroup = false; objData.isShared = false; }
         if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
            objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            if (isCheck) { objData.isOEM = false; objData.isRegion = false; objData.isGroup = true; }
            objData.isShared = true;
         }
      }
      return objData;
   }

   setLevelIDsForContacts = (objData, dealersettings, level, levelID) => {
      let _oemID = dealersettings.oemID ? dealersettings.oemID : null;
      let _regionID = dealersettings.regionID ? dealersettings.regionID : null;
      let _groupID = dealersettings.groupID ? dealersettings.groupID : null;

      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

      if (dealersettings && localStorage.defaultModule === 'oem') {
         if (level === 'oem' && levelID) {
            objData.oemID = levelID;
            objData.isOEM = true; objData.isRegion = false; objData.isGroup = false; objData.isShared = false;
         }
         else if (level === 'regionID' && levelID) {
            objData.oemID = _oemID;
            objData.regionID = levelID;
            objData.isOEM = false; objData.isRegion = true; objData.isGroup = false; objData.isShared = false;
         }
         else if (level === 'groupID' && levelID) {
            objData.oemID = _oemID;
            objData.regionID = _regionID;
            objData.groupID = levelID;
            objData.isOEM = false; objData.isRegion = false; objData.isGroup = true; objData.isShared = false;
         }
         else {
            objData.oemID = _oemID;
            objData.regionID = _regionID;
            objData.groupID = _groupID;
            objData.clientID = levelID ? levelID : dealersettings.client.id;
            objData.isOEM = false; objData.isRegion = false; objData.isGroup = false; objData.isShared = false;
            if (dealersettings && dealersettings.group && dealersettings.group.clients && dealersettings.group.clients[objData.clientID] && dealersettings.group.clients[objData.clientID].enableSharedContact) {
               objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
               objData.isOEM = false; objData.isRegion = false; objData.isGroup = true;
               objData.isShared = true;
            }
         }
      }
      else {
         objData.oemID = _oemID;
         objData.regionID = _regionID;
         objData.groupID = _groupID;
         objData.clientID = levelID ? levelID : dealersettings.client.id;
         objData.isOEM = false; objData.isRegion = false; objData.isGroup = false; objData.isShared = false;
         if (dealersettings && dealersettings.group && dealersettings.group.clients && dealersettings.group.clients[objData.clientID] && dealersettings.group.clients[objData.clientID].enableSharedContact) {
            objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            objData.isOEM = false; objData.isRegion = false; objData.isGroup = true;
            objData.isShared = true;
         }
      }
      return objData;
   }

   setClientOrGroupOrRegionOrOemIDForEnquiry = (objData, dealersettings, pipelineView, _clientID, isAddOEMID) => {
      let _oemID = dealersettings.oemID ? dealersettings.oemID : null;
      let _regionID = dealersettings.regionID ? dealersettings.regionID : null;
      let _groupID = dealersettings.groupID ? dealersettings.groupID : null;
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      if (_.isEmpty(objData.settingsID) && dealersettings.oemID) objData.settingsID = _oemID;

      if (dealersettings && localStorage.defaultModule === 'oem' && (pipelineView ? pipelineView === 'INBOUND' : true)) {
         if (dealersettings.level === 'oem' && _oemID) {
            objData.oemID = _oemID;
            objData.regionID = null;
            objData.groupID = null;
            objData.clientID = null;
         }
         else if (dealersettings.level === 'region' && _regionID) {
            objData.oemID = null;
            objData.regionID = _regionID;
            objData.groupID = null;
            objData.clientID = null;
         }
         else if (dealersettings.level === 'group' && _groupID) {
            objData.oemID = null;
            objData.regionID = null;
            objData.groupID = _groupID;
            objData.clientID = null;
         }
         else {
            objData.oemID = isAddOEMID ? _oemID : null;
            objData.regionID = null;
            objData.groupID = null;
            objData.clientID = _clientID ? _clientID : dealersettings.client.id;
            if (dealersettings && dealersettings.group && dealersettings.group.clients && dealersettings.group.clients[objData.clientID] && dealersettings.group.clients[objData.clientID].enableSharedContact) {
               objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            }
         }
      }
      else {
         objData.oemID = isAddOEMID ? _oemID : null;
         objData.regionID = null;
         objData.groupID = null;
         objData.clientID = _clientID ? _clientID : dealersettings.client.id;
         if (dealersettings && dealersettings.group && dealersettings.group.clients && dealersettings.group.clients[objData.clientID] && dealersettings.group.clients[objData.clientID].enableSharedContact) {
            objData.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
         }
      }
      return objData;
   }

   isPrivacyEnabled = (dealersettings) => {
      let securitysettings = !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && dealersettings.client.securitysettings
      if (!_.isEmpty(securitysettings) && securitysettings.lockLicenseImage && securitysettings.passwordExpiryForLockLicense &&
         (_.isEmpty(localStorage.privacyUpdatedOn) ||
            (!_.isEmpty(localStorage.privacyUpdatedOn) && (moment().diff(moment.unix(localStorage.privacyUpdatedOn), 'seconds') > securitysettings.passwordExpiryForLockLicense))
         ))
         return true;
      else
         return false;
   }

   isPrivacyFileEnabled = (dealersettings) => {
      let securitysettings = !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && dealersettings.client.securitysettings
      if (!_.isEmpty(securitysettings) && securitysettings.lockFileDownload && securitysettings.passwordExpiryForLockFileDownload &&
         (_.isEmpty(localStorage.privacyFileUpdatedOn) ||
            (!_.isEmpty(localStorage.privacyFileUpdatedOn) && (moment().diff(moment.unix(localStorage.privacyFileUpdatedOn), 'seconds') > securitysettings.passwordExpiryForLockFileDownload))
         ))
         return true;
      else
         return false;
   }

   checkBrowser = () => {
      // Chrome 1 - 79
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

      // Opera 8.0+
      var isOpera = window.navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';

      // Detect Safari 
      let isSafari = window.navigator.userAgent.indexOf("Safari") > -1;

      // Discard Safari since it also matches Chrome 
      if ((isChrome) && (isSafari))
         isSafari = false;

      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;

      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;


      var browserName = 'Unknown Browser'
      if (isOpera) { browserName = 'Opera'; }
      else if (isFirefox) { browserName = 'Firefox'; }
      else if (isSafari) { browserName = 'Safari'; }
      else if (isIE) { browserName = 'Internet Explorer'; }
      else if (isEdge) { browserName = 'Edge'; }
      else if (isChrome) { browserName = 'Chrome'; }
      else if (isEdgeChromium) { browserName = 'Edge Chromium'; }
      else if (isBlink) { browserName = 'Blink'; }

      return browserName;
   }


   timeformatfromSeconds = (_seconds, client, _format) => {
      var diffHours = 8;
      var result = '00:00:00';
      var totalSeconds = 0;
      if (client && client.workingHours) {
         const hours = client.workingHours.split(';');
         const start = moment(hours[0], 'HH:mm:ss');
         const end = moment(hours[1], 'HH:mm:ss');
         diffHours = end.hours() - start.hours();
      }

      const _minutes = _seconds / 60;
      const _hours = _minutes / 60;
      const days = Math.floor(_hours / diffHours);
      if (days > 0) {
         const diffSeconds = _seconds - (days * diffHours * 60 * 60);
         result = days + (days === 1 ? ' day ' : ' days ') +
            (diffSeconds > 0 ? moment().startOf('day').seconds(diffSeconds).format('HH:mm:ss') : '');
         totalSeconds = (days * 24 * 60 * 60) + diffSeconds;
      }
      else {
         result = moment().startOf('day').seconds(_seconds).format('HH:mm:ss');
         totalSeconds = _seconds;
      }

      return _format === 'seconds' ? totalSeconds : result;
   }

   logFirstWalkin = (enquiryID, walkinDate, dealersettings) => {
      try {
         firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`)
            .get()
            .then(doc => {
               if (doc.exists) {
                  if (_.isEmpty(doc.data().walkinDate)) {
                     firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
                        walkinDate: walkinDate
                     }, { merge: true })
                  }
               }
            })
      }
      catch (error) {
         console.log(error);
      }
   }
   showHighlightSearchTxt(_text, _searchTxt) {
      if (_searchTxt && _text) {
         const normReq = _searchTxt
            .toLowerCase()
            .replace(/\s+/g, " ")
            .trim()
            .split(" ")
            .sort((a, b) => b.length - a.length);
         return _text.replace(new RegExp(`(${normReq.join("|")})`, "gi"), match => "<highlight>" + match + "</highlight>");
      }
      else
         return _text;

   }

   getServiceSlotByTimeOfDay = async (_date, timeOfDay, currentSlots, dealersettings) => {

      if (_.isEmpty(_date) || _.isEmpty(timeOfDay))
         return null;

      const selectedDate = moment(_date).format('YYYY-MM-DD');
      let value = selectedDate + ' ' + (timeOfDay ? timeOfDay + ':00' : moment().format('HH:mm:ss'));

      if (value && !_.isEmpty(currentSlots)) {
         const startDate = moment(selectedDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
         const endDate = moment(selectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
         const ref = firestoreDB(dealersettings).firestore().collection('serviceJobs')
            .where('clientID', '==', dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('appointmentDate', '>=', startDate)
            .where('appointmentDate', '<=', endDate)
         const snapshots = await ref.get();

         let _services = []
         if (snapshots.size > 0) {
            snapshots.docs.forEach((doc) => {
               _services.push({ ...doc.data(), documentID: doc.id })
            });
         }

         let _selectedSlot = null;
         currentSlots && currentSlots.forEach((_slot) => {
            let _selectedData = !_.isEmpty(_services) && _services.filter(m => !_.isEmpty(m.slot) && m.slot.value === _slot.value);
            _slot.count = !_.isEmpty(_selectedData) ? _selectedData.length : 0;

            if (_slot.total > 0 && _slot.count < _slot.total && _.isEmpty(_selectedSlot)) {
               let _slotTimes = this.getSlotTimes(_slot.from, _slot.to, _slot.halfanHrSlot);

               if (_.find(_slotTimes, { 'value': timeOfDay })) {
                  //console.log('_slot', _slot)
                  _selectedSlot = _slot;
                  return true;
               }
            }
         });
         return _selectedSlot;
      }

      return null;

   }

   getSlotTimes = (_from, _to, _halfanHrSlot) => {
      if (_from && _to) {
         let startTime = parseFloat(_from.replace(':', '.'));
         let endTime = parseFloat(_to.replace(':', '.'));
         if (_halfanHrSlot)
            return _.orderBy(this.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime), ['code'], ['asc'])
         else {
            if (parseInt(_from.split(':')[1]) > 0)
               return _.orderBy(this.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime && a.half), ['code'], ['asc'])
            else
               return _.orderBy(this.getSlotTimeValues().filter(a => a.code >= startTime && a.code <= endTime && a.full), ['code'], ['asc'])

         }
      }
   }

   getTotalServiceCost = (service, recommendations, servicePackages) => {
      const { taxType, taxPercentage, serviceType, internalTaxExemption, discount, discountType, parts } = service;
      let _recommendationLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required));

      let _totalCost = 0;
      let _totalPartsCost = 0;
      let _totalServicePartsCost = 0;
      let _totalLabourCost = 0;
      let _totalPackagesCost = 0;
      let _totalTaxAmt = 0;
      let _subtotalAmt = 0;

      let _actualDiscountAmount = 0;
      let _actualDiscountRecomm = 0;
      let _actualDiscountPack = 0;

      let _actualTotalCost = 0;
      let _actualSubtotalAmt = 0;

      _recommendationLists && _recommendationLists.forEach(_recommData => {

         const _recommendation = Object.assign({}, _recommData);
         let recommendationDetail = Object.assign({}, _recommendation.detail);

         if (recommendationDetail.option !== recommendationOption.warranty && recommendationDetail.oKToDo === true) {

            let _total = parseFloat(parseFloat(recommendationDetail.labourCost) * parseFloat(recommendationDetail.allocationJobTime));
            let totalRecommCost = _.isNumber(_total) && !_.isNaN(_total) ? parseFloat(_total) : 0;
            var total = 0;
            let recommendationParts = Object.assign([], recommendationDetail.parts);
            if (!_.isEmpty(recommendationParts)) {
               for (var i = 0; i < recommendationParts.length; i++) {
                  if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(recommendationParts[i].quote)) {
                     let _rpQuantity = recommendationParts[i]?.quantity > 0 ? recommendationParts[i].quantity : 1
                     let totalPartCost = parseFloat(recommendationParts[i].partCost * _rpQuantity);

                     if (recommendationParts[i].priceLevelCode) {
                        var discountPrice = parseFloat((totalPartCost * (recommendationParts[i].priceLevelCode)) / 100);

                        let markupAmount = parseFloat(totalPartCost + (discountPrice));

                        totalPartCost = parseFloat(markupAmount);
                     }

                     total += totalPartCost ? totalPartCost : 0;
                  }
               }
            }
            _totalLabourCost += parseFloat(totalRecommCost);
            _totalPartsCost += parseFloat(total);
            let _totRecommAMT = parseFloat(totalRecommCost + total);
            _totalCost += _totRecommAMT;
            _actualTotalCost += _totRecommAMT;

            if (recommendationDetail.discount) {
               var totalDosc = 0;
               if (recommendationDetail.discountType === discount_type.percentage)
                  totalDosc = parseFloat((_totRecommAMT * (recommendationDetail.discount)) / 100);
               else
                  totalDosc = parseFloat(recommendationDetail.discount)
               _actualDiscountRecomm += parseFloat(totalDosc);
               _totalCost -= parseFloat(totalDosc);
            }

         }
      })

      if (!_.isEmpty(parts)) {
         var total = 0;
         let serviceParts = Object.assign([], parts);
         for (var i = 0; i < serviceParts.length; i++) {
            if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(serviceParts[i].quote)) {
               let _rpQuantity = serviceParts[i]?.quantity > 0 ? serviceParts[i].quantity : 1
               let totalPartCost = parseFloat(serviceParts[i].partCost * _rpQuantity);

               if (serviceParts[i].priceLevelCode) {
                  var discountPrice = parseFloat((totalPartCost * (serviceParts[i].priceLevelCode)) / 100);

                  let markupAmount = parseFloat(totalPartCost + (discountPrice));

                  totalPartCost = parseFloat(markupAmount);
               }

               total += totalPartCost ? totalPartCost : 0;
            }
         }
         _totalServicePartsCost += parseFloat(total);
         _totalCost += parseFloat(total);
         _actualTotalCost += parseFloat(total);
      }

      if (!_.isEmpty(servicePackages)) {

         for (var i = 0; i < servicePackages.length; i++) {
            //if (servicePackages[i].oKToDo === true)
            let totalPackCost = (servicePackages[i].price ? servicePackages[i].price : 0);
            _totalPackagesCost += parseFloat(totalPackCost)

            if (servicePackages[i].discount) {
               var totalDosc = 0;
               if (servicePackages[i].discountType === discount_type.percentage)
                  totalDosc = parseFloat((totalPackCost * (servicePackages[i].discount)) / 100);
               else
                  totalDosc = parseFloat(servicePackages[i].discount)

               _actualDiscountPack += parseFloat(totalDosc);
               _totalCost -= parseFloat(totalDosc);
            }
         }

         _totalCost += parseFloat(_totalPackagesCost);
         _actualTotalCost += parseFloat(_totalPackagesCost);
      }

      _subtotalAmt = _totalCost;
      _actualSubtotalAmt = _actualTotalCost;

      if (discount) {
         if (discountType === discount_type.percentage)
            _actualDiscountAmount = parseFloat((_totalCost * (discount)) / 100);
         else
            _actualDiscountAmount = parseFloat(discount)

         _totalCost -= parseFloat(_actualDiscountAmount);
      }

      // IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
      if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType && _totalCost) {

         _totalTaxAmt = parseFloat((_totalCost * (taxPercentage)) / 100);
         _totalCost += parseFloat(_totalTaxAmt);
         _actualTotalCost += parseFloat(_totalTaxAmt);
      }

      let _totalDiscount = parseFloat(_actualDiscountAmount + _actualDiscountRecomm + _actualDiscountPack)
      return {
         total: _totalCost,
         totalDiscount: _totalDiscount,
         labourCost: _totalLabourCost,
         partsCost: _totalPartsCost,
         packagesCost: _totalPackagesCost,
         servicePartsCost: _totalServicePartsCost,
         taxCost: _totalTaxAmt,
         subTotal: _subtotalAmt,
         actualDiscount: _actualDiscountAmount,
         actualDiscountRecomm: _actualDiscountRecomm,
         actualDiscountPack: _actualDiscountPack,
         actualTotalCost: _actualTotalCost,
         actualSubTotal: _actualSubtotalAmt
      }

   }

   getTotalServicePartsCost = (service) => {
      const { taxType, taxPercentage, serviceType, internalTaxExemption, parts } = service;

      let _totalCost = 0;
      let _totalTaxAmt = 0;
      if (!_.isEmpty(parts)) {
         var total = 0;
         let serviceParts = Object.assign([], parts);
         for (var i = 0; i < serviceParts.length; i++) {
            if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(serviceParts[i].quote)) {
               let _rpQuantity = serviceParts[i]?.quantity > 0 ? serviceParts[i].quantity : 1
               let totalPartCost = parseFloat(serviceParts[i].partCost * _rpQuantity);

               if (serviceParts[i].priceLevelCode) {
                  var discountPrice = parseFloat((totalPartCost * (serviceParts[i].priceLevelCode)) / 100);

                  let markupAmount = parseFloat(totalPartCost + (discountPrice));

                  totalPartCost = parseFloat(markupAmount);
               }

               total += totalPartCost ? totalPartCost : 0;
            }
         }
         _totalCost += parseFloat(total);
      }

      // IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
      if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType && _totalCost) {

         _totalTaxAmt = parseFloat((_totalCost * (taxPercentage)) / 100);
         _totalCost += parseFloat(_totalTaxAmt);
      }

      return {
         total: _totalCost,
         taxCost: _totalTaxAmt
      }
   }

   getTotalRecommCost = (service, _recommData) => {
      const { taxType, taxPercentage, serviceType, internalTaxExemption } = service;


      let _totalCost = 0;
      let _totalPartsCost = 0;
      let _totalLabourCost = 0;
      let _totalTaxAmt = 0;
      let _subtotalAmt = 0;
      let _subtotalAfterDiscount = 0;

      let _actualDiscountAmount = 0;


      const _recommendation = Object.assign({}, _recommData);
      let recommendationDetail = Object.assign({}, _recommendation.detail);
      const { discount, discountType } = recommendationDetail;

      if (recommendationDetail.option !== recommendationOption.warranty && recommendationDetail.oKToDo === true) {

         let _total = parseFloat(parseFloat(recommendationDetail.labourCost) * parseFloat(recommendationDetail.allocationJobTime));
         let totalRecommCost = _.isNumber(_total) && !_.isNaN(_total) ? parseFloat(_total) : 0;

         var totalPart = 0;
         let recommendationParts = Object.assign([], recommendationDetail.parts);

         if (!_.isEmpty(recommendationParts)) {
            for (var i = 0; i < recommendationParts.length; i++) {

               if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(recommendationParts[i].quote)) {
                  let _rpQuantity = recommendationParts[i]?.quantity > 0 ? recommendationParts[i].quantity : 1
                  let totalPartCost = parseFloat(recommendationParts[i].partCost * _rpQuantity);

                  if (recommendationParts[i].priceLevelCode) {
                     var discountPrice = parseFloat((totalPartCost * (recommendationParts[i].priceLevelCode)) / 100);

                     let markupAmount = parseFloat(totalPartCost + (discountPrice));

                     totalPartCost = parseFloat(markupAmount);
                  }

                  totalPart += totalPartCost ? totalPartCost : 0;
               }
            }
         }
         _totalLabourCost += parseFloat(totalRecommCost);
         _totalPartsCost += parseFloat(totalPart);
         let _totRecommAMT = parseFloat(totalRecommCost + totalPart);
         _totalCost += _totRecommAMT;

      }

      _subtotalAmt = _totalCost;

      if (discount) {
         if (discountType === discount_type.percentage)
            _actualDiscountAmount = parseFloat((_totalCost * (discount)) / 100);
         else
            _actualDiscountAmount = parseFloat(discount)

         _totalCost -= parseFloat(_actualDiscountAmount);

      }

      _subtotalAfterDiscount = _totalCost;

      // IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
      if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType && _totalCost) {

         _totalTaxAmt = parseFloat((_totalCost * (taxPercentage)) / 100);
         _totalCost += parseFloat(_totalTaxAmt);
      }

      return {
         total: _totalCost,
         labourCost: _totalLabourCost,
         partsCost: _totalPartsCost,
         taxCost: _totalTaxAmt,
         subTotal: _subtotalAmt,
         actualDiscount: _actualDiscountAmount,
         subtotalAfterDiscount: _subtotalAfterDiscount
      }


   }

   getTotalPackageCost = (service, servicePackage) => {
      const { taxType, taxPercentage, serviceType, internalTaxExemption } = service;
      const { discount, discountType } = servicePackage;

      let _totalCost = 0;
      let _totalPackagesCost = 0;
      let _totalTaxAmt = 0;
      let _subtotalAmt = 0;
      let _subtotalAfterDiscount = 0;

      let _actualDiscountAmount = 0;

      if (!_.isEmpty(servicePackage)) {
         _totalPackagesCost = servicePackage.price ? servicePackage.price : 0;
         _totalCost += parseFloat(_totalPackagesCost);
      }

      _subtotalAmt = _totalCost;

      if (discount) {
         if (discountType === discount_type.percentage)
            _actualDiscountAmount = parseFloat((_totalCost * (discount)) / 100);
         else
            _actualDiscountAmount = parseFloat(discount)

         _totalCost -= parseFloat(_actualDiscountAmount);
      }
      _subtotalAfterDiscount = _totalCost;

      // IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
      if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType && _totalCost) {

         _totalTaxAmt = parseFloat((_totalCost * (taxPercentage)) / 100);
         _totalCost += parseFloat(_totalTaxAmt);
      }

      return {
         total: _totalCost,
         packagesCost: _totalPackagesCost,
         taxCost: _totalTaxAmt,
         subTotal: _subtotalAmt,
         actualDiscount: _actualDiscountAmount,
         subtotalAfterDiscount: _subtotalAfterDiscount
      }


   }

   getTotalDeclinedCost = (service, recommendations) => {
      const { taxType, taxPercentage, serviceType, internalTaxExemption, discount, discountType } = service;
      let _recommendationLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required));

      let _totalCost = 0;
      let _totalPartsCost = 0;
      let _totalLabourCost = 0;
      let _totalTaxAmt = 0;
      let _subtotalAmt = 0;

      let _actualDiscountAmount = 0;
      let _actualDiscountRecomm = 0;

      let _actualTotalCost = 0;
      let _actualSubtotalAmt = 0;

      _recommendationLists && _recommendationLists.forEach(_recommData => {

         const _recommendation = Object.assign({}, _recommData);
         let recommendationDetail = Object.assign({}, _recommendation.detail);

         if (recommendationDetail.option !== recommendationOption.warranty && !recommendationDetail.oKToDo) {

            let _total = parseFloat(parseFloat(recommendationDetail.labourCost) * parseFloat(recommendationDetail.allocationJobTime));
            let totalRecommCost = _.isNumber(_total) && !_.isNaN(_total) ? parseFloat(_total) : 0;
            var total = 0;
            let recommendationParts = Object.assign([], recommendationDetail.parts);
            if (!_.isEmpty(recommendationParts)) {
               for (var i = 0; i < recommendationParts.length; i++) {
                  if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(recommendationParts[i].quote)) {
                     let _rpQuantity = recommendationParts[i]?.quantity > 0 ? recommendationParts[i].quantity : 1
                     let totalPartCost = parseFloat(recommendationParts[i].partCost * _rpQuantity);

                     if (recommendationParts[i].priceLevelCode) {
                        var discountPrice = parseFloat((totalPartCost * (recommendationParts[i].priceLevelCode)) / 100);

                        let markupAmount = parseFloat(totalPartCost + (discountPrice));

                        totalPartCost = parseFloat(markupAmount);
                     }

                     total += totalPartCost ? totalPartCost : 0;
                  }
               }
            }
            _totalLabourCost += parseFloat(totalRecommCost);
            _totalPartsCost += parseFloat(total);
            let _totRecommAMT = parseFloat(totalRecommCost + total);
            _totalCost += _totRecommAMT;
            _actualTotalCost += _totRecommAMT;

            if (recommendationDetail.discount) {
               var totalDosc = 0;
               if (recommendationDetail.discountType === discount_type.percentage)
                  totalDosc = parseFloat((_totRecommAMT * (recommendationDetail.discount)) / 100);
               else
                  totalDosc = parseFloat(recommendationDetail.discount)
               _actualDiscountRecomm += parseFloat(totalDosc);
               _totalCost -= parseFloat(totalDosc);
            }

         }
      })


      _subtotalAmt = _totalCost;
      _actualSubtotalAmt = _actualTotalCost;

      if (discount) {
         if (discountType === discount_type.percentage)
            _actualDiscountAmount = parseFloat((_totalCost * (discount)) / 100);
         else
            _actualDiscountAmount = parseFloat(discount)

         _totalCost -= parseFloat(_actualDiscountAmount);
      }

      // IF SERVICE TYPE IS INTERNAL NO GST WILL BE CALCULATED
      if ((serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType && _totalCost) {

         _totalTaxAmt = parseFloat((_totalCost * (taxPercentage)) / 100);
         _totalCost += parseFloat(_totalTaxAmt);
         _actualTotalCost += parseFloat(_totalTaxAmt);
      }

      let _totalDiscount = parseFloat(_actualDiscountAmount + _actualDiscountRecomm)
      return {
         total: _totalCost,
         totalDiscount: _totalDiscount,
         labourCost: _totalLabourCost,
         partsCost: _totalPartsCost,
         taxCost: _totalTaxAmt,
         subTotal: _subtotalAmt,
         actualDiscount: _actualDiscountAmount,
         actualDiscountRecomm: _actualDiscountRecomm,
         actualTotalCost: _actualTotalCost,
         actualSubTotal: _actualSubtotalAmt
      }

   }

   parseServiceInvoiceFromJob = (service, recommendations, servicePackages, _settings) => {
      const { parts } = service;
      let _recommendationLists = Object.assign([], recommendations.filter(item => item.choice === recommendationChoice.Required));
      let _accessories = [];
      /**=========================================================================================================== */
      if (!_.isEmpty(servicePackages)) {

         for (var i = 0; i < servicePackages.length; i++) {
            let totalPackCost = (servicePackages[i].price ? servicePackages[i].price : 0);

            let _accessoriesPack = {
               id: `${new Date().valueOf().toString()}-${i}`,
               item: servicePackages[i]?.name ? servicePackages[i].name : '',
               itemNo: 'Service',
               quantity: 1,
               rate: totalPackCost,
               discount: 0,
               discountType: discount_type.percentage,
               amount: totalPackCost,
               addedDate: window.firebase.firestore.Timestamp.now()
            };

            if (servicePackages[i].discount && _accessoriesPack.amount > 0) {
               let _isPercentage = true;
               let _percentage = servicePackages[i].discount;
               if (servicePackages[i].discountType !== discount_type.percentage) {
                  _percentage = parseFloat(((servicePackages[i].discount) / _accessoriesPack.amount) * 100);
                  _isPercentage = false;
               }
               var totalDosc = parseFloat((_accessoriesPack.amount * (_percentage)) / 100);
               _accessoriesPack.discount = _.round(_percentage, 2);
               _accessoriesPack.amount = _.round(parseFloat(_accessoriesPack.amount - totalDosc), (_isPercentage ? 2 : 0));

            }
            _accessories.push(_accessoriesPack);
         }
      }

      /**=========================================================================================================== */
      if (!_.isEmpty(parts)) {

         let serviceParts = Object.assign([], parts);
         for (var i = 0; i < serviceParts.length; i++) {
            let _accessoriesPart = {
               id: `${new Date().valueOf().toString()}-${i}${i}`,
               item: serviceParts[i]?.partName ? serviceParts[i].partName : '',
               itemNo: serviceParts[i]?.partNumber ? serviceParts[i].partNumber : '',
               quantity: 0,
               rate: 0,
               discount: 0,
               discountType: discount_type.percentage,
               amount: 0,
               addedDate: window.firebase.firestore.Timestamp.now()
            };
            let spCost = serviceParts[i]?.partCost > 0 ? serviceParts[i].partCost : 0
            if (spCost > 0) {
               if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(serviceParts[i].quote)) {
                  let spQuantity = serviceParts[i].quantity > 0 ? serviceParts[i].quantity : 1
                  let totalPartCost = parseFloat(spCost * spQuantity);

                  if (serviceParts[i].priceLevelCode) {
                     var discountPrice = parseFloat((totalPartCost * (serviceParts[i].priceLevelCode)) / 100);

                     let markupAmount = parseFloat(totalPartCost + (discountPrice));

                     totalPartCost = parseFloat(markupAmount);
                  }

                  _accessoriesPart.quantity = spQuantity;
                  _accessoriesPart.rate = parseFloat(totalPartCost / spQuantity);
                  _accessoriesPart.amount = totalPartCost;
               }
               else {
                  _accessoriesPart.rate = spCost;
                  let _quotetext = serviceParts[i].quote === recommPartQuotes.includedInPackage ? ' (included in package)' : ` (included in warranty)`
                  _accessoriesPart.item = _accessoriesPart.item + _quotetext;
               }
            }

            _accessories.push(_accessoriesPart);
         }
      }
      /**=========================================================================================================== */
      _recommendationLists && _.orderBy(_recommendationLists, ["addedDate"], ["asc"]).forEach(_recommData => {

         const _recommendation = Object.assign({}, _recommData);
         let recommendationDetail = Object.assign({}, _recommendation.detail);

         if (recommendationDetail.oKToDo === true) {
            let _accessoriesList = [];
            const _isWarranty = recommendationDetail.option === recommendationOption.warranty ? true : false;
            let _total = parseFloat(parseFloat(recommendationDetail.labourCost) * parseFloat(recommendationDetail.allocationJobTime));
            let totalRecommCost = _.isNumber(_total) && !_.isNaN(_total) ? parseFloat(_total) : 0;

            let _accessoriesRecomm = {
               id: `${_recommendation.documentID}`,
               item: `${_recommendation?.name ? _recommendation.name : 'Labour'} ${_isWarranty ? ' (included in warranty)' : ''}`,
               itemNo: this.getNameByValue(_settings?.categories, _recommendation.category, 'Labour'),
               quantity: _isWarranty ? 0 : 1,
               rate: totalRecommCost,
               discount: 0,
               discountType: discount_type.percentage,
               amount: _isWarranty ? 0 : totalRecommCost,
               addedDate: window.firebase.firestore.Timestamp.now()
            };
            _accessoriesList.push(_accessoriesRecomm);

            var total = 0;
            let recommendationParts = Object.assign([], recommendationDetail.parts);

            if (!_.isEmpty(recommendationParts)) {
               for (var i = 0; i < recommendationParts.length; i++) {
                  let _accessoriesPart = {
                     id: `${_recommendation.documentID}-${i}`,
                     item: recommendationParts[i]?.partName ? recommendationParts[i].partName : '',
                     itemNo: recommendationParts[i]?.partNumber ? recommendationParts[i].partNumber : '',
                     quantity: 0,
                     rate: 0,
                     discount: 0,
                     discountType: discount_type.percentage,
                     amount: 0,
                     addedDate: window.firebase.firestore.Timestamp.now()
                  };
                  let _rpCost = recommendationParts[i]?.partCost > 0 ? recommendationParts[i].partCost : 0
                  if (_rpCost > 0) {
                     if (![recommPartQuotes.warranty, recommPartQuotes.includedInPackage].includes(recommendationParts[i].quote)) {
                        let _rpQuantity = recommendationParts[i]?.quantity > 0 ? recommendationParts[i].quantity : 1
                        let totalPartCost = parseFloat(_rpCost * _rpQuantity);

                        if (recommendationParts[i].priceLevelCode) {
                           var discountPrice = parseFloat((totalPartCost * (recommendationParts[i].priceLevelCode)) / 100);

                           let markupAmount = parseFloat(totalPartCost + (discountPrice));

                           totalPartCost = parseFloat(markupAmount);
                        }

                        total += totalPartCost ? totalPartCost : 0;

                        _accessoriesPart.rate = parseFloat(totalPartCost / _rpQuantity);
                        if (_isWarranty) {
                           _accessoriesPart.item = _accessoriesPart.item + ` (included in warranty)`;
                        }
                        else {
                           _accessoriesPart.quantity = _rpQuantity;
                           _accessoriesPart.amount = totalPartCost;
                        }

                     }
                     else {
                        _accessoriesPart.rate = _rpCost;
                        let _quotetext = recommendationParts[i].quote === recommPartQuotes.includedInPackage ? ' (included in package)' : ` (included in warranty)`
                        _accessoriesPart.item = _accessoriesPart.item + _quotetext;
                     }
                  }

                  _accessoriesList.push(_accessoriesPart);
               }
            }

            let _totRecommAMT = parseFloat(totalRecommCost + total);

            if (recommendationDetail.discount) {
               _accessoriesList.forEach(_data => {
                  if (_data.amount > 0) {
                     let _isPercentage = true;
                     let _percentage = recommendationDetail.discount;
                     if (recommendationDetail.discountType !== discount_type.percentage) {
                        _percentage = parseFloat(((recommendationDetail.discount) / _totRecommAMT) * 100);
                        _isPercentage = false;
                     }
                     var totalDosc = parseFloat((_data.amount * (_percentage)) / 100);
                     _data.discount = _.round(_percentage, 2);
                     _data.amount = _.round(parseFloat(_data.amount - totalDosc), (_isPercentage ? 2 : 0));
                  }
               })
            }
            _accessories = _.union(_accessories, _accessoriesList)
         }
      })
      /**=========================================================================================================== */
      //console.log('_accessories', _accessories)
      return _accessories;

   }

   getActivityType = (_type, _origin) => {
      let _modules = ['tradeinPro', 'service']
      let response = _type;
      if (['log', 'activity'].includes(_type))
         response = (_origin ? (['enquiry'].includes(_origin) ? _type : `${_origin}${_type}`) :
            (_modules.includes(localStorage.defaultModule) ? `${localStorage.defaultModule}${_type}` : _type))

      return response;
   }

   getVehicleIcons = (_type, _ico, isVehicleDynamic, _icoVehicle) => {
      let _categories = this.getDynamicCategories();
      if (_ico && _ico.includes('icon-open-leads'))
         return _ico;
      else if (isVehicleDynamic)
         return _icoVehicle && _ico && _type && _categories.includes(_type) && !_ico.includes(_type.toLowerCase()) ? `${_ico}-${_type.toLowerCase()}` : _ico
      else
         return _ico && _type && _categories.includes(_type) && !_ico.includes(_type.toLowerCase()) ? `${_ico}-${_type.toLowerCase()}` : _ico

   }

   getNoVehicleIcons = (_type, _ico) => {
      let _categories = this.getDynamicCategories();
      return _ico && _type && _categories.includes(_type) ? `${_ico}${_type}` : _ico
   }

   showBrandLogo = (dealersettings, make, _ico) => {
      let _categories = this.getDynamicCategories();
      let _icoDefault = _ico ? _ico : 'novehicle'
      let _type = dealersettings?.client?.category;

      if (!_.isEmpty(dealersettings?.allMakeModels) && make && _type) {
         let _logo = dealersettings.allMakeModels.filter(m => m.category === _type && (m.value.toLowerCase() === make.toLowerCase() || m.name.toLowerCase() === make.toLowerCase()))[0]?.logo;
         return _logo ? _logo : _images[_type && _categories.includes(_type) ? `${_icoDefault}${_type}` : _icoDefault]
      }
      else
         return _images[_type && _categories.includes(_type) ? `${_icoDefault}${_type}` : _icoDefault]
   }

   getDynamicCategories = () => {
      return ['Motorcycle', 'Portables']
   }

   showVehicleText = (_type, _txt, _altTxt) => {
      let _categories = ['Portables']
      return _type && _altTxt && _categories.includes(_type) ? _altTxt : _txt
   }

   showReconditioningCost = (_marks, _currencySymbol) => {
      let marks = Object.assign([], _marks)
      const currencySymbol = _currencySymbol ? _currencySymbol : '$ ';

      if (!_.isEmpty(marks)) {
         var total = 0;
         for (var i = 0; i < marks.length; i++) {
            total = total + (marks[i].price ? marks[i].price : 0);
         }
         return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
      }
      else {
         return <NumberFormat value={0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
      }
   }

   getNameAndModel = (_data) => {

      let _cName = this.displayContactName(null, _data.contact, '')

      return `${_cName}'s ${_data && _data.tradein && _data.tradein.make ? (_data.tradein.make + ' ' + _data.tradein.model) : ''}`

   }

   saveContactOriginAndCampaign = (contactID, campaign, origin, props) => {
      if (contactID && (campaign || origin)) {
         const objData = Object.assign({}, {
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web'
         });
         if (campaign) objData.campaign = campaign;
         if (origin) objData.origin = origin;
         const updateRef = firestoreDB(props.dealersettings).firestore().collection('contactDetails').doc(contactID);
         updateRef.set(objData, { merge: true }).then((docRef) => {
         }).catch((error) => {
            console.error("Error updating contact_details: ", error);
         });
      }

   }

   checkServiceROExist = async (dealersettings, clientID, docID, roNumber) => {
      let exists = false;
      if (!_.isEmpty(roNumber) && clientID && docID) {
         let checkRONum = firestoreDB(dealersettings).firestore().collection('serviceJobs')
            .where('clientID', '==', clientID)
            .where('roNumber', '==', roNumber)
            .where('isDeleted', '==', false).limit(2).get()
            .then(snapshot => {
               if (snapshot.size >= 1 && !docID) {
                  exists = true;
                  return exists;
               } else if (snapshot.size >= 1 && docID) {
                  snapshot.forEach(doc => {
                     if ((snapshot.size > 1 && docID !== doc.id) || (snapshot.size === 1 && docID !== doc.id)) {
                        exists = true;
                     }
                  });
                  return exists;
               }
               return exists;
            });

         const [checkQuery] = await Promise.all([checkRONum]);
         return checkQuery;
      }
      else return false;

   }

   isValidActivityNotesCheck = (objLog, isDone, dealersettings) => {
      let _isValid = true;
      const mandActivityNotes = ((!_.isEmpty(dealersettings) &&
         !_.isEmpty(dealersettings.client) &&
         !_.isEmpty(dealersettings.client.clientSettings) &&
         dealersettings.client.clientSettings.mandActivityNotes) ? true : false);

      if (mandActivityNotes && isDone === true && _.isEmpty(objLog.notes)) {
         _isValid = false;
      }

      return _isValid
   }


   isValidSoldRequirements = (_soldRequirements, mandatoryFields) => {

      let formIsValid = true;
      if (!_.isEmpty(mandatoryFields)) {

         let _requiredFields = _.map(mandatoryFields, function (a) {
            return a === 'chassisNo' ? 'vinNo' : a === 'warrantyExpiry' ? 'warrantyExp' : a
         });

         _soldRequirements && _soldRequirements.filter(e => e.isSold === true).forEach(fieldsDetail => {

            if (!_.isEmpty(fieldsDetail.stock) && fieldsDetail.stock.make)
               fieldsDetail = Object.assign({}, fieldsDetail.stock);

            _requiredFields && _requiredFields.forEach((key) => {
               if ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !_.isNumber(fieldsDetail[key]) && !fieldsDetail[key])) {
                  formIsValid = false;
                  return formIsValid;
               }
            });

         });
      }

      return formIsValid;
   }

   savePrimaryFieldsToEnquiry = (enquiry, enquiryID, addDisplayID, dealersettings) => {

      let _enquiryID = enquiryID ? enquiryID : enquiry.documentID;
      if (_enquiryID && !_.isEmpty(enquiry) && (!_.isEmpty(enquiry.requirement) && (!_.isEmpty(enquiry.requirement.make) || !_.isEmpty(enquiry.requirement.stock)))) {
         let state = Object.assign({
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web'
         });

         if (addDisplayID && enquiry.displayID)
            state.displayID = enquiry.displayID;

         let stock = enquiry.requirement;
         if (!_.isEmpty(enquiry.requirement.stock))
            stock = enquiry.requirement.stock;

         let _vinNo = stock && stock.vinNo ? stock.vinNo : (enquiry.requirement.vinNo ? enquiry.requirement.vinNo : null);
         let _regNo = stock && stock.regNo ? stock.regNo : (enquiry.requirement.regNo ? enquiry.requirement.regNo : null);

         if (!enquiry.chassisNo && _vinNo)
            state.chassisNo = _vinNo;

         if (!enquiry.regNo && _regNo)
            state.regNo = _regNo;

         if (!enquiry.stockNo && stock && stock.stockNo)
            state.stockNo = stock.stockNo;

         if (!enquiry.warrantyExpiry && stock && stock.warrantyExp)
            state.warrantyExpiry = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD');

         if (!enquiry.warrantyStartDate && stock && stock.warrantyStartDate)
            state.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD');

         const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(_enquiryID);
         updateRef.set(state, { merge: true }).then((docRef) => {
         }).catch((error) => {
            console.error("Error updating enquiries: ", error);
         });

      }
   }

   removeBatchNumberFromEnquiry = (enquiry, _enquiryOptionsDF, dealersettings) => {
      let _dynamicFields = Object.assign({}, enquiry?.dynamicFields);
      if (!_.isEmpty(_dynamicFields) && !_.isEmpty(_enquiryOptionsDF) && _.find(_enquiryOptionsDF, { key: 'batchNumber', active: true })) {
         let _dynamicFieldOptions = _enquiryOptionsDF.filter(e => e.key === 'batchNumber' && e.active === true);
         let state = Object.assign({
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web'
         });

         _dynamicFieldOptions && _dynamicFieldOptions.forEach(item => {
            if (_dynamicFields.hasOwnProperty(item.value)) {
               _dynamicFields[item.value] = null;
               state.stockNo = null;
            }
         })
         state.dynamicFields = _dynamicFields;

         const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(enquiry.documentID);
         updateRef.set(state, { merge: true }).then((docRef) => {
         }).catch((error) => {
            console.error("Error updating enquiries: ", error);
         });
      }
   }

   removeBatchNumberOnLost = (state, enquiry, _enquiryOptionsDF) => {
      let _dynamicFields = Object.assign({}, enquiry?.dynamicFields);
      if (!_.isEmpty(_dynamicFields) && !_.isEmpty(_enquiryOptionsDF) && _.find(_enquiryOptionsDF, { key: 'batchNumber', active: true })) {
         let _dynamicFieldOptions = _enquiryOptionsDF.filter(e => e.key === 'batchNumber' && e.active === true);

         _dynamicFieldOptions && _dynamicFieldOptions.forEach(item => {
            if (_dynamicFields.hasOwnProperty(item.value)) {
               _dynamicFields[item.value] = null;
               state.stockNo = null;
            }
         })
         state.dynamicFields = _dynamicFields;
      }
      return state;
   }

   generateExcelData = (_enquiries, csvHeader, props, options) => {
      let _headers = [];
      let csvStyle = this.getCSVStyle();
      _headers = csvHeader.map(rec => {
         return {
            ...rec,
            title: (!_.isEmpty(options) && _.isObject(options)) ?
               this.getStaticFieldName(options, rec.key, rec.label, props) :
               this.showLocale(props, rec.key, rec.label),
            dynValue: rec.key,
            dynWidth: 10,
            ...csvStyle.header
         }
      })
      var exportData = []
      _enquiries.forEach(rec => {
         let obj = rec;
         let arrCols = []
         _headers.forEach(col => {
            arrCols.push({ value: `${obj[col.dynValue] ? obj[col.dynValue] : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
         })
         exportData.push(arrCols)
      })
      _headers.forEach(rec => {
         var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
         _headers = _headers.map(h => {
            return (h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth) ? {
               ...h,
               'width': { wch: w.dynWidth }
            } : h.width ? { ...h } : {
               ...h,
               'width': { wch: h.dynWidth }
            }
         })
      })
      return [{
         columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
         data: [...exportData]
      }];
   }

   checkRepeatCustomer = (contactID, _enquiries) => {
      if (_enquiries.filter(a => a.contactID === contactID && (a.statusValue === 'won' || a.statusValue === 'delivered')).length > 1)
         return true;

      return false;
   }

   displayModelName = (data, skipType) => {
      if (data.name && !skipType && data.type && data.name.indexOf(data.type) >= 0)
         return data.name
      else if (data.name)
         return data.name + ' ' + ((data.type && !skipType) ? data.type : '')
      else if (data.label)
         return data.label
      else
         return data.value ? data.value : ''
   }

   saveLoanAppointment = async (_objFleetData, type, dealersettings) => {
      const batch = firestoreDB(dealersettings).firestore().batch();
      const { serviceID, appointmentID, enquiryID, startDate, expectedReturn, endDate, modifiedDate, modifiedBy, modifiedFrom, documentID, stockID, clientID, owner } = _objFleetData;
      let _appID = appointmentID ? appointmentID : window.firebase.firestore().collection('loanAppointments').doc().id;
      let _owner = owner ? owner : modifiedBy;

      let objData = {
         startDate: startDate,
         expectedReturn: expectedReturn ? expectedReturn : endDate,
         modifiedBy: modifiedBy,
         modifiedDate: modifiedDate,
         modifiedFrom: modifiedFrom,
         enquiryID: enquiryID ? enquiryID : null,
      }
      if (objData.startDate && objData.expectedReturn) {

         if (_.isEmpty(appointmentID)) {
            objData.documentID = _appID;
            objData.recordID = documentID;
            objData.stockID = stockID;
            objData.clientID = clientID;
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.type = type ? type : 'loan';
            objData.addedBy = _owner;
            objData.addedDate = modifiedDate;
            objData.addedFrom = 'web';

            if (type == 'loan') {
               var ref = firestoreDB(dealersettings).firestore().collection('fleet').doc(documentID);
               batch.update(ref, { "appointmentID": objData.documentID });
            } else if (type == 'testdrives') {
               var ref = firestoreDB(dealersettings).firestore().collection('testdrives').doc(documentID);
               batch.update(ref, { "appointmentID": objData.documentID });
            } else if (type == 'activities') {
               var ref = firestoreDB(dealersettings).firestore().collection('activities').doc(documentID);
               batch.update(ref, { "appointmentID": objData.documentID });
            } else if (type == 'serviceLoan') {
               var ref = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(documentID);
               batch.update(ref, { "loanAppointmentID": null });
            }

         }

         if (serviceID) {
            var ref = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(serviceID);
            batch.update(ref, { "isLoan": true });
         }

         //Remove empty value from object
         for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
               delete objData[propName];
            }
         }

         batch.set(firestoreDB(dealersettings).firestore().doc(`loanAppointments/${_appID}`), objData, { merge: true });

         batch.commit();
      }

   }

   saveLastServiceOnCompleted = async (serviceJob, props) => {
      let _contactID = serviceJob.contactID ? serviceJob.contactID : (serviceJob.contact ? serviceJob.contact.documentID : null);
      let _requirement = Object.assign({}, serviceJob.vehicle)

      if (_contactID &&
         _requirement && (_requirement.vinNo || _requirement.rego)) {

         const ref = firestoreDB(props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`)
         const snapshots = await ref.get()
         if (snapshots.size > 0) {
            let ownedVehicles = []
            snapshots.docs.forEach((doc) => {
               ownedVehicles.push({ ...doc.data(), documentID: doc.id })
            });

            let isExistsData = ownedVehicles && ownedVehicles.filter(m =>
               (_requirement.vinNo ? m.vinNo === _requirement.vinNo : false) ||
               (_requirement.rego ? m.regNo === _requirement.rego : false))[0];

            if (isExistsData) {
               const _objData = Object.assign({});
               _objData.lastService = window.firebase.firestore.Timestamp.now();
               _objData.modifiedBy = localStorage.uid;
               _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
               _objData.modifiedFrom = 'web';
               _objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

               firestoreDB(props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(isExistsData.documentID).set(_objData, { merge: true });
            }

         }
      }
   }

   updateCustomerOwnedVehicle = async (_contactID, _vinNo, _regNo, _currentlyOwned, props) => {
      if (_contactID && (_vinNo || _regNo)) {
         const ref = firestoreDB(props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`)
         const snapshots = await ref.get()
         if (snapshots.size > 0) {
            let ownedVehicles = []
            snapshots.docs.forEach((doc) => {
               ownedVehicles.push({ ...doc.data(), documentID: doc.id })
            });

            let isExistsData = ownedVehicles && ownedVehicles.filter(m =>
               (_vinNo ? m.vinNo === _vinNo : false) ||
               (_regNo ? m.regNo === _regNo : false))[0];

            if (isExistsData) {
               const _objData = Object.assign({});

               if (_.isBoolean(_currentlyOwned)) _objData.currentlyOwned = _currentlyOwned;
               //_objData.lastService = window.firebase.firestore.Timestamp.now();
               _objData.modifiedBy = localStorage.uid;
               _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
               _objData.modifiedFrom = 'web';
               _objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

               firestoreDB(props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(isExistsData.documentID).set(_objData, { merge: true });
            }

         }
      }
   }

   deleteLoanAppointments = async (recordID, type, dealersettings) => {
      if (!recordID) return;

      const batch = firestoreDB(dealersettings).firestore().batch();

      if (type == 'serviceLoan') {
         var ref = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(recordID);
         batch.update(ref, { "loanAppointmentID": null });
      } else if (type == 'loan') {
         var ref = firestoreDB(dealersettings).firestore().collection('fleet').doc(recordID);
         batch.update(ref, { "appointmentID": null });
      } else if (type == 'testdrives') {
         var ref = firestoreDB(dealersettings).firestore().collection('testdrives').doc(recordID);
         batch.update(ref, { "appointmentID": null });
      } else if (type == 'activities') {
         var ref = firestoreDB(dealersettings).firestore().collection('activities').doc(recordID);
         batch.update(ref, { "appointmentID": null });
      }

      await firestoreDB(dealersettings).firestore().collection(`loanAppointments`)
         .where("recordID", '==', recordID)
         .get().then(response => {
            response.docs.forEach((doc) => {
               batch.delete(doc.ref);
            });
         });

      batch.commit();
   }

   checkAppointments = async (_bookings, _startDate, _endDate, formIsValid, clientUsers, dealersettings) => {
      try {
         let errorList = _bookings.filter(a => ((a.startDate == null || (a.startDate.seconds <= _endDate.seconds)) &&
            (a.expectedReturn == null || (_startDate.seconds <= a.expectedReturn.seconds)) &&
            (a.startDate == null || a.expectedReturn == null || (a.startDate.seconds <= a.expectedReturn.seconds)) &&
            (_startDate.seconds <= _endDate.seconds)));

         if (!_.isEmpty(errorList) && !_.isEmpty(errorList[0]?.startDate) && !_.isEmpty(errorList[0]?.expectedReturn)) {
            let slotExists = errorList[0];

            let result = await Swal.fire({
               //title: 'Vehicle is Booked',
               html:
                  'Vehicle has a booking from <strong>' +
                  moment.unix(slotExists.startDate.seconds).format('DD/MM/YYYY hh:mm A') +
                  (slotExists.expectedReturn ? ('</strong> to <strong>' + moment.unix(slotExists.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A')) : '') +
                  '</strong> by <strong>' + this.getUserNamebyId(clientUsers, slotExists.addedBy) + '</strong>',
               icon: 'info',
               showCancelButton: true,
               confirmButtonText: this.showLocale({ dealersettings }, 'Continue, anyway.'),
               cancelButtonText: this.showLocale({ dealersettings }, 'Cancel'),
            })

            if (result.value) {
               formIsValid = true;
               return formIsValid;
            }
            else {
               formIsValid = false;
               return formIsValid;
            }
         }
         else {
            return formIsValid;
         }
      }
      catch (error) {
         console.log('checkAppointments', error);
         return false;
      }


   }

   toDataURL = async (url) => await fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
         const reader = new FileReader()
         reader.onloadend = () => resolve(reader.result)
         reader.onerror = reject
         reader.readAsDataURL(blob)
      }))

   imageToBase64 = async (id, URL) => {
      await this.toDataURL(URL)
         .then(dataUrl => {
            //console.log('imageToBase64:', dataUrl)
            return dataUrl;
         }).catch((error) => {
            //console.error("Error updating enquiries: ", error);
            return URL;
         });
   }

   arrLimit = (arr, maxCount) => {
      var results = [];
      _.forEach(arr, function (rec) {
         if (results.length === maxCount) {
            return false;
         }
         results.push(rec);
      });
      return results;
   }

   activityLogOptions = (dealersettings, alternateOptions, isIcon) => {
      const setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
      if (setttings) {

         const _activityTypes = [];
         setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
            _activityTypes.push(
               {
                  label: isIcon ? (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>) : doc.name,
                  name: doc.name,
                  value: doc.value,
                  active: true,
               });
         });

         return Object.assign([], !_.isEmpty(_activityTypes) ? _activityTypes : alternateOptions);
      }
      else
         return Object.assign([], alternateOptions)
   }

   activityIconByValue = (dealersettings, _value) => {
      //console.log('activityIconByValue', dealersettings, '==', _value)
      var alternate = _value === 'call' ? 'icon-' + _value : _value === 'email' ? 'icon-Mail' : _value === 'task' ? 'icon-activity' : 'icon-activity-' + _value
      const setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
      const activityTypes = setttings && setttings.activityTypes;
      let obJArray = activityTypes ? activityTypes : activityLogOptions;

      if (!_.isEmpty(obJArray) && _value && obJArray.filter(e => e.value === _value).length > 0) {
         var icon = obJArray.filter(e => e.value === _value)[0].icon
         return (icon ? `icon-${icon}` : alternate)
      }
      else
         return alternate

   }

   handleFileDownload = (url) => {
      return new Promise(function (resolve, reject) {
         let xhr = new XMLHttpRequest();
         xhr.open('GET', url);
         xhr.responseType = 'blob';
         xhr.onload = (event) => {
            const blob = xhr.response;
            //console.log('blob file', blob)
            const bloburl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = bloburl;
            a.download = `download_${moment().format('DD_MM_YYYY_HH_mm')}`;
            const clickHandler = () => {
               setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
               }, 150);
            };
            a.addEventListener('click', clickHandler, false);
            a.click();
            resolve({
               success: true
            });
         };
         xhr.onerror = function () {
            window.open(url, '_blank');
            reject({
               success: false,
               message: xhr.statusText
            });
         };
         xhr.send();
      });
   }

   // handleFileDownload = async url => {
   //    try {
   //       const xhr = new XMLHttpRequest();
   //       xhr.responseType = 'blob';
   //       xhr.onload = (event) => {
   //          const blob = xhr.response;
   //          console.log('blob file', blob)
   //          const bloburl = URL.createObjectURL(blob);
   //          const a = document.createElement('a');
   //          a.href = bloburl;
   //          a.download = `download_${moment().format('DD_MM_YYYY_HH_mm')}`;
   //          const clickHandler = () => {
   //             setTimeout(() => {
   //                URL.revokeObjectURL(url);
   //                a.removeEventListener('click', clickHandler);
   //             }, 150);
   //          };
   //          a.addEventListener('click', clickHandler, false);
   //          a.click();

   //       };
   //       xhr.open('GET', url);
   //       xhr.send();

   //    }
   //    catch (error) {
   //       console.log(error);
   //       window.open(url, '_blank');
   //    }

   // }

   removeUnicodeChars = (str) => {
      const val = str ? str.replace(/[^a-zA-Z0-9 ]/g, "") : '';
      return val?.substr(0, 30) || '';
   };

   clearlocalFilter = () => {
      localStorage.removeItem('pipelineFilter');
      localStorage.removeItem('contactFilter');
      localStorage.removeItem('activityFilter');
      localStorage.removeItem('eventFilter');
      localStorage.removeItem('logsFilter');
      localStorage.removeItem('companyFilter');
      localStorage.removeItem('inBoundFilter');
      localStorage.removeItem('oemStockFilter');
      //localStorage.removeItem('allocationFilter');
      localStorage.removeItem('servicePipelineFilter');
      localStorage.removeItem('testDriveFilter');
      localStorage.removeItem('eventSearchText');
      localStorage.removeItem('enquirySearchText');
      localStorage.removeItem('inBoundSearchText');
      //localStorage.removeItem('allocationSearchText');
      localStorage.removeItem('campaignContactSearchText');
      localStorage.removeItem('cafeHistorySearchText');
      localStorage.removeItem('logSearchText');
      localStorage.removeItem('contactSearchText');
      localStorage.removeItem('companySearchText');
      localStorage.removeItem('fleetSearchText');
      localStorage.removeItem('oemStockSearchText');
      localStorage.removeItem('serviceSearchText');
      localStorage.removeItem('stockSearchText');
      localStorage.removeItem('tdHistorySearchText');
   }

   getCurrentPreviousQuarter = (value) => {
      var today = new Date(),
         quarter = Math.floor((today.getMonth() / 3)),
         startDate,
         endDate;

      switch (value) {
         case "previous":
            startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
            break;
         default:
            startDate = new Date(today.getFullYear(), quarter * 3, 1);
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
            break;
      }

      return {
         startDate: moment(startDate).format('YYYY-MM-DD'),
         endDate: moment(endDate).format('YYYY-MM-DD')
      };
   }

   getDomain = (url, subdomain) => {
      subdomain = subdomain || false;

      url = url.replace(/(https?:\/\/)?(www.)?/i, '');

      if (!subdomain) {
         url = url.split('.');

         url = url.slice(url.length - 2).join('.');
      }

      if (url.indexOf('/') !== -1) {
         return url.split('/')[0];
      }

      return url;
   }

   getReportColumns = (props) => {
      let _leadSource = []
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
            .forEach(rec => {
               _leadSource.push({
                  label: rec.name,
                  value: `enqtype-${rec.value}`,
                  name: rec.name,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: '#4967CC',
                  sourceType: 'Enquiry Type',
               })
            })
      if (props.report.pipeline
         && props.report.pipeline.length === 1
         && props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.pipelines
         && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
         props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
            if (rec.value !== 'InboundLead') {
               _leadSource.push({
                  label: `${rec.name} (Stage)`,
                  searchlabel: `${rec.name} Stage`,
                  value: `stage-${rec.value}`,
                  name: `${rec.name} (Stage)`,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: '#4967CC',
                  sourceType: 'Stage',
               })
            }
         })
      }
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityOutcome &&
         props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
            .forEach(rec => {
               _leadSource.push({
                  label: rec.name,
                  value: `outcome-${rec.value}`,
                  name: `${rec.name} (Activity Outcome)`,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: '#4967CC',
                  sourceType: 'Activity Outcome',
                  type: 'activity'
               })
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
            .forEach(rec => {
               _leadSource.push({
                  label: rec.name,
                  value: `callstatus-${rec.value}`,
                  name: rec.name,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: '#4967CC',
                  sourceType: 'Call Status',
                  type: 'log'
               })
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
            .forEach(rec => {
               _leadSource.push({
                  label: rec.name,
                  value: `enqstatus-${rec.value}`,
                  name: rec.name,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: rec.color,
                  sourceType: 'Enquiry Status'
               })
               _leadSource.push({
                  label: `${rec.name} Conv. Ratio`,
                  value: `enqstatus-${rec.value}Conv`,
                  name: `${rec.name} Conv. Ratio`,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: [discount_type.percentage],
                  percentage: true,
                  color: rec.color,
                  sourceType: 'Enquiry Status'
               })
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityTypes &&
         props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
            .forEach(rec => {
               _leadSource.push({
                  label: <>{rec.name}<span className="user-role-span">Activity Type</span></>,
                  searchlabel: `${rec.name} Activity Type`,
                  value: `acttype-${rec.value}`,
                  name: rec.name,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: rec.color,
                  sourceType: 'Activity Type',
                  type: 'activity'
               })
               _leadSource.push({
                  label: <>{rec.name} Done<span className="user-role-span">Activity Type</span></>,
                  searchlabel: `${rec.name} Done Activity Type`,
                  value: `acttypedone-${rec.value}`,
                  name: `${rec.name} Done`,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: rec.color,
                  sourceType: 'Activity Type',
                  type: 'activity'
               })
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.contactTypes &&
         props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
            .forEach(rec => {
               _leadSource.push({
                  label: <>{rec.name}<span className="user-role-span">Contact Type</span></>,
                  searchlabel: `${rec.name} Contact Type`,
                  value: `conttype-${rec.value}`,
                  name: rec.name,
                  view: ['table', 'column', 'pie', 'target'],
                  calculationType: ['count'],
                  color: '#4967CC',
                  sourceType: 'Contact Type'
               })
            })

      return _leadSource;
   }

   getLeadsByEnquiryTypeTotal = (props, _progressReport, isInbound) => {
      let leadSourceData = {};
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`enqtype-${rec.value}`]: _.sumBy(_progressReport, `enqtype-${rec.value}`)
               }
            })

      if (props.report.pipeline
         && props.report.pipeline.length === 1
         && props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.pipelines
         && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
         let _leadSource = []
         let _sourceFields = []
         props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
            if (rec.value !== 'InboundLead') {
               leadSourceData = {
                  ...leadSourceData,
                  [`stage-${rec.value}`]: _.sumBy(_progressReport, `stage-${rec.value}`)
               }
            }
         })
      }

      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`lostReason-${rec.value}`]: _.sumBy(_progressReport, `lostReason-${rec.value}`)
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityOutcome &&
         props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`outcome-${rec.value}`]: _.sumBy(_progressReport, `outcome-${rec.value}`)
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`callstatus-${rec.value}`]: _.sumBy(_progressReport, `callstatus-${rec.value}`)
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
            .forEach(rec => {
               let _stsCount = _.sumBy(_progressReport, `enqstatus-${rec.value}`);
               leadSourceData = {
                  ...leadSourceData,
                  [`enqstatus-${rec.value}`]: _stsCount,
                  [`enqstatus-${rec.value}Conv`]: `${(isInbound ? _.sumBy(_progressReport, `convertedLeads`) : _.sumBy(_progressReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(_progressReport, `convertedLeads`) : _.sumBy(_progressReport, `total`))) * _stsCount) : 0}%`,
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityTypes &&
         props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`acttype-${rec.value}`]: _.sumBy(_progressReport, `acttype-${rec.value}`),
                  [`acttypedone-${rec.value}`]: _.sumBy(_progressReport, `acttypedone-${rec.value}`),
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.contactTypes &&
         props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`conttype-${rec.value}`]: _.sumBy(_progressReport, `conttype-${rec.value}`)
               }
            })
      return leadSourceData;
   }

   getDynamicTotalLength = (props, data, isInbound) => {
      let leadSourceData = {};
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`enqtype-${rec.value}`]: data[`arrenqtype-${rec.value}`] ? data[`arrenqtype-${rec.value}`].length : 0
               }
            })
      if (props.report.pipeline
         && props.report.pipeline.length === 1
         && props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.pipelines
         && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
         let _leadSource = []
         let _sourceFields = []
         props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
            if (rec.value !== 'InboundLead') {
               leadSourceData = {
                  ...leadSourceData,
                  [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
               }
            }
         })
      }
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`lostReason-${rec.value}`]: data[`arrlostReason-${rec.value}`] ? data[`arrlostReason-${rec.value}`].length : 0
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityOutcome &&
         props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`outcome-${rec.value}`]: data[`arroutcome-${rec.value}`] ? data[`arroutcome-${rec.value}`].length : 0
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`callstatus-${rec.value}`]: data[`arrcallstatus-${rec.value}`] ? data[`arrcallstatus-${rec.value}`].length : 0
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
            .forEach(rec => {
               let _stsCount = data[`arrenqstatus-${rec.value}`] ? _.sumBy(data[`arrenqstatus-${rec.value}`], "soldCount") : 0;
               let _totalCount = data[`arrtotal`] ? data[`arrtotal`].length : 0;
               let _convertedCount = data[`arrconvertedLeads`] ? data[`arrconvertedLeads`].length : 0;
               leadSourceData = {
                  ...leadSourceData,
                  [`arrenqstatus-${rec.value}`]: data[`arrenqstatus-${rec.value}`] ? _.uniq(data[`arrenqstatus-${rec.value}`].map(r => { return r.documentID })) : [],
                  [`enqstatus-${rec.value}`]: _stsCount,
                  [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.activityTypes &&
         props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && !Boolean(a.default))
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`acttype-${rec.value}`]: data[`arracttype-${rec.value}`] ? data[`arracttype-${rec.value}`].length : 0,
                  [`acttypedone-${rec.value}`]: data[`arracttypedone-${rec.value}`] ? data[`arracttypedone-${rec.value}`].length : 0,
               }
            })
      props.dealersettings &&
         props.dealersettings.client &&
         props.dealersettings.client.settings &&
         props.dealersettings.client.settings.contactTypes &&
         props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
            .forEach(rec => {
               leadSourceData = {
                  ...leadSourceData,
                  [`conttype-${rec.value}`]: data[`arrconttype-${rec.value}`] ? data[`arrconttype-${rec.value}`].length : 0
               }
            })
      return leadSourceData;
   }

   loadNewEnquiry = (dealersettings, contact, history) => {

      if (dealersettings &&
         dealersettings.client &&
         dealersettings.client.settings) {

         const setttings = dealersettings.client.settings;

         let state = Object.assign({}, objNewEnquiryVM);
         if (_.isEmpty(state.addedBy)) {
            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = window.firebase.firestore.Timestamp.now();
            state.owner = dealersettings ? dealersettings.id : '';
         }
         state.modifiedBy = dealersettings ? dealersettings.id : '';
         state.modifiedDate = window.firebase.firestore.Timestamp.now();
         state = this.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings)

         if (!_.isEmpty(contact)) {
            let objContactData = Object.assign({}, contact);
            //Remove empty value from object
            for (var propName in objContactData) {
               if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                  delete objContactData[propName];
               }
            }
            state.isContact = true;
            state.contact = objContactData;
            state.contactID = objContactData.documentID;
         }

         if (setttings.pipelines.length === 1 && setttings.pipelines.some(item => item.value === 'LeadsBucket')) {

            let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
            let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
            let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

            state.pipeline = _pipeline.value;
            state.stage = _stages[0];
            state.status = null;
            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            //state.statusHistory = Object.assign([], [{ date: state.stageDate, status: state.status, user: localStorage.uid }]);
            state.ownerInbound = localStorage.uid;
         }
         else {
            let _pipeline = Object.assign({}, setttings.pipelines.filter(i => i.value !== 'LeadsBucket' && i.default === true)[0]);
            if (_.isEmpty(_pipeline))
               _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value !== 'LeadsBucket')[0]);
            if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline)) {
               _pipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline);
            }
            let defaultstages = _pipeline?.stages;
            let defaultstatus = _pipeline?.status;
            let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

            state.pipeline = _pipeline.value;
            state.stage = stages[0];
            state.status = !_.isEmpty(defaultstatus) ? defaultstatus[0] : 'open';
            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

         }

         state.isDeleted = false;
         state.isNewEnquiry = true;

         const objData = Object.assign({}, state);
         //Remove empty value from object
         for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
               if (!['origin'].includes(propName)) delete objData[propName];
            }
         }

         if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

         const refReqData = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
         refReqData.set(objData, { merge: true }).then((docRef) => {

            history.push("/enquiry/details/" + objData.documentID);

         }).catch((error) => {
            console.error("Error adding enquiries: ", error);
         });


      }

   }

   loadNewService = (dealersettings, contact, history, _status) => {

      if (dealersettings &&
         dealersettings.client &&
         dealersettings.client.services) {

         const _clientService = (!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            !_.isEmpty(dealersettings.client.clientSettings.service)) ? dealersettings.client.clientSettings.service : null;

         const _taxType = (!_.isEmpty(_clientService) && _clientService.taxType) ? _clientService.taxType : null;
         const _taxPercentage = (!_.isEmpty(_clientService) && _clientService.tax) ? _clientService.tax : 0;
         const _internalTaxExemption = (!_.isEmpty(_clientService) && _clientService.internalTaxExemption) ? true : false;

         const setttings = dealersettings.client.services;

         let state = Object.assign({}, objService);
         if (_.isEmpty(state.addedBy)) {
            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = window.firebase.firestore.Timestamp.now();
            state.owner = dealersettings ? dealersettings.id : '';
         }
         state.modifiedBy = dealersettings ? dealersettings.id : '';
         state.modifiedDate = window.firebase.firestore.Timestamp.now();
         //state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings)
         state.clientID = dealersettings ? dealersettings.client.id : '';
         state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
         state.appointmentDate = moment().format('YYYY-MM-DD HH:mm:ss');
         state.internalTaxExemption = _internalTaxExemption;

         if (!_.isEmpty(contact)) {
            let objContactData = Object.assign({}, contact);
            //Remove empty value from object
            for (var propName in objContactData) {
               if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                  delete objContactData[propName];
               }
            }
            state.isContact = true;
            state.contact = objContactData;
            state.contactID = objContactData.documentID;
         }

         if (_taxType && _taxPercentage) {
            state.taxType = _taxType;
            state.taxPercentage = _taxPercentage;
         }

         if (_status) {
            state.status = _status;
            if (_status !== serviceStatus.NEWSERVICE && !_.isEmpty(setttings)) {

               let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
               if (_.isEmpty(_selPipeline))
                  _selPipeline = Object.assign({}, setttings.pipelines[0]);
               if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline)) {
                  _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline);
               }

               let statusSettings = _selPipeline && _selPipeline.statusSettings;

               let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
               if (!_.isEmpty(data) && data.pipeline && data.stage) {
                  state.pipeline = data.pipeline;
                  state.stage = data.stage;
               }
               else {
                  let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
                  state.pipeline = _selPipeline.value;
                  state.stage = _stages[0];
               }

               state.stageDate = window.firebase.firestore.Timestamp.now();
               state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
               state.statusHistory = Object.assign([], [{ date: state.stageDate, status: state.status, user: localStorage.uid }]);

            }
         }
         else {
            state.status = serviceStatus.NEWSERVICE;
            state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);
         }


         if (state.status === serviceStatus.CHECKIN) {
            state.checkInBy = dealersettings ? dealersettings.id : '';
            state.checkInDate = window.firebase.firestore.Timestamp.now();

         }

         state.isDeleted = false;

         const objData = Object.assign({}, state);
         //Remove empty value from object
         for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
               delete objData[propName];
            }
         }

         if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;

         const refReqData = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(objData.documentID);
         refReqData.set(objData, { merge: true }).then((docRef) => {
            let documentID = objData.documentID;

            const _client = objData.clientID && !_.isEmpty(dealersettings) &&
               !_.isEmpty(dealersettings.group) &&
               !_.isEmpty(dealersettings.group.clients) &&
               dealersettings.group.clients[objData.clientID] ? dealersettings.group.clients[objData.clientID] : dealersettings.client;

            if (!_.isEmpty(_client) && !_.isEmpty(_client.services) && !_.isEmpty(_client.services.recommendations)) {

               const labourCost = (!_.isEmpty(_clientService) && _clientService.labourCost) ? _clientService.labourCost : 0;
               const _categoriesList = Object.assign([], setttings && setttings.categories);
               const batch = firestoreDB(dealersettings).firestore().batch();
               _client.services.recommendations.filter(a => a.active === true).forEach(data => {
                  //data.name = data.title ? data.title : data.name;
                  if ((data.type === 'checklist' && _categoriesList.some(e => e.value === data.category && e.active === true)) || data.type !== 'checklist') {
                     if (data.title) {
                        data.name = data.title;
                        delete data['title'];
                     }
                     data.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
                     data.serviceID = documentID;
                     if (objData.clientID) data.clientID = objData.clientID;
                     if (objData.projectId) data.projectId = objData.projectId;
                     data.detail = Object.assign({}, {
                        option: 'customer',
                        status: 'urgent',
                        labourCost: labourCost ? labourCost : 0,
                        oKToDo: true,
                     })
                     batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${documentID}/recommendations/${data.documentID}`), data, { merge: true });

                  }
               })
               batch.commit().then(snapshot => {
                  history.push("/service/details/" + documentID);
               }).catch((error) => {
                  console.error("Error updating document: ", error);
               });
            }
            else {
               history.push("/service/details/" + documentID);
            }

         }).catch((error) => {
            console.error("Error adding enquiries: ", error);
         });


      }

   }

   updateRecommendationStatus = (dealersettings, recommendations, serviceID) => {

      if (dealersettings && recommendations && serviceID) {

         const batch = firestoreDB(dealersettings).firestore().batch();
         recommendations.filter(a => a.choice === recommendationChoice.Required && a.detail.recomStatus === 'inProgress').forEach(data => {

            data.detail = Object.assign({}, {
               ...data.detail,
               recomStatus: 'completed',
            })
            batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/recommendations/${data.documentID}`), data, { merge: true });

         })
         batch.commit().then(snapshot => {

         }).catch((error) => {
            console.error("Error updating document: ", error);
         });
      }
   }

   loadNewTradeInPro = (dealersettings, contact, history) => {

      if (dealersettings &&
         dealersettings.client &&
         dealersettings.client.tradeinPro) {

         const setttings = dealersettings.client.tradeinPro;

         let state = Object.assign({}, objTradeIP);
         if (_.isEmpty(state.addedBy)) {
            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = window.firebase.firestore.Timestamp.now();
            state.owner = dealersettings ? dealersettings.id : '';
         }
         state.modifiedBy = dealersettings ? dealersettings.id : '';
         state.modifiedDate = window.firebase.firestore.Timestamp.now();
         state.clientID = dealersettings ? dealersettings.client.id : '';
         state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
         state.isDeleted = false;
         state.isNewTradein = true;

         if (!_.isEmpty(contact)) {
            let objContactData = Object.assign({}, contact);
            //Remove empty value from object
            for (var propName in objContactData) {
               if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                  delete objContactData[propName];
               }
            }
            state.isContact = true;
            state.contact = objContactData;
            state.contactID = objContactData.documentID;
         }

         let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
         if (_.isEmpty(_selPipeline))
            _selPipeline = Object.assign({}, setttings.pipelines[0]);
         // if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline)) {
         //    _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline);
         // }

         let defaultstages = _selPipeline.stages;
         let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

         state.pipeline = _selPipeline.value;
         state.stage = stages[0];
         state.status = tipStatus.NEW;
         state.stageDate = window.firebase.firestore.Timestamp.now();
         state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);

         const objData = Object.assign({}, state);
         //Remove empty value from object
         for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
               delete objData[propName];
            }
         }

         if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;

         const refReqData = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(objData.documentID);
         refReqData.set(objData, { merge: true }).then((docRef) => {
            let documentID = objData.documentID;
            history.push("/tradeinpro/details/" + documentID);
         }).catch((error) => {
            console.error("Error adding enquiries: ", error);
         });


      }

   }

   bindAllImages = (images, sidePhotos) => {
      let _allImages = Object.assign([], images)
      !_.isEmpty(sidePhotos) && Object.keys(sidePhotos).forEach(key => {
         if (sidePhotos[key]) _allImages.push(sidePhotos[key])
      })
      return _allImages
   }

   getTradeinProDealers = (dealersettings, isArray, isAll) => {
      let dealerList = !_.isEmpty(dealersettings?.tradeinProClients) ? dealersettings.tradeinProClients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.tradeinPro
         && c.moduleSettings.tradeinPro.enabled === true
         && c.moduleSettings.tradeinPro.active === true
      ).map(r => { return r.documentID }) : [dealersettings?.client?.documentID];
      dealerList = isAll ? dealerList : this.arrLimit(dealerList, 30)
      return isArray ? dealerList : dealerList.join(',')
   }

   getDealLogDealers = (dealersettings) => {
      let dealerList = !_.isEmpty(dealersettings?.clients) ? dealersettings.clients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.dealLog
         && c.moduleSettings.dealLog.enabled === true
         && c.moduleSettings.dealLog.active === true
      ).map(r => { return r }) : [dealersettings?.client];
      return dealerList
   }
   tradeinProClients = (dealersettings) => {
      let tradeinProClients = [];
      dealersettings.tradeinProClients && dealersettings.tradeinProClients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.tradeinPro
         && c.moduleSettings.tradeinPro.enabled === true
         && c.moduleSettings.tradeinPro.active === true
      ).forEach(rec => {
         tradeinProClients.push({
            ...rec,
            label: rec.name,
            value: rec.id,
            data: rec,
            active: true,
         })
      })

      return tradeinProClients;

   }


   //#region EVENTSPRO
   getEventsProDealers = (dealersettings, isArray) => {
      const dealerList = !_.isEmpty(dealersettings?.clients) ? this.arrLimit(dealersettings.clients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.eventsPro
         && c.moduleSettings.eventsPro.enabled === true
         && c.moduleSettings.eventsPro.active === true
      ).map(r => { return r.documentID }), 30) : [dealersettings?.client?.documentID];

      return isArray ? dealerList : dealerList.join(',')
   }

   getAllEventsProDealers = (dealersettings, isArray) => {
      const dealerList = !_.isEmpty(dealersettings?.clients) ? dealersettings.clients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.eventsPro
         && c.moduleSettings.eventsPro.enabled === true
         && c.moduleSettings.eventsPro.active === true
      ).map(r => { return r.documentID }) : [dealersettings?.client?.documentID];

      return isArray ? dealerList : dealerList.join(',')
   }

   getAllEventsProByLevel = (dealersettings, level, isID, isArray) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c =>
            c.moduleSettings
            && c.moduleSettings.eventsPro
            && c.moduleSettings.eventsPro.enabled === true
            && c.moduleSettings.eventsPro.active === true
         ).forEach(rec => {
            _dealers.push({ ...rec, label: rec.name, value: rec.id, data: rec, active: true })
         })
      if (level === 'region') {
         dealersettings.regions && dealersettings.regions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.region === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'subregion') {
         dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'group') {
         dealersettings.groups && dealersettings.groups.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.group === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'individual') {
         _response = _dealers;
      }

      if (isID) {
         if (isArray)
            return _response.map(r => { return r.value })
         else
            return _response.map(r => { return r.value }).join(',')
      } else return _response
   }

   getDataByLevel = (dealersettings, level, levelID, showBy) => {
      let _response = [];
      if (level === 'region' && levelID)
         _response = dealersettings.regions.filter(e => levelID.includes(e.id));
      else if (level === 'subregion' && levelID)
         _response = dealersettings.subregions.filter(e => levelID.includes(e.id));
      else if (level === 'group' && levelID)
         _response = dealersettings.groups.filter(e => levelID.includes(e.id));
      else if ((level === 'individual' || _.isEmpty(level)) && levelID)
         _response = dealersettings.clients.filter(e => levelID.includes(e.id));
      return showBy ? _response.map(r => { return r[showBy] }) : _response;
   }

   getAllEventClientsByLevel = (dealersettings, clientids, level, levelID, isString) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c => c?.moduleSettings?.eventsPro?.enabled === true && c?.moduleSettings?.eventsPro?.active === true).forEach(rec => { _dealers.push(rec) })

      if (level === 'region' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.region)).map(r => { return r.id })
      else if (level === 'subregion' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.subregion)).map(r => { return r.id })
      else if (level === 'group' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.group)).map(r => { return r.id })
      else if (level === 'individual' && !_.isEmpty(levelID))
         _response = levelID
      else if (!_.isEmpty(clientids))
         _response = clientids

      return isString ? _response.join(',') : _response;
   }

   getAllEventClientsDataByLevel = (dealersettings, clientids, level, levelID) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c => c?.moduleSettings?.eventsPro?.enabled === true && c?.moduleSettings?.eventsPro?.active === true).forEach(rec => { _dealers.push(rec) })

      if (level === 'region' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.region))
      else if (level === 'subregion' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.subregion))
      else if (level === 'group' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.group))
      else if (level === 'individual' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.id))
      else if (!_.isEmpty(clientids))
         _response = _dealers.filter(e => clientids.includes(e.id))

      return _response;
   }
   //#endregion EVENTSPRO

   //#region FLEET-PRO
   // getFleetProDealers = (dealersettings, isArray) => {
   //    const dealerList = !_.isEmpty(dealersettings?.clients) ? this.arrLimit(dealersettings.clients.filter(c =>
   //       c.moduleSettings
   //       && c.moduleSettings.fleetPro
   //       && c.moduleSettings.fleetPro.enabled === true
   //       && c.moduleSettings.fleetPro.active === true
   //    ).map(r => { return r.documentID }), 30) : [dealersettings?.client?.documentID];

   //    return isArray ? dealerList : dealerList.join(',')
   // }

   getAllFleetProDealers = (dealersettings, isArray) => {
      const dealerList = !_.isEmpty(dealersettings?.clients) ? dealersettings.clients.filter(c =>
         c.moduleSettings
         && c.moduleSettings.fleetPro
         && c.moduleSettings.fleetPro.enabled === true
         && c.moduleSettings.fleetPro.active === true
      ).map(r => { return r.documentID }) : [dealersettings?.client?.documentID];

      return isArray ? dealerList : dealerList.join(',')
   }

   levelBasedID = (level) => {
      if (level === 'oem') return 'oemID'
      else if (level === 'region') return 'regionID'
      else if (level === 'subregion') return 'subregionID'
      else if (level === 'group') return 'groupID'
      else if (level === 'individual') return 'clientID'
      else return 'clientID'
   }

   getAllFleetProByLevel = (dealersettings, level, isID, isArray) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c =>
            c.moduleSettings
            && c.moduleSettings.fleetPro
            && c.moduleSettings.fleetPro.enabled === true
            && c.moduleSettings.fleetPro.active === true
         ).forEach(rec => {
            _dealers.push({ ...rec, label: rec.name, value: rec.id, data: rec, active: true })
         })
      if (level === 'region') {
         dealersettings.regions && dealersettings.regions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.region === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'subregion') {
         dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'group') {
         dealersettings.groups && dealersettings.groups.forEach((doc) => {
            if (_dealers && _dealers.some(client => client.group === doc.id)) {
               _response.push({
                  ...doc,
                  value: doc.id,
                  active: _.isBoolean(doc.active) ? doc.active : true,
                  label: doc.name
               });
            }
         });
      }
      else if (level === 'individual') {
         _response = _dealers;
      }
      else if (level === 'oem' && dealersettings.oemID) {
         _response.push({
            value: dealersettings.oemID,
            active: true,
            label: 'oem'
         });
      }

      if (isID) {
         if (isArray)
            return _response.map(r => { return r.value })
         else
            return _response.map(r => { return r.value }).join(',')
      } else return _response
   }

   getAllFleetClientsByLevel = (dealersettings, level, levelID, isString) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c => c?.moduleSettings?.fleetPro?.enabled === true && c?.moduleSettings?.fleetPro?.active === true).forEach(rec => { _dealers.push(rec) })

      if (level === 'region' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.region)).map(r => { return r.id })
      else if (level === 'subregion' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.subregion)).map(r => { return r.id })
      else if (level === 'group' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.group)).map(r => { return r.id })
      else if (level === 'individual' && !_.isEmpty(levelID))
         _response = levelID

      return isString ? _response.join(',') : _response;
   }

   getAllFleetClientsDataByLevel = (dealersettings, level, levelID) => {
      let _dealers = [];
      let _response = [];
      if (dealersettings.clients)
         dealersettings.clients.filter(c => c?.moduleSettings?.fleetPro?.enabled === true && c?.moduleSettings?.fleetPro?.active === true).forEach(rec => { _dealers.push(rec) })

      if (level === 'region' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.region))
      else if (level === 'subregion' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.subregion))
      else if (level === 'group' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.group))
      else if (level === 'individual' && !_.isEmpty(levelID))
         _response = _dealers.filter(e => levelID.includes(e.id))


      return _response;
   }

   checkFleetAppointments = async (_bookings, _startDate, _endDate, formIsValid, clientUsers, dealersettings, fleetPro) => {
      try {
         if (_startDate && _endDate) {
            let errorList = _bookings.filter(a => ((a.startDate == null || (a.startDate.seconds <= _endDate.seconds)) &&
               (a.endDate == null || (_startDate.seconds <= a.endDate.seconds)) &&
               (a.startDate == null || a.endDate == null || (a.startDate.seconds <= a.endDate.seconds)) &&
               (_startDate.seconds <= _endDate.seconds)));

            if (!_.isEmpty(errorList) && !_.isEmpty(errorList[0]?.startDate) && !_.isEmpty(errorList[0]?.endDate)) {
               let slotExists = errorList[0];

               Swal.fire(
                  'Vehicle has a booking from <strong>' +
                  moment.unix(slotExists.startDate.seconds).format('DD/MM/YYYY hh:mm A') +
                  (slotExists.endDate ? ('</strong> to <strong>' + moment.unix(slotExists.endDate.seconds).format('DD/MM/YYYY hh:mm A')) : ''), '', 'info')

               return false;
            }
            else if (!_.isEmpty(fleetPro?.statusDaterange) && !_.isEmpty(fleetPro?.statusDaterange?.[fleetProStatus.OPERATIONAL])) {
               let _sdTemp = fleetPro?.statusDaterange?.[fleetProStatus.OPERATIONAL]?.startDate
               let _edTemp = fleetPro?.statusDaterange?.[fleetProStatus.OPERATIONAL]?.endDate

               let _sd = window.firebase.firestore.Timestamp.fromDate(moment.unix(_sdTemp.seconds).startOf('day')._d)
               let _ed = window.firebase.firestore.Timestamp.fromDate(moment.unix(_edTemp.seconds).endOf('day')._d)

               const isDate1Between = _sd.seconds <= _startDate.seconds && _startDate.seconds <= _ed.seconds;
               const isDate2Between = _sd.seconds <= _endDate.seconds && _endDate.seconds <= _ed.seconds;
               const isDiffDate = moment.unix(_sdTemp.seconds).format('DD/MM/YYYY') !== moment.unix(_edTemp.seconds).format('DD/MM/YYYY') ? true : false

               const areBothDatesBetween = isDate1Between && isDate2Between;
               if (areBothDatesBetween)
                  return true;
               else {
                  Swal.fire(`The vehicle is operational ${isDiffDate ? 'from' : 'on'} ${this.showCombineDate(_sd, _ed)}.`, 'Please choose dates within this range.', 'info')
                  return false;
               }

            }
            else {
               return formIsValid;
            }
         }
         else {
            return formIsValid;
         }

      }
      catch (error) {
         console.log('checkFleetAppointments', error);
         return false;
      }


   }

   //#endregion FLEET-PRO

   //#region SERVICE
   addTechnicianWorkLog = (dealersettings, service, updateDealerSettings) => {

      const objData = Object.assign({});
      objData.addedBy = localStorage.uid;
      objData.addedDate = window.firebase.firestore.Timestamp.now();
      objData.addedFrom = 'web';

      objData.modifiedBy = localStorage.uid;
      objData.modifiedDate = window.firebase.firestore.Timestamp.now();
      objData.modifiedFrom = 'web';

      objData.mannual = false;
      objData.active = true;
      objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
      objData.clientID = dealersettings ? dealersettings.client.id : '';
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
      objData.serviceID = service.documentID;
      objData.startTime = window.firebase.firestore.Timestamp.now();

      //Remove empty value from object
      Object.keys(objData).forEach(key => (objData[key] === undefined || objData[key] === null) ? delete objData[key] : {});

      if (!_.isEmpty(objData.serviceID)) {
         firestoreDB(dealersettings).firestore().doc(`serviceJobs/${objData.serviceID}/workLogs/${objData.documentID}`).set(objData, { merge: true }).then((docRef) => {

            // let _data = {
            //    modifiedBy: localStorage.uid,
            //    modifiedDate: window.firebase.firestore.Timestamp.now(),
            //    modifiedFrom: 'web'
            // }
            // if (service?.technicianStatus !== serviceStatus.WORKINPROGRESS)
            //    _data.technicianStatus = serviceStatus.WORKINPROGRESS;

            // firestoreDB(dealersettings).firestore().doc(`serviceJobs/${objData.serviceID}`).set(_data, { merge: true })

            window.firebase.firestore().doc(`users/${objData.addedBy}`).set({ "workingServiceID": objData.serviceID }, { merge: true });

            if (updateDealerSettings) updateDealerSettings('workingServiceID', objData.serviceID)

         }).catch((error) => {
            console.error("Error updating contact_details: ", error);
         });
      }

   };

   stopAllWorkLogTimers = async (dealersettings, recordID) => {
      if (!recordID) return;

      const batch = firestoreDB(dealersettings).firestore().batch();
      const batch2 = window.firebase.firestore().batch();
      await firestoreDB(dealersettings).firestore().collection(`serviceJobs/${recordID}/workLogs`)
         .where("active", '==', true)
         .get().then(response => {
            response.docs.forEach((doc) => {
               let _objData = {};

               let e = Object.assign({}, doc.data());
               var startTime = moment.unix(e.startTime.seconds);

               _objData.active = false;
               _objData.timeSpent = moment().diff(startTime, 'seconds');
               _objData.modifiedBy = localStorage.uid;
               _objData.modifiedFrom = 'web';
               _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
               batch.update(doc.ref, _objData);
               batch.update(window.firebase.firestore().doc(`serviceJobs/${recordID}`), { "modifiedBy": localStorage.uid, "modifiedDate": window.firebase.firestore.Timestamp.now(), "modifiedBy": 'web' });

               if (e.addedBy)
                  batch2.update(window.firebase.firestore().doc(`users/${e.addedBy}`), { "workingServiceID": null });

            });
         });

      batch.commit();
      batch2.commit();
   }
   //#endregion SERVICE

   checkUserRoster = async (dealersettings, log, allUsers, isDateRange) => {
      let msg = null;
      if (dealersettings?.client?.moduleSettings?.roster?.enabled
         && dealersettings?.client?.moduleSettings?.roster?.active
         && log.startDate
         && localStorage.defaultModule !== 'oem'
         && localStorage.defaultModule !== 'tradeinPro'
         && localStorage.defaultModule !== 'service') {
         const rosterCategories = dealersettings?.rosterSettings?.rosterCategories ?
            dealersettings.rosterSettings.rosterCategories : []

         const rosterHolidays = dealersettings?.rosterSettings?.rosterHolidays ?
            dealersettings.rosterSettings.rosterHolidays.filter(a => a.active === true && a.dealershipOff === true) : [];


         let title = null;

         const rosterExists = await window.firebase.firestore().collection('roster')
            .where('userIDs', 'array-contains', log.owner)
            .where('clientID', '==', dealersettings.client.id)
            .where('date', '==', moment.unix(log.startDate.seconds).format('YYYY-MM-DD'))
            .limit(1)
            .get();
         if (rosterExists.docs.length > 0) {
            title = this.getNameByValue(rosterCategories, rosterExists.docs[0].data()[log.owner]);
            msg = `${this.getUserNamebyId(allUsers, log.owner)} is on ${this.getNameByValue(rosterCategories, rosterExists.docs[0].data()[log.owner])}.`;
         }
         else {
            rosterHolidays.forEach(rec => {
               if (rec.recurrence && rec.cron) {
                  const options = {
                     currentDate: new Date(moment.unix(log.startDate.seconds).format('YYYY-MM-DD')),
                     iterator: true,
                     tz: !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
                  };
                  const interval = parser.parseExpression(rec.cron, options);
                  const _ms = interval?.next()?.value?._date?.ts;
                  if (_ms && moment.unix(_ms / 1000).format('YYYY-MM-DD') === moment.unix(log.startDate.seconds).format('YYYY-MM-DD')) {
                     title = rec.name;
                     msg = `Dealership is off on ${moment.unix(log.startDate.seconds).format('DD/MM/YYYY')}(${rec.name}).`;
                     return false;
                  }
               }
               else if (!rec.recurrence && rec.dateFrom && rec.dateTo && moment.unix(log.startDate.seconds).isBetween(`${rec.dateFrom} 00:00`, `${rec.dateTo} 23:59`, undefined, '[]')) {
                  title = rec.name;
                  msg = `Dealership is off on ${moment.unix(log.startDate.seconds).format('DD/MM/YYYY')}(${rec.name}).`;
                  return false;
               }
            })

         }
      }
      return msg;
   }

   showDuration = (_totalSeconds) => {
      if (_totalSeconds > 0) {
         var duration = moment.duration(_totalSeconds, 'seconds');
         duration = moment.duration(duration, 'milliseconds');
         const days = duration.days();
         const hours = duration.hours();
         const minutes = duration.minutes();
         const seconds = duration.seconds();
         return (days > 0 ? (days + "d ") : "") +
            (hours > 0 ? (hours + "h ") : "") +
            (minutes > 0 ? (minutes + "m ") : "") +
            (seconds > 0 ? (seconds + "s ") : "")
      }
      else return '0s'

   }


   getPropByString = (obj, propString) => {
      if (!propString)
         return obj;

      var prop, props = propString.split('.');

      for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
         prop = props[i];

         var candidate = obj[prop];
         if (candidate !== undefined) {
            obj = candidate;
         } else {
            break;
         }
      }
      return obj[props[i]];
   }

   getTermsByField = (_client, _fieldName, _language) => {
      if (!_.isEmpty(_client) && _fieldName) {
         if (_language && !_.isEmpty(_client[_language]?.[_fieldName]))
            return _client[_language]?.[_fieldName];
         else
            return _client[_fieldName];

      }
      return null;

   }



   // removeEmptyOrNull = (obj) => {
   //    Object.keys(obj).forEach(k =>
   //       (obj[k] && typeof obj[k] === 'object') && this.removeEmptyOrNull(obj[k]) ||
   //       (!obj[k] && obj[k] !== undefined) && delete obj[k]
   //    );
   //    return obj;
   // };

   removeEmptyOrNull = (object) => {
      Object
         .entries(object)
         .forEach(([k, v]) => {
            if (v && typeof v === 'object')
               this.removeEmptyOrNull(v);
            if (v &&
               typeof v === 'object' &&
               !Object.keys(v).length ||
               v === null ||
               v === undefined ||
               v.length === 0
            ) {
               if (Array.isArray(object))
                  object.splice(k, 1);
               else if (!(v instanceof Date))
                  delete object[k];
            }
         });
      return object;
   }

   stringtoURL = (notes) => {
      if (!_.isEmpty(notes)) {
         const urls = notes.match(/((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g);
         if (urls) {
            urls.forEach(function (url) {
               notes = notes.replace(url, '<a class="hyperlink-blue" target="_blank" href="' + url + '">' + url + "</a>");
            });
         }
         return notes.replace("(", "<br/>(");
      }
      return null;
   }

   getAttendeReq = (eventSettings, attendee) => {
      let _req = [];
      const attendeesDF = eventSettings?.attendeesDF ? eventSettings.attendeesDF : [];
      const newCars = _.find(attendeesDF, { saleType: 'New' }) ? _.find(attendeesDF, { saleType: 'New' }) : _.find(attendeesDF, { mappingKey: 'model' });
      const preownedCars = _.find(attendeesDF, { saleType: 'Preowned' });
      const saleTypes = _.find(attendeesDF, { mappingKey: 'saleType' });

      if (attendee?.dynamicFields?.[newCars?.value]) {
         if (_.isArray(attendee?.dynamicFields?.[newCars.value])) {
            attendee.dynamicFields[newCars.value].forEach(rec => {
               _req.push({
                  make: newCars?.make ? newCars?.make : null,
                  model: rec,
                  saleType: newCars?.saleType ? newCars?.saleType : attendee.dynamicFields[saleTypes?.value] ? attendee.dynamicFields[saleTypes.value] : 'New'
               })
            })
         }
         else {
            _req.push({
               model: attendee.dynamicFields[newCars.value],
               make: newCars?.make ? newCars?.make : null,
               saleType: newCars?.saleType ? newCars?.saleType : attendee.dynamicFields[saleTypes?.value] ? attendee.dynamicFields[saleTypes.value] : 'New'
            })
         }

      }

      if (attendee?.dynamicFields?.[preownedCars?.value]) {
         if (_.isArray(attendee?.dynamicFields?.[preownedCars.value])) {
            attendee.dynamicFields[preownedCars.value].forEach(rec => {
               _req.push({
                  make: preownedCars?.make ? preownedCars?.make : null,
                  model: rec,
                  saleType: preownedCars?.saleType ? preownedCars?.saleType : attendee.dynamicFields[saleTypes?.value] ? attendee.dynamicFields[saleTypes.value] : 'Preowned'
               })
            })
         }
         else {
            _req.push({
               make: preownedCars?.make ? preownedCars?.make : null,
               model: attendee.dynamicFields[preownedCars.value],
               saleType: preownedCars?.saleType ? preownedCars?.saleType : attendee.dynamicFields[saleTypes?.value] ? attendee.dynamicFields[saleTypes.value] : 'Preowned'
            })
         }

      }
      return _req;
   }

   getAttendeOwned = (eventSettings, attendee, currentlyOwned) => {
      let _req = [];
      const attendeesDF = eventSettings?.attendeesDF ? eventSettings.attendeesDF : [];
      const ownedCars = _.find(attendeesDF, { mappingKey: (currentlyOwned ? 'currentlyOwned' : 'previouslyOwned') });

      if (attendee?.dynamicFields?.[ownedCars?.value]) {
         if (_.isArray(attendee?.dynamicFields?.[ownedCars.value])) {
            attendee.dynamicFields[ownedCars.value].forEach(rec => {
               _req.push({
                  make: ownedCars?.make ? ownedCars?.make : null,
                  model: rec,
               })
            })
         }
         else {
            _req.push({
               model: attendee.dynamicFields[ownedCars.value],
               make: ownedCars?.make ? ownedCars?.make : null,
            })
         }

      }
      return _req;
   }

   arrPaginate = (array, page_size, page_number) => {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((page_number - 1) * page_size, page_number * page_size);
   }

   heic2Image = async (_file) => {
      let file;
      var fileNameExt = _file.name.substring(0, _file.name.lastIndexOf('.'))
      await heic2any({
         blob: _file,
         toType: "image/jpeg",
         quality: 0.5,
      }).then(function (resultBlob) {
         file = new File([resultBlob], `${fileNameExt}.jpeg`, { type: "image/jpeg", lastModified: new Date().getTime() });
      }).catch(function (x) {
         console.log('heic2any1', x.code);
         console.log('heic2any2', x.message);
      });

      return file
   }

   bindMArkType = (type, cost, notes, currencySymbol) => {

      if (type === 'MajorScratch') {
         return <>
            <span><Translate text={'major'} /> <Translate text={'scratch'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MinorScratch') {
         return <>
            <span><Translate text={'minor'} /> <Translate text={'scratch'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MajorRust') {
         return <>
            <span><Translate text={'major'} /> <Translate text={'rust'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MinorRust') {
         return <>
            <span><Translate text={'minor'} /> <Translate text={'rust'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MajorDent') {
         return <>
            <span><Translate text={'major'} /> <Translate text={'dent'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MinorDent') {
         return <>
            <span><Translate text={'minor'} /> <Translate text={'dent'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MajorCrack') {
         return <>
            <span><Translate text={'major'} /> <Translate text={'crack'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else if (type === 'MinorCrack') {
         return <>
            <span><Translate text={'minor'} /> <Translate text={'crack'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
      else {
         return <>
            <span><Translate text={'info'} />: {notes ? notes : ''}</span>
            <NumberFormat value={cost ? cost : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
         </>
      }
   }


   getAllDealers = (dealersettings, isArray, isPorsche) => {
      const dealerList = !_.isEmpty(dealersettings?.clients) && localStorage.defaultModule === 'oem' ? dealersettings.clients
         .filter(a => a.settingsID === dealersettings.oemID && (isPorsche ? a.status !== 'inactive' : true))
         .map(r => { return r.documentID }) : [dealersettings?.client?.documentID];

      return isArray ? dealerList : dealerList.join(',')
   }

   handleCopy = (e, val) => {
      e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(val);
      toast.notify('Copied', {
         duration: 2000,
      });
   }

   getFleetProDynamicField = (dynObj, mappingKey, dynFields) => {
      const key = _.find(dynFields, { mappingKey: mappingKey })?.value;
      return dynObj?.[key] || null;
   }

   showPSAge = (_history, pipeline, stage, _type) => {
      let _stageHistory = Object.assign([], _history);
      let _data = _.orderBy(_stageHistory.filter(p => p.pipeline === pipeline && (_type === 'pipeline' ? true : p.stage === stage)), ['date'], [(_type === 'pipeline' ? 'asc' : 'desc')]);
      let _date = _data[0]?.date
      return this.calculateSittingAge(_date, null, true);
   }

   calculateSittingAge = (_date, _compareDate, _isLong) => {
      if (_date) {

         // Convert date to Moment object
         const compareDate = moment.unix(_date.seconds);
         // Get current/compare date
         const currentDate = _compareDate ? moment.unix(_compareDate.seconds) : moment();
         const hours = currentDate.diff(compareDate, 'hours');
         const minutes = currentDate.diff(compareDate, 'minutes');
         const seconds = currentDate.diff(compareDate, 'seconds');
         const days = currentDate.startOf('day').diff(compareDate.startOf('day'), 'days');
         if (days) return `${days} Day${days > 1 ? 's' : ''}`
         else if (hours) return `${hours} Hour${hours > 1 ? 's' : ''}`
         else if (minutes) return `${minutes} Minute${minutes > 1 ? 's' : ''}`
         else if (seconds) return `${seconds} Second${seconds > 1 ? 's' : ''}`
         else if (seconds === 0) return `Just Now`
         else return '';
         // if (_isLong) {
         //    // Calculate difference in years, months, and days
         //    const years = currentDate.diff(compareDate, 'years');
         //    const months = currentDate.diff(compareDate, 'months');
         //    const days = currentDate.startOf('day').diff(compareDate.startOf('day'), 'days');
         //    const hours = currentDate.diff(compareDate, 'hours');
         //    const minutes = currentDate.diff(compareDate, 'minutes');
         //    if (years) return `${years} Year${years > 1 ? 's' : ''}`
         //    else if (months) return `${months} Month${months > 1 ? 's' : ''}`
         //    else if (days) return `${days} Day${days > 1 ? 's' : ''}`
         //    else if (hours) return `${hours} Hour${hours > 1 ? 's' : ''}`
         //    else if (minutes) return `${minutes} Minutes${minutes > 1 ? 's' : ''}`
         //    else return '';            

         // }
         // else {
         //    var diff = currentDate.diff(compareDate, 'milliseconds');
         //    var duration = moment.duration(diff);

         //    const years = duration.years();
         //    const months = duration.months();
         //    const days = duration.days();
         //    const hours = duration.hours();

         //    return (years > 0 ? (years + "y ") : "") +
         //       (months > 0 ? (months + "m ") : "") +
         //       (days > 0 ? ((hours > 0 ? (days + 1) : (days)) + "d ") : "") +
         //       (hours > 0 && (years === 0 && months === 0 && days === 0) ? (hours + "h ") : "")
         // }
      }
      else {
         return '';
      }

   }

   handleSittingDays = (stageHistory, pipeline, _stage) => {
      //console.log('handleSittingDays', _stage, stageHistory)

      if (!_.isEmpty(stageHistory) && stageHistory.filter(e => e.stage === _stage.value && e.pipeline === pipeline)[0]) {
         let _stageHistory = _.orderBy(stageHistory, ['date'], ['desc']);
         let current_stage = _stageHistory.filter(e => e.stage === _stage.value && e.pipeline === pipeline)[0];

         if (current_stage) {
            //const cs_date = moment.unix(current_stage.date.seconds);
            const current_index = _stageHistory.findIndex(e => e.stage === _stage.value && e.pipeline === pipeline);
            if (current_index > 0) {
               const next_stage = _stageHistory[current_index - 1];
               return this.calculateSittingAge(current_stage.date, next_stage?.date, _stage.name);
            }
            return this.calculateSittingAge(current_stage.date, null, _stage.name);
         }
      }

      return '';
   };

   handleStageHistory = (stageHistory, _pipeline, _stage) => {
      let _stageData = null;
      if (!_.isEmpty(stageHistory)) {
         var last = _.orderBy(stageHistory, ['date'], ['desc'])[0];
         // Concatenate pipeline and stage values with "~" separator, excluding null values
         var lastHistoryKey = [last?.pipeline, last?.stage].join("~");
         var currentHistoryKey = [_pipeline, _stage].join("~");

         // Compare the concatenated keys to determine if there's a change
         if (lastHistoryKey != currentHistoryKey) {
            _stageData = {
               date: window.firebase.firestore.Timestamp.now(),
               stage: _stage,
               pipeline: _pipeline
            };
         }
      } else {
         // If the stage history is empty, create a new entry
         _stageData = {
            date: window.firebase.firestore.Timestamp.now(),
            stage: _stage,
            pipeline: _pipeline
         };
      }

      return _stageData
   }

   formatYear = (decimalYear) => {
      if (decimalYear) {
         const integerPart = Math.floor(decimalYear);
         const decimalPart = decimalYear - integerPart;
         const months = Math.round(decimalYear * 12);
         if (months < 12) {
            return months === 1 ? '1 month' : `${months} months`;
         }
         else if (decimalPart >= 0.5) {
            return `${integerPart + 0.5} years`;
         } else {
            return integerPart === 1 ? '1 year' : `${integerPart} years`;
         }
      }
      else return '';


   }

   getIndexFromArray = (_array, key, value) => {
      return _array.findIndex(item => item[key] === value);
   };

   bindContactName = (originalArray, key) => {
      if (!_.isEmpty(originalArray) && key) {
         // Array to insert
         const arrayToInsert = [
            { label: 'First Name', key: 'firstName' },
            { label: 'Middle Name', key: 'middleName' },
            { label: 'Last Name', key: 'lastName' }
         ];
         // Index after which to insert
         const insertAfterIndex = this.getIndexFromArray(originalArray, 'key', key)

         if (insertAfterIndex >= 0)
            return [
               ...originalArray.slice(0, insertAfterIndex + 1),
               ...arrayToInsert,
               ...originalArray.slice(insertAfterIndex + 1)
            ];

      }
      return originalArray

   }

   leadConvertedNote = (dealersettings, objFields) => {
      let _pipelineName = _.find(dealersettings.allPipelines, { 'value': objFields.pipeline }) ? _.find(dealersettings.allPipelines, { 'value': objFields.pipeline }).name : objFields.pipeline
      if (_pipelineName && !_pipelineName.toLowerCase().includes('pipeline')) _pipelineName = _pipelineName + ' Pipeline'
      return (_.isEmpty(objFields.notes) || _.isEmpty(_.trim(objFields.notes))) ?
         `${dealersettings.isPorsche ? 'Enquiry has been moved' : 'Lead has been converted'} to ${_pipelineName || ' Pipeline'}.` :
         objFields.notes

   }

   getMinifiedData = (data, _type) => {
      let _data = !_.isEmpty(data) ? Object.assign({}, { ...data }) : null
      // if (_type === 'enquiry' && !_.isEmpty(_data)) {


      //    Object.keys(_data).forEach(key => (objNewSubEnquiryVM.hasOwnProperty(key) && (_data[key] === undefined || _.isEmpty(_data[key]))) ? delete _data[key] : {})
      // }
      // else if (_type === 'contact' && !_.isEmpty(_data)) {
      //    Object.keys(_data).forEach(key => (contactBasicDetailsVM.hasOwnProperty(key) && (_data[key] === undefined || _.isEmpty(_data[key]))) ? delete _data[key] : {})
      // }
      // else 
      if (!_.isEmpty(_data)) {
         Object.keys(_data).forEach(key => (_data[key] === undefined || _.isEmpty(_data[key])) ? delete _data[key] : {})
      }
      return _data;
   }

   mandScheduleNextActivity = (dealersettings, enquiry) => {

      const isStatusOpenOrSold = this.isStatusOpenOrSold(dealersettings?.client?.settings?.enquiryStatus, enquiry?.status)
      const isStatusOpen = this.isEnqStatusOpen(dealersettings?.client?.settings?.enquiryStatus, enquiry?.status)

      const mandScheduleNextActivity = ((dealersettings?.rolePermissions?.permissions?.mandScheduleNextActivity) ?
         ((!_.isEmpty(enquiry) && enquiry.status && (isStatusOpenOrSold || enquiry.status === enquiryStatus.OPEN || enquiry.status === enquiryStatus.WON || enquiry.status === enquiryStatus.CLOSED)) ? true : false)
         : false);

      const mandScheduleNextActivityOpen = ((dealersettings?.rolePermissions?.permissions?.mandScheduleNextActivityOpen) ?
         ((!_.isEmpty(enquiry) && enquiry.status && (isStatusOpen || enquiry.status === enquiryStatus.OPEN)) ? true : false)
         : false);
      //console.log('mandScheduleNextActivity', enquiry?.status, 'open=>', isStatusOpen, mandScheduleNextActivityOpen, 'sold=>', isStatusOpenOrSold, mandScheduleNextActivity)
      return (mandScheduleNextActivity || mandScheduleNextActivityOpen) ? true : false;

   }

   getDataExcluded = (objArray, excludedArray, includeEmpty, isAll, isJoin) => {
      if (!_.isEmpty(objArray)) {
         let _result = objArray.filter(e => !_.isEmpty(excludedArray) ? !excludedArray.includes(e.value) : true).map(r => { return r.value })

         if (!_.isEmpty(_result) && includeEmpty && isJoin)
            _result = _.union([""], _result)
         else if (!_.isEmpty(_result) && includeEmpty)
            _result = _.union(["", null], _result)

         _result = isAll ? _result : this.arrLimit(_result, 30)
         return isJoin ? _result.join(',') : _result
      }
      return isJoin ? '' : []
   }

   saveModelTypeAndGroup = (objdata, dealersettings) => {

      const brands = dealersettings?.allMakeModels;
      let _models = (objdata?.make && brands && _.find(brands, { value: objdata.make })) ? _.find(brands, { value: objdata.make })?.models : [];
      //console.log('saveModelTypeAndGroup', objdata, _models, brands)
      if (objdata?.model && (_.isEmpty(objdata?.group) || _.isEmpty(objdata?.type)) && !_.isEmpty(_models) && _.find(_models, { value: objdata.model })) {
         let e = _.find(_models, { value: objdata.model });
         if (e?.group) objdata['group'] = e.group;
         if (e?.type) objdata['type'] = e.type;
      }
      //console.log('90', objdata)
      return objdata;

   }

}

export default new CommonHelper;