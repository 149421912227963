import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';

export default (params) => {
    const [value, setValue] = useState();

    useEffect(() => {
        let _value = params?.value || params?.valueFormatted || (params.isFilterRenderer ? '(Blanks)' : '');
        if (params?.value === 0) {
            _value = null
        } else if (params?.colDef?.html && _.find(params?.context?.settings?.[params?.colDef?.settingsField], { searchlabel: params.value })?.name) {
            _value = _.find(params?.context?.settings?.[params?.colDef?.settingsField], { searchlabel: params.value })?.name
        } else if (params?.colDef?.dataType === 'price' && params?.value) {
            _value = <NumberFormat decimalScale={2} value={params?.value} displayType='text' thousandSeparator={true} prefix={params?.context?.currencySymbol} />
        } else if (params?.colDef?.suffix && params?.value) {
            _value = `${params?.value}${params?.colDef?.suffix}`
        } else if (params?.colDef?.contact && params?.value) {
            _value = <><i className={`ico icon-${params?.data?.businessContact && !_.isEmpty(params?.data?.contact?.company) ? 'company' : 'Contacts'} mr-1`}></i>{params?.value}</>
        }
        if (!params.isFilterRenderer && params?.data?.fieldComments?.[params?.column?.colId]) {
            _value = (<>{_value}<div className="float-right"><span className="comment-icon-linear"><i className="ico icon-chat"></i></span></div></>)
        }
        setValue(_value);
    }, [params?.value]);

    return value;
};